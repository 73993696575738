import React from "react";
import { googleLogout } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { logout } from "../apis/authorizationApis";

function Logout() {
  const navigate = useNavigate();

  const logOut = async () => {
    const response = await logout();
    console.log(response);
    if (response.status === 200) {
      googleLogout();
      localStorage.removeItem("user");
      navigate("/");
    } else if (response.status === 401) {
      localStorage.removeItem("user");
      navigate("/");
    }
    else {
      window.alert("Failed to log out. Error: " + response.status);
      console.error("Error logging out:", response);
    }

  };

  return (
    <div className="d-grid gap-2">
      <Button variant="outline-primary" style={{
        "--bs-btn-color": "#50011a",
        "--bs-btn-border-color": "#50011a",
        "--bs-btn-hover-color": "#fff",
        "--bs-btn-hover-bg": "#50011a",
        "--bs-btn-hover-border-color": "#50011a",
        "--bs-btn-active-color": "#fff",
        "--bs-btn-active-bg": "#50011a",
        "--bs-btn-active-border-color": "#50011a",
        "--bs-btn-active-shadow": "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
        "--bs-btn-disabled-color": "#50011a",
        "--bs-btn-disabled-bg": "transparent",
        "--bs-btn-disabled-border-color": "#50011a"
      }} onClick={logOut}>
        Log Out
      </Button>
    </div>
  );
}

export default Logout;