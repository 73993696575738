import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { getThisUser, getAllUsersByClient } from "../apis/userApis";
import { addUsersToGroup, getGroupById } from "../apis/groupsApis";
import {
  FaRegSquare, FaRegSquareCheck, FaRegSquareMinus, FaRegSquarePlus, FaUserPlus
} from "react-icons/fa6";
import ThemeButton from "../components/themeButton";
const AddUsersToGroupModal = ({ show, onHide, groupId, addUserToGroupsResponse }) => {
  const [profile, setProfile] = useState(null);
  const [users, setUsers] = useState([]);
  const [group, setGroup] = useState({ users: [] });
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [hoveredUser, setHoveredUser] = useState(null);

  const fetchUserProfile = () => {
    getThisUser()
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setProfile(response.data.user);
        } else {
          addUserToGroupsResponse(response.status);
          resetState();
          onHide();
        }
      })
      .catch((error) => {
        console.error(error);
        addUserToGroupsResponse(503);
        resetState();
        onHide();
      });
  };

  const fetchAllUsers = (page_number = 0) => {
    getAllUsersByClient(page_number)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setUsers(response.data.users);
        } else {
          addUserToGroupsResponse(response.status);
          resetState();
          onHide();
        }
      })
      .catch((error) => {
        console.error(error);
        addUserToGroupsResponse(503);
        resetState();
        onHide();
      });
  };

  const fetchGroup = () => {
    getGroupById(groupId)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setGroup(response.data.group);
          // setSelectedUsers(response.data.users.map(user => user.email));
        } else {
          addUserToGroupsResponse(response.status);
          resetState();
          onHide();
        }
      })
      .catch((error) => {
        console.error(error);
        addUserToGroupsResponse(503);
        resetState();
        onHide();
      });
  };

  const resetState = () => {
    setProfile(null);
    setUsers([]);
    setGroup({ users: [] });
    setSelectedUsers([]);
  };

  useEffect(() => {
    if (show) {
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (userFromStorage) {
        fetchUserProfile(userFromStorage.email);
        fetchAllUsers();
        fetchGroup();
        addUserToGroupsResponse(200);
      } else {
        addUserToGroupsResponse(401);
        onHide();
      }
    }
  }, [show, onHide, addUserToGroupsResponse]);

  const toggleUserSelection = (id) => {
    setSelectedUsers(prevSelected =>
      prevSelected.includes(id)
        ? prevSelected.filter(e => e !== id)
        : [...prevSelected, id]
    );
  };

  const isUserInGroup = (email) => {
    return group.users.some(user => user.email === email);
  };

  const handleAddUsersToGroup = async () => {
    if (selectedUsers.length === 0) {
      alert("Please select at least one user.");
      return;
    }

    const response = await addUsersToGroup(groupId, selectedUsers);
    if (response.status === 200) {
      alert("Users added to group successfully.");
      resetState();
      addUserToGroupsResponse(200);
      onHide();
    } else {
      alert("Failed to add users to group.\n" + response.data.message);
      addUserToGroupsResponse(response.status);
      resetState();
      onHide();
    }
  };

  return (
    <Modal show={show} onHide={() => {
      resetState();
      onHide();
    }} centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Users To {group.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "450px", overflowY: "auto" }}>
        {profile && (
          <>
            {users.map((user) => (
              <div key={user.id}
                onClick={() => toggleUserSelection(user.id)}
                onKeyDown={(e) => { if (e.key === "Enter") { toggleUserSelection(user.id); } }}
                role="button" tabIndex="0"
                onMouseEnter={() => setHoveredUser(user.email)}
                onMouseLeave={() => setHoveredUser(null)}
                style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: "5px", flexDirection: "row", marginBottom: "5px" }}>
                <div style={{ fontSize: "17px", paddingBottom: "5px", display: "block" }}>
                  {user.first_name} {user.last_name}
                  <strong style={{ fontSize: "10px", marginBottom: "0px", marginLeft: "5px", marginTop: "auto", marginRight: "5px", color: "gray" }}>
                    ({user.email})
                  </strong>
                  {isUserInGroup(user.email) ? (
                    <FaRegSquareCheck style={{ color: "#50011a" }} />
                  ) : (
                    <>
                      {selectedUsers.includes(user.id) ? <FaRegSquareMinus style={{ color: "#50011a" }} /> :
                        <>
                          {hoveredUser === user.email ?
                            <FaRegSquarePlus style={{ color: "#50011a" }} /> :
                            <FaRegSquare style={{ color: "#50011a" }} />}
                        </>}
                    </>
                  )}
                </ div>
              </div>
            ))}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ThemeButton variant="outline-primary" onClick={handleAddUsersToGroup}>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <FaUserPlus /> Add User
          </ div>
        </ThemeButton>
      </ Modal.Footer>
    </Modal>
  );
};

export default AddUsersToGroupModal;