import React, { useState, useEffect, useCallback, useRef } from "react";
import { Table, Spinner, Badge, Dropdown, Button } from "react-bootstrap";
import { FaAngleRight, FaPlus, FaXmark, FaCheck, FaPen } from "react-icons/fa6";
import ThemeNavbar from "../components/themeNavbar";
import ThemeButton from "../components/themeButton";
import { getAllClients, updateClientStatus } from "../apis/clientApis";
import { getAllUsers } from "../apis/userApis";
import AddClientForm from "../modals/addClientForm";

const DatascribeManagementPage = () => {
  const [serverError, setServerError] = useState("");
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [clientData, setClientData] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);
  const [editingStatus, setEditingStatus] = useState(null);
  const [tempStatus, setTempStatus] = useState("");
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  const [addClientResponse, setAddClientResponse] = useState(null);

  const observerTarget = useRef(null);
  const clientsTableRef = useRef(null);
  const usersTableRef = useRef(null);

  const fetchAllUsers = useCallback((pageNumber = 0) => {
    setIsLoading(true);
    getAllUsers(pageNumber)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setUsersData(prevUsers => {
            if (pageNumber === 0) {
              return response.data.users;
            } else {
              return [...prevUsers, ...response.data.users];
            }
          });
          setHasMore(response.data.users.length > 0);
        } else {
          handleFetchError(response.status);
          setServerErrorMessage(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
        handleFetchError(503);
        setServerErrorMessage(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const fetchClientData = async () => {
    try {
      const response = await getAllClients();
      if (response.status >= 200 && response.status < 300) {
        setClientData(response.data);
      } else {
        handleFetchError(response.status);
        setServerErrorMessage(response.data);
      }
    } catch (error) {
      console.error(error);
      handleFetchError(503);
      setServerErrorMessage(error);
    }
  };

  const handleFetchError = (status) => {
    switch (status) {
      case 304:
        break;
      case 400:
        setServerError("400 Bad Request");
        break;
      case 401:
        setServerError("401 Unauthorized Access");
        break;
      case 403:
        setServerError("403 Forbidden Access");
        break;
      case 404:
        setServerError("404 Not Found");
        break;
      case 429:
        setServerError("429 Too Many Requests");
        break;
      case 500:
        setServerError("500 Internal Server Error");
        break;
      case 503:
        setServerError("503 Service Unavailable");
        break;
      default:
        setServerError("500 Internal Server Error");
        break;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (!userFromStorage) {
        setServerError(400);
        setIsLoading(false);
        return;
      }

      try {
        await fetchAllUsers(0);
        await fetchClientData();
      } catch (error) {
        console.error("Error fetching data:", error);
        setServerError("500 Internal Server Error");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    const handleResize = () => setIsMobile(window.innerWidth < 750);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [fetchAllUsers]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasMore && !isLoading) {
          setPage(prevPage => prevPage + 1);
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [hasMore, isLoading]);

  useEffect(() => {
    if (page > 0) {
      fetchAllUsers(page);
    }
  }, [page, fetchAllUsers]);

  const scrollTableRight = (tableRef) => {
    if (tableRef.current) {
      tableRef.current.scrollLeft += 100;
    }
  };

  const calculateRemainingTime = (registrationTime) => {
    const registrationDate = new Date(registrationTime);
    const expirationDate = new Date(registrationDate.getTime() + 7 * 24 * 60 * 60 * 1000); // 7 days after registration
    const now = new Date();
    const remainingTime = expirationDate - now;

    if (remainingTime <= 0) {
      return "Expired";
    }

    const days = Math.floor(remainingTime / (24 * 60 * 60 * 1000));
    const hours = Math.floor((remainingTime % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    const minutes = Math.floor((remainingTime % (60 * 60 * 1000)) / (60 * 1000));

    return `${days}d ${hours}h ${minutes}m`;
  };

  const handleEditStatus = (clientId, currentStatus) => {
    setEditingStatus(clientId);
    setTempStatus(currentStatus);
  };

  const handleStatusChange = (status) => {
    setTempStatus(status);
  };


  const handleConfirmStatusChange = async (clientId) => {
    try {
      const response = await updateClientStatus(clientId, tempStatus);
      if (response.status >= 200 && response.status < 300) {
        alert("Client status updated successfully");
      } else {
        alert("Error updating client status:\nMessage from server:", response.data);
      }

      fetchClientData();
      setEditingStatus(null);
    } catch (error) {
      console.error("Error updating client status:", error);
      setServerError("Error updating client status");
    }
  };

  const handleCancelStatusChange = () => {
    setEditingStatus(null);
    setTempStatus("");
  };

  return (
    <>
      {serverError === "" ? (
        <div>
          <ThemeNavbar />
          <h1 className="my-4">Datascribe Management</h1>

          <div style={{
            display: "flex", flexDirection: "row", alignItems: "center",
            justifyContent: "space-between", paddingLeft: "5px", paddingRight: "20px"
          }}>
            <h3>Clients</h3>
            <ThemeButton variant="outline-primary">
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}
                onClick={() => setShowAddClientModal(true)} onKeyDown={() => setShowAddClientModal(true)}
                role="button" tabIndex={0}>
                <FaPlus />
                Register New Client
              </div>
            </ThemeButton>
          </div>
          <div className="table-container" ref={clientsTableRef}>
            {isMobile && (
              <FaAngleRight onClick={() => scrollTableRight(clientsTableRef)}
                style={{ fontSize: "15px", zIndex: "100", position: "absolute", color: "#fff", left: "90%", marginTop: "2px" }} />
            )}
            <Table striped bordered className="curved-table" style={{ minWidth: "450px", minHeight: "225px" }}>
              <thead style={{ fontSize: isMobile ? "14px" : "20px", height: isMobile ? "40px" : "60px" }}>
                <tr>
                  <th style={{ backgroundColor: "#50011a", color: "white", width: "10%" }}>ID</th>
                  <th style={{ backgroundColor: "#50011a", color: "white", width: "20%" }}>Name</th>
                  <th style={{ backgroundColor: "#50011a", color: "white", width: "15%" }}>Status</th>
                  <th style={{ backgroundColor: "#50011a", color: "white", width: "25%" }}>Creation Date</th>
                  <th style={{ backgroundColor: "#50011a", color: "white", width: "30%" }}>Admins</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: isMobile ? "12px" : "18px", minWidth: "450px" }}>
                {clientData.map((client) => (
                  <tr key={client.client_id}>
                    <td>{client.client_id}</td>
                    <td>{client.client_name}</td>
                    <td style={{ position: "relative", minHeight: "150px" }}>
                      {editingStatus === client.client_id ? (
                        <div style={{ display: "flex", gap: "5px", }}>
                          <Dropdown onSelect={(status) => handleStatusChange(status)}>
                            <Dropdown.Toggle variant="secondary" id={`dropdown-${client.client_id}`}>
                              {tempStatus || "Select Status"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item eventKey="active">Active</Dropdown.Item>
                              <Dropdown.Item eventKey="inactive">Inactive</Dropdown.Item>
                              <Dropdown.Item eventKey="demo">Demo</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <Button variant="success" size="sm" onClick={() => handleConfirmStatusChange(client.client_id)}>
                            <FaCheck />
                          </Button>
                          <Button variant="danger" size="sm" onClick={handleCancelStatusChange}>
                            <FaXmark />
                          </Button>
                        </div>
                      ) : (
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          <Badge pill bg={client.status === "active" ? "success" : client.status === "demo" ? "warning" : "danger"}>
                            {client.status}
                          </Badge>
                          <ThemeButton variant="outline-primary" style={{ marginRight: "15px", marginLeft: "5px", fontSize: isMobile ? "8px" : "12px" }}
                            onClick={() => handleEditStatus(client.client_id, client.status)}>
                            <FaPen />
                          </ThemeButton>
                        </div>
                      )}
                    </td>
                    <td>
                      {new Date(client.registration_time).toLocaleString()}
                      {client.status === "demo" && (
                        <div style={{ color: "red", fontSize: isMobile ? "8px" : "12px" }}>
                          Time Remaining: {calculateRemainingTime(client.registration_time)}
                        </div>
                      )}
                    </td>
                    <td>
                      {client.admins.map((admin, index) => (
                        <div key={index}>
                          {`${admin.first_name} ${admin.last_name}`}
                          <span style={{ marginLeft: "5px", fontSize: isMobile ? "8px" : "12px", color: "gray" }}>{`(${admin.email})`}</span>
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <div style={{
            display: "flex", flexDirection: "row", alignItems: "center",
            justifyContent: "space-between", paddingLeft: "5px", paddingRight: "20px"
          }}>
            <h3>Users</h3>
          </div>
          <div className="table-container" ref={usersTableRef}>
            {isMobile && (
              <FaAngleRight onClick={() => scrollTableRight(usersTableRef)}
                style={{ fontSize: "15px", zIndex: "100", position: "absolute", color: "#fff", left: "90%", marginTop: "2px" }} />
            )}
            <Table striped bordered className="curved-table" style={{ minWidth: "450px" }}>
              <thead style={{ fontSize: isMobile ? "14px" : "20px", height: isMobile ? "40px" : "60px" }}>
                <tr>
                  <th style={{ backgroundColor: "#50011a", color: "white", width: "10%" }}>ID</th>
                  <th style={{ backgroundColor: "#50011a", color: "white", width: "20%" }}>Name</th>
                  <th style={{ backgroundColor: "#50011a", color: "white", width: "25%" }}>Email</th>
                  <th style={{ backgroundColor: "#50011a", color: "white", width: "40%" }}>Clients</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: isMobile ? "12px" : "18px", minWidth: "450px" }}>
                {usersData.map((user, index) => (
                  <tr key={user.id} ref={index === usersData.length - 1 ? observerTarget : null}>
                    <td>{user.id}</td>
                    <td>{user.first_name} {user.last_name}</td>
                    <td>{user.email}</td>
                    <td>
                      {user.clients.map((client, index) => (
                        <Badge key={index} bg="secondary" style={{ marginRight: "5px" }}>
                          {client.client_name}
                        </Badge>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {isLoading && (
              <div className="text-center mt-3">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <ThemeNavbar></ThemeNavbar>
          <div className='server-error-container'>
            <p className='login-response'>
              {serverError}
            </p>
            <p style={{ color: "#50011a" }}>
              Cause of Error: {serverErrorMessage.message}
            </p>
            {serverError === "403 Forbidden Access" || serverError === "401 Unauthorized Access" && (
              <p>
                Please <a href="/" className='link'>Login</a> again to continue.
              </p>
            )}
          </div>
        </div>
      )}
      <AddClientForm
        show={showAddClientModal}
        setAddClientResponse={setAddClientResponse}
        handleClose={() => {
          setShowAddClientModal(false);
          if (addClientResponse === "401 Unauthorized Access") {
            setServerError("401 Unauthorized Access");
            setServerErrorMessage({
              message: "Please login again to continue."
            });
          }
          fetchClientData();
        }}
      />
    </>
  );
};

export default DatascribeManagementPage;