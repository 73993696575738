import React, { useEffect, useState } from "react";
import { Modal, Spinner, Form } from "react-bootstrap";
import { importGroupDrive, getGroupById } from "../apis/groupsApis";
import { getUserByEmail } from "../apis/userApis";
import ThemeButton from "../components/themeButton";
import { FaAngleUp } from "react-icons/fa6";

const ImportDriveToGroupModal = ({ show, onHide, setAssignDriveResponse, groupId }) => {
  const [profile, setProfile] = useState({});
  const [group, setGroup] = useState({});
  const [driveId, setDriveId] = useState("");
  const [assigningDrive, setAssigningDrive] = useState(false);
  const [importOptions, setImportOptions] = useState({
    people: true,
    folders: true,
    files: true
  });

  const fetchUserProfile = (email) => {
    getUserByEmail(email)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setProfile(response.data.user);
        } else {
          setAssignDriveResponse(response.status);
          resetState();
          onHide();
        }
      })
      .catch((error) => {
        console.error(error);
        setAssignDriveResponse(503);
        resetState();
        onHide();
      });
  };

  const fetchGroup = async (groupId) => {
    try {
      getGroupById(groupId).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          console.log(response.data.group);
          setGroup(response.data.group);
        } else {
          setAssignDriveResponse(response.status);
          resetState();
          onHide();
        }
      });
    } catch (error) {
      console.error(error);
      setAssignDriveResponse(503);
      resetState();
      onHide();
    }
  };

  useEffect(() => {
    if (show) {
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (userFromStorage) {
        fetchUserProfile(userFromStorage.email);
        fetchGroup(groupId);
        setAssignDriveResponse(200);
      } else {
        setAssignDriveResponse(401);
        onHide();
      }
    }
  }, [show, onHide, setAssignDriveResponse, groupId]);

  const resetState = () => {
    setProfile({});
    setGroup({});
    setAssigningDrive(false);
    setDriveId("");
    setImportOptions({
      people: true,
      folders: true,
      files: true
    });
  };

  const handleAssignDrive = async () => {
    setAssigningDrive(true);
    if (!driveId) {
      alert("Please enter a drive id");
      setAssigningDrive(false);
      return;
    }
    const createGroupDriveResponse = await importGroupDrive(driveId, group.id, importOptions.people, importOptions.folders, importOptions.files);
    if (createGroupDriveResponse.status >= 200 && createGroupDriveResponse.status < 300) {
      alert("Drive assigned successfully.");
      setAssignDriveResponse(createGroupDriveResponse.status);
      resetState();
      onHide();
    } else {
      let msg = await JSON.stringify(createGroupDriveResponse.data.message);
      alert("Failed to assign Drive. Error: " + msg);
      setAssignDriveResponse(createGroupDriveResponse.status);
      resetState();
      onHide();
    }
  };

  const handleHide = () => {
    if (!assigningDrive) {
      resetState();
      onHide();
    }
  };

  const handleImportOptionChange = (option) => {
    setImportOptions(prev => {
      const newOptions = { ...prev };

      // Handle the folders-files dependency
      if (option === "folders") {
        if (!prev.folders) {
          // If enabling folders, no changes needed
          newOptions.folders = true;
        } else {
          // If disabling folders, must also disable files
          newOptions.folders = false;
          newOptions.files = false;
        }
      } else if (option === "files") {
        if (!prev.files) {
          // If enabling files, must also enable folders
          newOptions.files = true;
          newOptions.folders = true;
        } else {
          // If disabling files, just disable files
          newOptions.files = false;
        }
      } else {
        // For people option, just toggle
        newOptions[option] = !prev[option];
      }

      return newOptions;
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleHide}
      centered
      backdrop={assigningDrive ? "static" : true}
      keyboard={!assigningDrive}
    >
      <Modal.Header closeButton={!assigningDrive}>
        <Modal.Title>Assign Shared Drive to Group</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {profile && (
          <>
            {assigningDrive ? (
              <>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <p>Assigning Shared Drive to Group</p>
                <p>Please wait. This may take a few minutes depending on the size of the Shared Drive.</p>
              </>
            ) : (
              <>
                <p>Assign a new Shared Drive to <strong>{group.name}</strong></p>
                <p>This action is <strong style={{ color: "red" }}>NOT</strong> reversable.</p>

                <Form.Group className="mb-3">
                  <Form.Label>Drive ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Drive ID"
                    value={driveId}
                    onChange={(e) => setDriveId(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold mb-2">Import Options</Form.Label>

                  <div className="ms-2">
                    <Form.Check
                      type="checkbox"
                      id="import-people"
                      label="Import people (permissions)"
                      checked={importOptions.people}
                      onChange={() => handleImportOptionChange("people")}
                      className="mb-2"
                    />

                    <Form.Check
                      type="checkbox"
                      id="import-folders"
                      label="Import folders"
                      checked={importOptions.folders}
                      onChange={() => handleImportOptionChange("folders")}
                      className="mb-2"
                    />

                    <Form.Check
                      type="checkbox"
                      id="import-files"
                      label="Import files (requires folders)"
                      checked={importOptions.files}
                      disabled={!importOptions.folders}
                      onChange={() => handleImportOptionChange("files")}
                    />
                  </div>
                </Form.Group>

              </>
            )}
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <ThemeButton
          variant="outline-primary"
          onClick={handleAssignDrive}
          disabled={assigningDrive}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <FaAngleUp />
            Assign Drive
          </div>
        </ThemeButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportDriveToGroupModal;