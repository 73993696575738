import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { getThisUser } from "../apis/userApis";
import { removeUsersFromGroup, getGroupById } from "../apis/groupsApis";
import {
  FaRegSquare, FaRegSquareCheck, FaRegSquareMinus, FaUserMinus
} from "react-icons/fa6";
import ThemeButton from "../components/themeButton.jsx";

const RemoveUsersFromGroupModal = ({ show, onHide, groupId, removeUserFromGroupsResponse }) => {
  const [profile, setProfile] = useState(null);
  const [group, setGroup] = useState({ name: "", users: [] });
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [hoveredUser, setHoveredUser] = useState(null);

  const fetchUserProfile = () => {
    getThisUser()
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setProfile(response.data.user);
        } else {
          removeUserFromGroupsResponse(response.status);
          resetState();
          onHide();
        }
      })
      .catch((error) => {
        console.error(error);
        removeUserFromGroupsResponse(503);
        resetState();
        onHide();
      });
  };

  const fetchGroup = () => {
    getGroupById(groupId)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setGroup(response.data.group);
        } else {
          removeUserFromGroupsResponse(response.status);
          resetState();
          onHide();
        }
      })
      .catch((error) => {
        console.error(error);
        removeUserFromGroupsResponse(503);
        resetState();
        onHide();
      });
  };

  const resetState = () => {
    setProfile(null);
    setGroup({ name: "", users: [] });
    setSelectedUsers([]);
  };

  useEffect(() => {
    if (show) {
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (userFromStorage) {
        fetchUserProfile(userFromStorage.email);
        fetchGroup();
        removeUserFromGroupsResponse(200);
      } else {
        removeUserFromGroupsResponse(401);
        onHide();
      }
    }
  }, [show, onHide, removeUserFromGroupsResponse]);

  const toggleUserSelection = (id) => {
    if (profile.id === id) {
      return;
    }
    setSelectedUsers(prevSelected =>
      prevSelected.includes(id)
        ? prevSelected.filter(e => e !== id)
        : [...prevSelected, id]
    );
  };

  const handleRemoveUsersFromGroup = async () => {
    if (selectedUsers.length === 0) {
      alert("Please select at least one user to remove.");
      return;
    }

    const response = await removeUsersFromGroup(groupId, selectedUsers);
    if (response.status === 200) {
      alert("Users removed from group successfully.");
      resetState();
      removeUserFromGroupsResponse(200);
      onHide();
    } else {
      alert("Failed to remove users from group.\n" + response.data.message);
      removeUserFromGroupsResponse(response.status);
      resetState();
      onHide();
    }
  };

  return (
    <Modal show={show} onHide={() => {
      resetState();
      onHide();
    }} centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Remove Users From {group.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "500px", overflowY: "auto" }}>
        {profile && (
          <>
            {group.users.map((user) => (
              <div key={user.id}
                onClick={() => toggleUserSelection(user.id)}
                onKeyDown={(e) => { if (e.key === "Enter") { toggleUserSelection(user.id); } }}
                role="button" tabIndex="0"
                onMouseEnter={() => setHoveredUser(user.email)}
                onMouseLeave={() => setHoveredUser(null)}
                style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: "5px", flexDirection: "row", marginBottom: "5px" }}>
                <div style={{ display: "block", fontSize: "17px", paddingBottom: "5px" }}>
                  {user.first_name} {user.last_name}
                  <strong style={{ fontSize: "10px", marginBottom: "0px", marginLeft: "5px", marginTop: "auto", color: "gray" }}>
                    ({user.email})
                  </strong>
                  {profile.id === user.id ? (
                    <FaRegSquare style={{ color: "gray" }} />
                  ) : (
                    <>
                      {selectedUsers.includes(user.id) ? (
                        <FaRegSquareCheck style={{ color: "#50011a" }} />
                      ) : (
                        <>
                          {hoveredUser === user.email ? (
                            <FaRegSquareMinus style={{ color: "#50011a" }} />
                          ) : (
                            <FaRegSquare style={{ color: "#50011a" }} />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            ))}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ThemeButton variant="outline-danger" onClick={handleRemoveUsersFromGroup}>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <FaUserMinus /> Remove Users
          </div>
        </ThemeButton>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveUsersFromGroupModal;