import React, { useEffect, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import { FaFolder, FaFolderOpen, FaRegSquare, FaRegSquarePlus, FaRegSquareCheck, FaRegSquareMinus } from "react-icons/fa6";
import { getChildFoldersOfUploadedFolder, addFormToFolder, removeFormFromFolder } from "../apis/folderApis";
import { getFormById } from "../apis/formsApis";

const FormFolderSelectionModal = ({ show, onHide, formID, formVersionID, setFolderSelectorResponse }) => {
  const [folderStructure, setFolderStructure] = useState(null);
  const [versions, setVersions] = useState([]);
  const [currentVersion, setCurrentVersion] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [expandedFolders, setExpandedFolders] = useState([]);
  const [hoveredFolder, setHoveredFolder] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);

  const rootFolderName = "Drive";
  const rootFolderGoogleID = "***";

  const fetchFormVersions = async (formID) => {
    const versionResponse = await getFormById(formID);
    if (versionResponse && Array.isArray(versionResponse.data)) {
      setVersions(versionResponse.data.sort((a, b) =>
        new Date(b.last_updated) - new Date(a.last_updated)
      ));
      setCurrentVersion(formVersionID);
    } else {
      console.log("Error: ", versionResponse);
      setFolderSelectorResponse(versionResponse.status);
      resetState();
      onHide();
    }
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 750);
  };

  useEffect(() => {
    if (show) {
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (userFromStorage) {
        setProfile(userFromStorage);
        fetchFormVersions(formID);
        setFolderSelectorResponse(200);
        window.addEventListener("resize", handleResize);
      } else {
        setFolderSelectorResponse(401);
        onHide();
      }
    }
  }, [show, onHide]);

  useEffect(() => {
    if (profile) {
      const EmptyFolderStructure = {
        name: rootFolderName,
        google_drive_id: rootFolderGoogleID,
        children: []
      };
      setFolderStructure(EmptyFolderStructure);
      fetchFolderStructure(EmptyFolderStructure);
    }
  }, [profile]);

  const fetchFolderStructure = async (parentFolder) => {
    setIsLoading(true);
    let parentFolderID;
    if (parentFolder.google_drive_id === "***") {
      parentFolderID = -1;
    } else {
      parentFolderID = parentFolder.google_drive_id;
    }
    const fetchedFolderStructure = await getChildFoldersOfUploadedFolder(parentFolderID);
    if (fetchedFolderStructure.status >= 200 && fetchedFolderStructure.status < 300) {
      parentFolder.children = fetchedFolderStructure.data;
    } else {
      setFolderSelectorResponse(fetchedFolderStructure.status);
      resetState();
      onHide();
    }
    setIsLoading(false);
  };

  const toggleFolder = async (folderName) => {
    if (expandedFolders.includes(folderName)) {
      const newExpandedFolders = expandedFolders.filter((folder) => folder !== folderName);
      setExpandedFolders(newExpandedFolders);
    } else {
      const specificFolder = findFolderWithGoogleID(folderStructure, folderName);
      if (specificFolder && (!specificFolder.children || specificFolder.children.length === 0)) {
        await fetchFolderStructure(specificFolder);
      }
      const newExpandedFolders = [...expandedFolders, folderName];
      setExpandedFolders(newExpandedFolders);
    }
  };

  const findFolderWithGoogleID = (folderStructureToSearch, googleID) => {
    if (folderStructureToSearch.google_drive_id === googleID) {
      return folderStructureToSearch;
    } else if (folderStructureToSearch.children) {
      for (const child of folderStructureToSearch.children) {
        const foundFolder = findFolderWithGoogleID(child, googleID);
        if (foundFolder) {
          return foundFolder;
        }
      }
    }
    return null;
  };

  const handleAddFormToFolder = async (folderID) => {
    setIsLoading(true);
    const response = await addFormToFolder(folderID, formID, currentVersion);
    if (response.status >= 200 && response.status < 300) {
      setFolderSelectorResponse(200);
    } else {
      setFolderSelectorResponse(response.status);
      alert("Could not add form to folder. Error: " + response.status);
      resetState();
      onHide();
    }
    setIsLoading(false);
  };

  const handleRemoveFormFromFolder = async (folderID) => {
    setIsLoading(true);
    const response = await removeFormFromFolder(folderID, formID, currentVersion);
    if (response.status >= 200 && response.status < 300) {
      setFolderSelectorResponse(200);
    } else {
      setFolderSelectorResponse(response.status);
      alert("Could not remove form from folder. Error: " + response.status);
      resetState();
      onHide();
    }
    setIsLoading(false);
  };

  const isFormInFolder = (folder) => {
    if (!folder.forms) return false;
    return folder.forms.some(form =>
      form.form_id === formID && form.version_id === currentVersion
    );
  };

  const renderFolderStructure = (folder, parentPath = "") => {
    const folderPath = `${folder.google_drive_id}`;
    const isExpanded = expandedFolders.includes(folderPath);
    const isHovered = hoveredFolder === folderPath;

    const handleAddFormToFolderClick = (e) => {
      e.stopPropagation();
      handleAddFormToFolder(folder.id);
      folder.forms = folder.forms || [];
      folder.forms.push({ form_id: formID, version_id: currentVersion });
    };

    const handleRemoveFormFromFolderClick = (e) => {
      e.stopPropagation();
      handleRemoveFormFromFolder(folder.id);
      folder.forms = folder.forms || [];
      const index = folder.forms.findIndex(form =>
        form.form_id === formID && form.version_id === currentVersion
      );
      if (index > -1) {
        folder.forms.splice(index, 1);
      }
    };

    // Check if this folder is a direct child of the root folder
    const isDirectChildOfRoot = parentPath === rootFolderGoogleID;

    return (
      <div key={folderPath} style={{ marginBottom: "5px", paddingLeft: "10px", borderLeft: "2px solid transparent" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            padding: "5px",
            borderRadius: "3px",
            transition: "background-color 0.3s",
            backgroundColor: isHovered ? "#f0f0f0" : "transparent"
          }}
          onMouseEnter={() => setHoveredFolder(folderPath)}
          onMouseLeave={() => setHoveredFolder(null)}
          onClick={() => toggleFolder(folderPath)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              toggleFolder(folderPath);
            }
          }}
          role="button"
          tabIndex="0">
          <div style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            marginRight: "5px",
            display: "flex",
            alignItems: "center",
          }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {isExpanded ? <FaFolderOpen /> : <FaFolder />}
              <div>
                <span style={{ marginLeft: "5px" }}>{folder.name}</span>
                <span style={{ marginLeft: "5px", fontSize: "10px", color: "gray" }}>
                  (googleID: {folder.google_drive_id})
                </span>
              </div>
            </div>
            {folder.google_drive_id !== rootFolderGoogleID && !isDirectChildOfRoot && (
              <div style={{ marginLeft: "15px", color: "#50011a" }}>
                {isFormInFolder(folder) ? (
                  isHovered ? (
                    <FaRegSquareMinus
                      onClick={handleRemoveFormFromFolderClick}
                      onKeyDown={handleRemoveFormFromFolderClick}
                      role="button"
                      tabIndex="0"
                    />
                  ) : (
                    <FaRegSquareCheck />
                  )
                ) : (
                  isHovered ? (
                    <FaRegSquarePlus
                      onClick={handleAddFormToFolderClick}
                      onKeyDown={handleAddFormToFolderClick}
                      role="button"
                      tabIndex="0"
                    />
                  ) : (
                    <FaRegSquare />
                  )
                )}
              </div>
            )}
          </div>
        </div>
        {isExpanded && folder.children && folder.children.length > 0 && (
          <div style={{ marginLeft: "20px" }}>
            {folder.children.map((child) => (
              <div key={child.google_drive_id}>{renderFolderStructure(child, folder.google_drive_id)}</div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const resetState = () => {
    setExpandedFolders([]);
    setHoveredFolder(null);
    setFolderStructure(null);
    setIsLoading(false);
  };

  const handleVersionChange = (newVersion) => {
    setCurrentVersion(newVersion);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        resetState();
        onHide();
      }}
      size="lg"
      centered
    >
      <Modal.Header closeButton style={{ gap: "10px" }}>
        <Modal.Title>Form Folder Selection</Modal.Title>
        {versions.length > 0 && (
          <div className="d-flex align-items-center">
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-secondary"
                size="sm"
                style={{
                  "--bs-btn-color": "#50011a",
                  "--bs-btn-border-color": "#50011a",
                  "--bs-btn-hover-color": "#fff",
                  "--bs-btn-hover-bg": "#50011a",
                  "--bs-btn-hover-border-color": "#50011a",
                  "--bs-btn-active-color": "#fff",
                  "--bs-btn-active-bg": "#50011a",
                  "--bs-btn-active-border-color": "#50011a",
                  "--bs-btn-active-shadow": "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
                  "--bs-btn-disabled-color": "#50011a",
                  "--bs-btn-disabled-bg": "transparent",
                  "--bs-btn-disabled-border-color": "#50011a",
                  fontSize: isMobile ? "12px" : "18px"
                }}
              >
                Version: {currentVersion || "Select Version"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {versions.map((version) => (
                  <Dropdown.Item
                    key={version.version_id}
                    active={currentVersion === version.version_id}
                    onClick={() => handleVersionChange(version.version_id)}
                    style={{ "--bs-dropdown-link-active-bg": "#50011a" }}
                  >
                    {version.version_id}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </Modal.Header>
      <Modal.Body>
        {folderStructure ? (
          <>
            <div style={{ marginBottom: "10px", display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h5 style={{ marginBottom: "10px" }}>
                  Select Folders to add the form to.
                  <p style={{ fontSize: "14px", color: "gray", marginBottom: "0px" }}>
                    Form ID: {formID} | Version: {currentVersion}
                  </p>
                </h5>
              </div>
              {isLoading && (
                <div style={{ textAlign: "center" }}>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span className="sr-only">Processing...</span>
                </div>
              )}
            </div>
            <div>
              <div style={{ flex: 1, border: "1px solid #50011a", borderBottomRightRadius: "10px", borderBottomLeftRadius: "10px", marginRight: "5px", overflow: "hidden" }}>
                <h5 style={{ backgroundColor: "#50011a", color: "white", padding: "10px", marginBlockEnd: "0px" }}>
                  Interactive Folder Selection
                </h5>
                <div style={{ maxHeight: "450px", overflowY: "auto", padding: "10px", paddingRight: "20px" }}>
                  {renderFolderStructure(folderStructure)}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>Error fetching folder structure</div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default FormFolderSelectionModal;