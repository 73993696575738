import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { recalibrateGroupDrive } from "../apis/groupsApis";
import ThemeButton from "../components/themeButton";


const RecalibrateDriveModal = ({ show, onHide, groupId }) => {
  const [loading, setLoading] = useState(false);


  const recalibrateDrive = async () => {
    setLoading(true);
    const response = await recalibrateGroupDrive(groupId);
    if (response.status != 200) {
      alert("Failed to recalibrate drive");
      setLoading(false);
      return;
    }
    setLoading(false);
    alert("Drive recalibrated successfully");
    onHide();
  };


  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Recalibrate Drive
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!loading && "Are you sure you want to recalibrate this drive?"}
        {loading && "Recalibrating drive..."}
        {loading &&
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status">
            </Spinner>
          </div>
        }

      </Modal.Body>
      <Modal.Footer>
        <ThemeButton
          variant="outline-primary"
          onClick={recalibrateDrive}
          disabled={loading}
        >
          Recalibrate Drive
        </ThemeButton>
      </Modal.Footer>
    </Modal>
  );

};

export default RecalibrateDriveModal;