const baseURL = process.env.NODE_ENV === "development" ? "http://localhost:5000" : "";


/**
 * Retrieves the folder structures associated with the current user from the server.
 *
 * @return {Promise<Object>} An object containing the status code and data of the folder structures.
 *                           If the status code is 200, the data property will contain an array of folder structures.
 *                           If the status code is not 200, the data property will be undefined.
 * @throws {Error} If there is an error during the fetch request.
 */
export const getUserFolderStructures = async () => {
  try {
    const response = await fetch(`${baseURL}/api/folders/getUserFolderStructures`, {
      method: "GET",
      credentials: "include",
    });
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 503,
    };
  }
};

/**
 * Retrieves the template folder structures from the server.
 *
 * @return {Promise<Object>} An object containing the status code and data of the template folder structures.
 *                           If the status code is 200, the data property will contain an array of template folder structures.
 *                           If the status code is not 200, the data property will be undefined.
 * @throws {Error} If there is an error during the fetch request.
 */
export const getTemplateFolderStructure = async () => {
  try {
    const response = await fetch(`${baseURL}/api/folders/getTemplateFolderStructures`, {
      method: "GET",
      credentials: "include",
    });
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 503,
    };
  }
};


/**
 * Pushes the folder structure to the Google Drive.
 *
 * @param {string} folderStructureID - The ID of the folder structure to push.
 * @return {Promise<Object>} An object containing the status code of the response.
 *                           If the status code is 200, the object will have a status property.
 *                           If the status code is not 200, the object will have a status property.
 * @throws {Error} If there is an error during the fetch request.
 */
export const pushStructureToDrive = async (folderStructureID, groupID) => {
  try {
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" }
    };

    const response = await fetch(`${baseURL}/api/folders/pushStructureToDrive?structureID=${folderStructureID}&groupID=${groupID}`, requestOptions);
    // console.log(response);
    if (response.status === 200) {
      return {
        status: response.status
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 503,
    };
  }
};


/**
 * Retrieves the folder structure associated with the given folder ID from the server.
 *
 * @param {string} folderId - The ID of the folder structure to retrieve.
 * @return {Promise<Object>} An object containing the status code and data of the folder structure.
 *                           If the status code is 200, the data property will contain the folder structure.
 *                           If the status code is not 200, the data property will be undefined.
 * @throws {Error} If there is an error during the fetch request.
 */
export const getFolderStructureById = async (folderId) => {
  try {
    const response = await fetch(`${baseURL}/api/folders/getFolderStructureByID?structureID=${folderId}`,
      { method: "GET", credentials: "include" }
    );
    if (response.status === 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 503,
    };
  }
};


/**
 * Deletes a folder structure by its ID.
 *
 * @param {string} folderId - The ID of the folder structure to delete.
 * @return {Promise<Object>} An object containing the status code of the deletion request.
 *                           If the deletion is successful, the status code will be 200.
 *                           If there is an error during the deletion, the status code will be 503.
 */
export const deleteFolderStructureById = async (folderId) => {
  try {
    const response = await fetch(`${baseURL}/api/folders/deleteFolderStructureByID?structureID=${folderId}`, {
      method: "DELETE",
      credentials: "include",
    });
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 503,
    };
  }
};


/**
 * Updates a folder structure on the server.
 *
 * @param {Object} folderStructure - The updated folder structure.
 * @param {string} folderStructureID - The ID of the folder structure to update.
 * @param {number} numberOfFolders - The updated number of folders.
 * @param {string} structureName - The updated name of the structure.
 * @param {boolean} template - Indicates whether the structure is a template.
 * @return {Promise<Object>} An object containing the status of the update request.
 * @throws {Error} If there is an error during the update request.
 */
export const updateFolderStructure = async (folderStructure, folderStructureID, numberOfFolders, structureName, template) => {
  try {
    console.log(folderStructure, folderStructureID, numberOfFolders, structureName, template);
    const response = await fetch(`${baseURL}/api/folders/updateFolderStructure?structureID=${folderStructureID}&numberOfFolders=${numberOfFolders}&name=${structureName}&template=${template}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(folderStructure),
    });
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 503,
    };
  }
};


/**
 * Adds a new folder structure to the server.
 *
 * @param {Object} folderStructure - The folder structure to be added.
 * @param {number} numberOfFolders - The number of folders in the structure.
 * @param {string} structureName - The name of the structure.
 * @param {boolean} template - Indicates whether the structure is a template.
 * @return {Promise<Object>} An object with the status code of the request.
 *                           If the status code is 200, the folder structure was added successfully.
 *                           If the status code is not 200, an error occurred.
 */
export const addFolderStructure = async (folderStructure, numberOfFolders, structureName, template) => {
  console.log("folderStructure: ", folderStructure);
  try {
    const newStructure = { ...folderStructure }; // Create a copy of the current folder structure
    structureName = `${structureName}`;

    const response = await fetch(`${baseURL}/api/folders/addFolderStructure?numberOfFolders=${numberOfFolders}&name=${structureName}&template=${template}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newStructure),
    });
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Sends a POST request to the server to check if a given folder structure is valid.
 *
 * @param {Object} folderStructure - The folder structure to be validated.
 * @return {Promise<Object>} An object containing the status of the request. If the request is successful, the status will be 200. Otherwise, it will be the status code of the response.
 */
export const getIsFolderStructureValid = async (folderStructure) => {
  try {
    const response = await fetch(`${baseURL}/api/folders/isFolderStructureValid`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: (folderStructure),
    });
    console.log(response);
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Retrieves the child folders of an uploaded folder from the server.
 *
 * @param {string} folderID - The ID of the uploaded folder.
 * @return {Promise<Object>} An object containing the status code and data of the child folders.
 *                           If the status code is 200, the data property will contain the child folders.
 *                           If the status code is not 200, the data property will be undefined.
 * @throws {Error} If there is an error during the fetch request.
 */
export const getChildFoldersOfUploadedFolder = async (folderID) => {
  try {
    const response = await fetch(`${baseURL}/api/folders/getChildFoldersOfUploadedFolder?folderID=${folderID}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json()
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520
    };
  }
};

/**
 * Adds a form to a folder.
 *
 * @param {string} folderID - The ID of the folder.
 * @param {string} formID - The ID of the form.
 * @return {Promise<Object>} An object containing the status of the request. If the request is successful, the status will be 200. Otherwise, it will be the status code of the response.
 */
export const addFormToFolder = async (folderID, formID, formVersionID) => {
  try {
    const response = await fetch(`${baseURL}/api/folders/addFormToFolder?folderID=${folderID}&formID=${formID}&formVersion=${formVersionID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520
    };
  }
};

/**
 * Sends a PUT request to remove a form from a folder.
 *
 * @param {string} folderID - The ID of the folder from which the form will be removed.
 * @param {string} formID - The ID of the form to be removed from the folder.
 * @return {Promise<{status: number}>} A promise that resolves to an object containing the status of the request.
 */
export const removeFormFromFolder = async (folderID, formID, formVersionID) => {
  try {
    const response = await fetch(`${baseURL}/api/folders/removeFormFromFolder?folderID=${folderID}&formID=${formID}&formVersion=${formVersionID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520
    };
  }
};


/**
 * Sends a PATCH request to update the name of a Google folder.
 *
 * @param {string} folderID - The ID of the folder to update.
 * @param {string} folderName - The new name for the folder.
 * @return {Promise<{status: number}>} A promise that resolves to an object containing the status of the request.
 */
export const updateGoogleFolderName = async (folderID, folderName) => {
  try {
    const response = await fetch(`${baseURL}/api/folders/updateGoogleFolderName?folderID=${folderID}&newName=${folderName}`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520
    };
  }
};

/**
 * Sends a POST request to add a child folder to the specified parent folder in the drive.
 *
 * @param {string} folderID - The ID of the parent folder.
 * @param {string} folderName - The name of the child folder to be added.
 * @return {Promise<{status: number}>} A promise that resolves to an object with a status property indicating the HTTP status code of the response.
 * If the request is successful, the status property will be 200. If there is an error, the status property will be 520.
 */
export const addChildFolderToDrive = async (folderID, folderName) => {
  try {
    const response = await fetch(`${baseURL}/api/folders/addChildFolderToDrive?folderID=${folderID}&folderName=${folderName}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520
    };
  }
};