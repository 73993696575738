import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { addGroupToClient } from "../apis/groupsApis";

const AddGroupForm = ({ show, handleClose, setAddGroupResponse }) => {
  const [groupName, setGroupName] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await addGroupToClient(groupName);
      console.log(response);
      if (response.status === 200) {
        setAddGroupResponse(200);
        alert("Group added successfully.");
        handleClose();
        setGroupName("");
      }
      else if (response.status === 404) {
        setAddGroupResponse(404);
        alert("Failed to add group. Check entered group name. Please try again later.");
      }
      else {
        setAddGroupResponse(500);
        alert("Failed to add group. Please try again later.");
      }
    } catch (error) {
      console.error(error);
      setAddGroupResponse(500);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formGroupName">
            <Form.Label>Group Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter group name"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" style={{
            marginTop: "10px",
            "--bs-btn-color": "#fff",
            "--bs-btn-bg": "#50011a",
            "--bs-btn-border-color": "#50011a",
            "--bs-btn-hover-color": "#fff",
            "--bs-btn-hover-bg": "#960231",
            "--bs-btn-hover-border-color": "#0a2e50",
            "--bs-btn-focus-shadow-rgb": "80, 1, 26",
            "--bs-btn-active-color": "#fff",
            "--bs-btn-active-bg": "#0a2e50",
            "--bs-btn-active-border-color": "#0a2d5a",
            "--bs-btn-active-shadow": "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
            "--bs-btn-disabled-color": "#fff",
            "--bs-btn-disabled-bg": "#50011a",
            "--bs-btn-disabled-border-color": "#50011a",
          }}>
            Add Group
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddGroupForm;