import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getThisUser } from "../apis/userApis";
import { getClientOverview } from "../apis/clientApis";
import ThemeNavbar from "../components/themeNavbar.jsx";
import Footer from "../components/footer.jsx";

function HomePage() {
  const [profile, setProfile] = useState(null);
  const [serverError, setServerError] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);
  const [clientData, setClientData] = useState(null);
  const navigate = useNavigate();


  const fetchUserProfile = (email) => {
    console.log("Fetching profile for: " + email);
    getThisUser(email)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setProfile(response.data.user);
        } else {
          setServerError(response.status + " " + response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setServerError(503);
      });
  };

  const fetchClientData = async () => {
    getClientOverview()
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          console.log(response.data);
          setClientData(response.data);
        } else {
          setServerError(response.status + " " + response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setServerError(503);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (!userFromStorage) {
        setServerError("401 Unauthorized Access");
        return;
      }

      try {
        await fetchUserProfile(userFromStorage.email);
        await fetchClientData();
      } catch (error) {
        console.error("Error fetching data:", error);
        setServerError("500 Internal Server Error");
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    };

    fetchData();
  }, [navigate]);

  const handleResize = () => {
    if (window.innerWidth < 750) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  return (
    <div className="site-container">
      <ThemeNavbar />
      <body className="site-body"
        style={{
          backgroundColor: "transparent",
          height: "calc(100vh - 125px)",
        }}>
        {profile && (
          <>
            <div className="container py-4 bg-white rounded shadow-sm">
              <h2 className="display-4 mb-4">Datascribe Studio</h2>
              <p>
                This webapp is intended to aid in the creation of folder structures in Google Drive, as well as provide a framework for creating, sharing, and filling out forms with pertinent data.
              </p>
              <h4 className="mb-4" style={{ fontSize: "1.5rem" }}>Datascribe Information</h4>
              <p>
                At Datascribe.cloud, <b>Our vision</b> is to support researchers and small businesses in unlocking the full potential of their data through accessible and affordable cloud-based solutions. We strive to be the go-to platform for innovative data management and analytics, empowering our users to drive impactful discoveries and informed decisions. By focusing on simplicity, reliability, and exceptional support, we aim to foster a collaborative environment that accelerates research and growth.
              </p>
              <p>
                At Datascribe.cloud, <b>Our mission</b> is to accelerate research with innovative, scalable, and secure cloud-based data solutions. We aim to simplify data management, enhance analytics capabilities, and drive informed decision-making by providing intuitive, reliable, and cost-effective services tailored to meet the unique needs of reseachers.
              </p>
              <h4 className="mb-4" style={{ fontSize: "1.5rem" }}>Helpful Links/Resources</h4>
              {profile && (
                <>
                  <p>
                    For help/faqs, refer to the{" "}
                    <a
                      href="https://docs.google.com/document/d/1dog4_ZCKJAA9IQ5uyxEEd7Xo3S0Ta--1Nb52ZgDKl3U/edit?usp=sharing"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#500000", textDecoration: "underline" }} // Aggie Maroon with underline
                      className="text-decoration-underline"
                    >
                      user documentation
                    </a>
                  </p>
                </>
              )}
            </div>
            {clientData && (
              <div className="container d-flex justify-content-between mt-4" style={{ flexDirection: isMobile ? "column" : "row", gap: "10px" }}>
                <div className="container" style={{ backgroundColor: "white", width: isMobile ? "100%" : "40vw", border: "1px solid #000", padding: "20px", marginBottom: "20px", borderRadius: "10px" }}>
                  <h2 className="text-center">Data Trees</h2>
                  <p className="text-center">
                    <i className="fas fa-folder-open fa-3x"></i>
                  </p>
                  <p>
                    Organize and manage your documents with ease. Our folder structure system helps you keep your files in order and accessible.
                  </p>
                  <ul className="list-group">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <span>Your Data Trees</span>
                      <span className="badge bg-primary rounded-pill">{clientData.userFolderTemplatesCount}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <span>Data Trees in Organization</span>
                      <span className="badge bg-primary rounded-pill">{clientData.totalFolderTemplatesCount}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <span>Folders Uploaded to Drive</span>
                      <span className="badge bg-primary rounded-pill">{clientData.totalFoldersCount}</span>
                    </li>
                  </ul>
                </div>
                <div className="container" style={{ backgroundColor: "white", width: isMobile ? "100%" : "40vw", border: "1px solid #000", padding: "20px", marginBottom: "20px", borderRadius: "10px" }}>
                  <h2 className="text-center">Data Travellers</h2>
                  <p className="text-center">
                    <i className="fas fa-file-alt fa-3x"></i>
                  </p>
                  <p>
                    Create, share, and collect data seamlessly. Our form management system ensures you gather the information you need efficiently.
                  </p>
                  <ul className="list-group">
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <span>Your Data Travellers</span>
                      <span className="badge bg-success rounded-pill">{clientData.userFormTemplatesCount}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <span>Data Travellers in Organization</span>
                      <span className="badge bg-success rounded-pill">{clientData.totalFormTemplatesCount}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <span>Responses Dispatched</span>
                      <span className="badge bg-success rounded-pill">{clientData.totalResponsesCount}</span>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </>
        )}
        {
          serverError && serverError === "401 Unauthorized Access" && (
            <div className='site-container'>
              <header className="site-header">
                <div className="title-area">
                  <h1 className="site-title">
                    <a href="https://birdshot.tamu.edu/">Birdshot Center</a>
                  </h1>
                </div>
                <div className="widget-area header-widget-area">
                  <a id="site-logo" href="https://birdshot.tamu.edu" style={{ display: "block", maxWidth: "350px" }}>
                    <img id="custom-header" src="https://birdshot.tamu.edu/wp-content/themes/coe-theme/images/logos/coe/logos-horizontal/ENGINEERING_logo_HWHT.png" alt="Texas A&M University College of Engineering" />
                  </a>
                </div>
              </header>
              <body className='site-body'>
                <h2 className='body-title'>Automated Google Web Form Creator and Manager</h2>
                <p className='login-response'>401 Unauthorized Access</p>
                <p>Please <a href="/" className='link'>Login</a> to continue.</p>
              </body>
            </div>
          )}
        {
          serverError && serverError !== "401 Unauthorized Access" && (
            <div>
              {/* <ThemeNavbar></ThemeNavbar> */}
              <div className='server-error-container'>
                {serverError === "403 Forbidden Access"
                  ? (
                    <>
                      <p className='login-response'>
                        {serverError}
                      </p>
                    </>
                  )
                  : (
                    <>
                      <p className='login-response'>
                        {serverError}
                      </p>
                    </>
                  )
                }
              </div>
            </div>
          )
        }
      </ body>
      <Footer />
    </div>
  );
}

export default HomePage;
