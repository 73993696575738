const baseURL = process.env.NODE_ENV === "development" ? "http://localhost:5000" : "";

/**
 * Fetches the user forms from the server.
 *
 * @return {Promise<{status: number, data: any}> | {status: number}} A promise that resolves to an object
 * containing the status code and the data if the request is successful, or an object with only the status code if the request fails.
 */
export const getUserForms = async () => {
  try {
    const response = await fetch(`${baseURL}/api/forms/getUserForms`,
      { method: "GET", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Retrieves the template forms from the server.
 *
 * @return {Promise<{status: number, data: any}> | {status: number}} A promise that resolves to an object
 * containing the status code and the data if the request is successful, or an object with only the status code if the request fails.
 */
export const getTemplateForms = async () => {
  try {
    const response = await fetch(`${baseURL}/api/forms/getTemplateForms`,
      { method: "GET", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Retrieves a form by its ID from the server.
 *
 * @param {string} formID - The ID of the form to retrieve.
 * @return {Promise<{status: number, data: any}> | {status: number}} A promise that resolves to an object
 * containing the status code and the data if the request is successful, or an object with only the
 * status code if the request fails.
 */
export const getFormById = async (formID, formVersionID) => {
  let url = `${baseURL}/api/forms/getFormById?formID=${formID}`;
  if (formVersionID) {
    url += `&formVersionID=${formVersionID}`;
  }
  try {
    const response = await fetch(url,
      { method: "GET", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Retrieves all fields from the server.
 *
 * @return {Promise<Object>} An object containing the status code and the data if the request is successful,
 *                           or an object with only the status code if the request fails.
 * @throws {Error} If there is an error during the fetch request.
 */
export const getAllFields = async () => {
  try {
    const response = await fetch(`${baseURL}/api/forms/getAllFields`,
      { method: "GET", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Retrieves a field from the server by its ID.
 *
 * @param {string} fieldID - The ID of the field to retrieve.
 * @return {Promise<{status: number, data: any}> | {status: number}} A promise that resolves to an object
 * containing the status code and the field data if the request is successful, or an object with only
 * the status code if the request fails.
 */
export const getFieldById = async (fieldID) => {
  try {
    const response = await fetch(`${baseURL}/api/forms/getFieldById?fieldID=${fieldID}`,
      { method: "GET", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Updates a field in the server's database.
 *
 * @param {string} fieldID - The ID of the field to update.
 * @param {object} field - The updated field object.
 * @return {Promise<{status: number, data: any}> | {status: number}} A promise that resolves to an object
 * containing the status code and the updated data if the request is successful, or an object with only the
 * status code if the request fails.
 */
export const updateField = async (fieldID, field) => {
  try {
    const response = await fetch(`${baseURL}/api/forms/updateField?fieldID=${fieldID}`,
      {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(field)
      }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Adds a field to the server's database.
 *
 * @param {object} field - The field object to be added.
 * @return {Promise<{status: number, data: any}> | {status: number}} A promise that resolves to an object
 * containing the status code and the added data if the request is successful, or an object with only the
 * status code if the request fails.
 */
export const addField = async (field) => {
  try {
    const response = await fetch(`${baseURL}/api/forms/addField`,
      {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(field)
      }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Deletes a field by its ID.
 *
 * @param {string} fieldID - The ID of the field to be deleted.
 * @return {Promise<Object>} An object containing the status of the response and the data if successful, or just the status if unsuccessful.
 * If an error occurs, an object with a status of 520 is returned.
 */
export const deleteField = async (fieldID) => {
  try {
    const response = await fetch(`${baseURL}/api/forms/deleteField?fieldID=${fieldID}`,
      { method: "DELETE", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Updates a form in the server's database.
 *
 * @param {string} formID - The ID of the form to be updated.
 * @param {object} form - The updated form object.
 * @return {Promise<{status: number, data: any}> | {status: number}} A promise that resolves to an object
 * containing the status code and the updated data if the request is successful, or an object with only the
 * status code if the request fails.
 */
export const updateForm = async (formID, form, formVersion) => {
  try {
    const response = await fetch(`${baseURL}/api/forms/updateForm?formID=${formID}&formVersion=${formVersion}`,
      {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(form)
      }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Adds a form to the server.
 *
 * @param {Object} form - The form object to be added.
 * @return {Promise<Object>} An object containing the status of the response and the data if successful, or just the status if unsuccessful.
 * If an error occurs, an object with a status of 520 is returned.
 */
export const addForm = async (form, template, formVersion, formID) => {
  let url = `${baseURL}/api/forms/addForm?template=${template}`;
  if (formVersion) {
    url += `&formVersion=${formVersion}`;
  }
  if (formID) {
    url += `&formID=${formID}`;
  }
  try {
    const response = await fetch(url,
      {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(form)
      }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Deletes a form by its ID.
 *
 * @param {string} formID - The ID of the form to be deleted.
 * @return {Promise<Object>} An object containing the status of the response and the data if successful, or just the status if unsuccessful.
 * If an error occurs, an object with a status of 520 is returned.
 */
export const deleteForm = async (formID) => {
  try {
    const response = await fetch(`${baseURL}/api/forms/deleteForm?formID=${formID}`,
      { method: "DELETE", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Checks if the structure of a form is valid.
 *
 * @param {Object} jsonFile - The JSON file containing the form structure.
 * @return {Promise<Object>} An object containing the status of the response.
 * If successful, the object will have a status property set to 200.
 * If unsuccessful, the object will have a status property set to the response status.
 * If an error occurs, the object will have a status property set to 520.
 */
export const isFormStructureValid = async (jsonFile) => {
  try {
    const response = await fetch(`${baseURL}/api/forms/isFormStructureValid`,
      {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: jsonFile
      }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Retrieves the UI JSON data of a form by its ID from the server.
 *
 * @param {string} formID - The ID of the form to retrieve the UI JSON for.
 * @return {Promise<{status: number, data: any}> | {status: number}} A promise that resolves to an object
 * containing the status code and the UI JSON data if the request is successful, or an object with only the
 * status code if the request fails.
 */
export const getFormUiJsonById = async (formID, formVersion) => {
  try {
    const response = await fetch(`${baseURL}/api/forms/getFormUiJsonById?formID=${formID}&formVersion=${formVersion}`,
      { method: "GET", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};