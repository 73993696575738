const baseURL = process.env.NODE_ENV === "development" ? "http://localhost:5000" : "";

/**
 * Retrieves the client information for the current admin user.
 *
 * @return {Promise<Object>} A promise that resolves to an object containing the status and data of the response.
 *   - status: The HTTP status code of the response.
 *   - data: The JSON data of the response, if the status is 200.
 * @throws {Error} If there is an error during the fetch request.
 */
export const getClientForAdmin = async () => {
  try {
    const response = await fetch(`${baseURL}/api/client/getClientForAdmin`,
      { method: "GET", credentials: "include" }
    );
    if (response.status >= 200 && response.status < 300) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Opens a client for registration. This allows users to register for the client.
 *
 * @return {Promise<Object>} A promise that resolves to an object containing the status and data of the response.
 *   - status: The HTTP status code of the response.
 *   - data: The JSON data of the response, if the status is 200.
 * @throws {Error} If there is an error during the fetch request.
 */
export const openClientForRegistration = async () => {
  try {
    const response = await fetch(`${baseURL}/api/client/openClientForRegistration`,
      { method: "PUT", credentials: "include" }
    );
    if (response.status >= 200 && response.status < 300) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,

    };
  }
};

/**
 * Closes a client for registration. This prevents users from registering for the client.
 *
 * @return {Promise<Object>} A promise that resolves to an object containing the status and data of the response.
 *   - status: The HTTP status code of the response.
 *   - data: The JSON data of the response, if the status is 200.
 * @throws {Error} If there is an error during the fetch request.
 */
export const closeClientForRegistration = async () => {
  try {
    const response = await fetch(`${baseURL}/api/client/closeClientForRegistration`,
      { method: "PUT", credentials: "include" }
    );
    if (response.status >= 200 && response.status < 300) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};


/**
 * Retrieves all clients from the server.
 *
 * @return {Promise<Object>} A promise that resolves to an object containing the status and data of the response.
 *   - status: The HTTP status code of the response.
 *   - data: The JSON data of the response, if the status is 200.
 * @throws {Error} If there is an error during the fetch request.
 */
export const getAllClients = async () => {
  try {
    const response = await fetch(`${baseURL}/api/client/getAllClients`,
      { method: "GET", credentials: "include" }
    );
    if (response.status >= 200 && response.status < 300) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Updates the status of a client.
 *
 * @param {string} clientId - The ID of the client to update.
 * @param {string} status - The new status for the client.
 *
 * @return {Promise<Object>} A promise that resolves to an object containing the status and data of the response.
 *   - status: The HTTP status code of the response.
 *   - data: The JSON data of the response, if the status is 200.
 * @throws {Error} If there is an error during the fetch request.
 */
export const updateClientStatus = async (clientId, status) => {
  try {
    const response = await fetch(`${baseURL}/api/client/updateClientStatus?clientId=${clientId}&status=${status}`,
      { method: "PUT", credentials: "include" }
    );
    if (response.status >= 200 && response.status < 300) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};


/**
 * Retrieves an overview of the current client.
 *
 * @return {Promise<Object>} A promise that resolves to an object containing the status and data of the response.
 *   - status: The HTTP status code of the response.
 *   - data: The JSON data of the response, if the status is 200.
 * @throws {Error} If there is an error during the fetch request.
 */
export const getClientOverview = async () => {
  try {
    const response = await fetch(`${baseURL}/api/client/getClientOverview`,
      { method: "GET", credentials: "include" }
    );
    if (response.status >= 200 && response.status < 300) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};