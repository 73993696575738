// src/index.js or src/App.js
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

const NotFoundPage = () => {
  return (
    <Container className="text-center my-5">
      <Row>
        <Col>
          <h1 className="display-4">404 - Page Not Found</h1>
          <p className="lead">The page you are looking for does not exist.</p>
          <Button variant="primary" href="/home">Go to Homepage</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFoundPage;
