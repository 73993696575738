import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { createGroupDrive, getGroupById } from "../apis/groupsApis";
import { getUserByEmail } from "../apis/userApis";
import ThemeButton from "../components/themeButton";
import { FaAngleUp } from "react-icons/fa6";



const AssignDriveToGroupModal = ({ show, onHide, setAssignDriveResponse, groupId }) => {

  const [profile, setProfile] = useState({});
  const [group, setGroup] = useState({});
  // const [isMobile, setIsMobile] = useState(window.innerWidth < 750);
  const [assigningDrive, setAssigningDrive] = useState(false);

  const fetchUserProfile = (email) => {
    getUserByEmail(email)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setProfile(response.data.user);
        } else {
          setAssignDriveResponse(response.status);
          resetState();
          onHide();
        }
      })
      .catch((error) => {
        console.error(error);
        setAssignDriveResponse(503);
        resetState();
        onHide();
      });
  };

  const fetchGroup = async (groupId) => {
    try {
      getGroupById(groupId).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          console.log(response.data.group);
          setGroup(response.data.group);
        } else {
          setAssignDriveResponse(response.status);
          resetState();
          onHide();
        }
      });
    } catch (error) {
      console.error(error);
      setAssignDriveResponse(503);
      resetState();
      onHide();
    }
  };


  useEffect(() => {
    if (show) {
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (userFromStorage) {
        fetchUserProfile(userFromStorage.email);
        fetchGroup(groupId);
        setAssignDriveResponse(200);
        // window.addEventListener("resize", handleResize);
      } else {
        setAssignDriveResponse(401);
        onHide();
      }
    }
  }, [show, onHide, setAssignDriveResponse]);


  const resetState = () => {
    setProfile({});
    setGroup({});
    setAssigningDrive(false);
  };


  const handleAssignDrive = async () => {
    setAssigningDrive(true);

    const createGroupDriveResponse = await createGroupDrive(group.id);
    if (createGroupDriveResponse.status >= 200 && createGroupDriveResponse.status < 300) {
      alert("Drive assigned successfully.");
      setAssignDriveResponse(createGroupDriveResponse.status);
      resetState();
      onHide();
    } else {
      // console.log(createGroupDriveResponse.data);
      let msg = await JSON.stringify(createGroupDriveResponse.data.message);
      alert("Failed to assign Drive. Error: " + msg);
      setAssignDriveResponse(createGroupDriveResponse.status);
      resetState();
      onHide();
    }
  };

  return (
    <Modal show={show} onHide={() => {
      resetState();
      onHide();
    }} centered>
      <Modal.Header closeButton>
        <Modal.Title>Assign Shared Drive to Group</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {profile && (
          <>
            {assigningDrive ? (
              <>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <p>Assigning Shared Drive to Group</p>
                <p>Please wait. This takes around 15 seconds.</p>
              </>
            ) : (
              <>
                <p>Assign a new Shared Drive to <strong>{group.name}</strong></p>
                <p>This action is <strong style={{ color: "red" }}>NOT</strong> reversable.</p>
              </>
            )}
          </>
        )}

      </Modal.Body>

      <Modal.Footer>
        <ThemeButton variant="outline-primary" onClick={handleAssignDrive}>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <FaAngleUp />
            Assign Drive
          </div>
        </ThemeButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignDriveToGroupModal;