import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import "./../styles/googleLoginButtonStyles.css";


const GoogleLoginButton = ({ validateUserFunction }) => {
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => validateUserFunction(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/drive",
    include_granted_scopes: false,
  });


  return (
    <>
      <button type="button" className="login-with-google-btn" onClick={() => login()} >
        Sign in with Google to access the app
      </button>
    </>
  );
};

export default GoogleLoginButton;
