import React, { useState, useEffect } from "react";
import LandingPageNavbar from "../components/landingPageNavbar";
import Footer from "../components/footer";

const ContactPage = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 750);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className='site-container'>
      <LandingPageNavbar />
      <main className="flex-grow container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6" style={{ marginBottom: "3rem", marginTop: "3rem" }}>Contact Us</h1>
        <div style={{ display: "flex", justifyContent: "space-between", height: "100%", flexDirection: isMobile ? "column" : "row", gap: "1rem" }}>
          <div className="container" style={{ backgroundColor: "white", borderRadius: "10px", padding: "2rem" }}>
            <section className="mb-8" style={{ marginBottom: "3rem" }}>
              <h2 className="text-2xl font-semibold mb-4" style={{ color: "#50011a" }}>Main Contact</h2>
              <p className="mb-2">
                <strong>Name:</strong> Vahid Attari
              </p>
              <p className="mb-2">
                <strong>Email:</strong> <a href="mailto:contact@attari.v@tamu.edu" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">attari.v@tamu.edu</a>
              </p>
            </section>
            <section className="mb-8" style={{ marginBottom: "3rem" }}>
              <h2 className="text-2xl font-semibold mb-4" style={{ color: "#50011a" }}>Developer Contact</h2>
              <p className="mb-2">
                <strong>Name:</strong> Divyanshu Singh
              </p>
              <p className="mb-2">
                <strong>Email:</strong> <a href="mailto:divyanshu@tamu.edu" className="text-blue-600 hover:underline">divyanshu@tamu.edu</a>
              </p>
              <p>
                <strong>Report Feature/Bug:</strong> <a href="https://docs.google.com/forms/d/e/1FAIpQLSd22F0NfqY3c-rtJGjcdpM80RFFHcrGOvKj81jgVtWBEkPjOA/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">Google Form</a>
              </p>
            </section>
            <section>
              <h2 className="text-2xl font-semibold mb-4" style={{ color: "#50011a" }}>Research Lab</h2>
              <p>
                This application is associated with the{" "}
                <a href="https://birdshot.tamu.edu/" target="_blank" rel="noopener noreferrer"
                  className="text-blue-600 hover:underline">
                  Birdshot Center
                </a>
              </p>
              <p>
                Research Page:{" "}
                <a href="https://vahid2364.github.io" target="_blank" rel="noopener noreferrer"
                  className="text-blue-600 hover:underline">
                  Materials Insight Research Group
                </a>
              </p>
            </section>
          </div>
          <div className="container" style={{ backgroundColor: "white", borderRadius: "10px", padding: "2rem" }}>
            <section>
              <h2 className="text-2xl font-semibold mb-4" style={{ color: "#50011a" }}>Address</h2>
              <p className="mb-4">
                Materials Science Department<br />
                Reed-McDonald Bldg, 575 Ross St<br />
                Texas A&M University<br />
                College Station, Texas - 77840
              </p>
              <div className="aspect-w-16 aspect-h-9" style={{ borderRadius: "10px", overflow: "hidden" }}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12101.169381693482!2d-96.35164957418615!3d30.620124343007564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86468396feea26bf%3A0x606abb3339881012!2sDepartment%20of%20Materials%20Science%20%26%20Engineering!5e0!3m2!1sen!2sus!4v1728423196311!5m2!1sen!2sus"
                  width="600"
                  height="450"
                  style={{ border: 0, borderRadius: "10px" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="TAMU Materials Science Department Location"
                  className="w-full h-full"
                ></iframe>
              </div>
            </section>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ContactPage;