import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getFormById, getAllFields, getFieldById, addForm, updateForm } from "../apis/formsApis";
import {
  FaAngleDown, FaPenToSquare, FaRegSquarePlus, FaPen, FaCheck, FaXmark, FaPlus, FaUpDown,
  FaAngleUp, FaTrash, FaCircleInfo, FaCopy
} from "react-icons/fa6";
import "../styles/FormViewStyles.css";
import ThemeButton from "../components/themeButton";

const EditFormModal = ({ show, onHide, formID, formVersionID, setViewFormModal }) => {
  const [form, setForm] = useState(null);
  const [versions, setVersions] = useState([]);
  const [currentVersion, setCurrentVersion] = useState(null);
  const [newVersionForSave, setNewVersionForSave] = useState("");
  const [isTemplate, setIsTemplate] = useState(false);
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedQuestions, setExpandedQuestions] = useState([]);
  const [editFormName, setEditFormName] = useState(false);
  const [currentFormName, setCurrentFormName] = useState("");
  const [editFormTitle, setEditFormTitle] = useState(false);
  const [currentFormTitle, setCurrentFormTitle] = useState("");
  const [editFormDescription, setEditFormDescription] = useState(false);
  const [currentFormDescription, setCurrentFormDescription] = useState("");
  const [templateFields, setTemplateFields] = useState(null);
  const [currentQuestions, setCurrentQuestions] = useState([]);
  const [isReorderingQuestions, setIsReorderingQuestions] = useState(false);
  const [editingQuestionTitle, setEditingQuestionTitle] = useState([]);
  const [editingQuestionType, setEditingQuestionType] = useState([]);
  const [editingQuestionRequired, setEditingQuestionRequired] = useState([]);
  const [editingQuestionChoices, setEditingQuestionChoices] = useState([]);
  const [editingQuestionFileType, setEditingQuestionFileType] = useState([]);
  const [editingQuestionScaleRange, setEditingQuestionScaleRange] = useState([]);
  const [editingQuestionSubQuestions, setEditingQuestionSubQuestions] = useState([]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);


  useEffect(() => {
    if (show) {
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (userFromStorage) {
        setProfile(userFromStorage);
        setViewFormModal(200);
        window.addEventListener("resize", handleResize);
      } else {
        setViewFormModal(401);
        onHide();
      }
    }
  }, [show, onHide, setViewFormModal]);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 750);
  };

  useEffect(() => {
    if (show && profile) {
      fetchForm();
    }
  }, [show, profile]);

  const getQuestionTypeText = (questionType) => {
    switch (questionType) {
      case "shortAnswer":
        return "Short Answer";
      case "paragraph":
        return "Paragraph";
      case "checkboxes":
        return "Checkboxes";
      case "multipleChoice":
        return "Multiple Choice";
      case "dropdown":
        return "Dropdown";
      case "date":
        return "Date";
      case "time":
        return "Time";
      case "fileUpload":
        return "File Upload";
      case "scale":
        return "Scale";
      case "numeric":
        return "Numeric";
      case "group":
        return "Group";
      default:
        return "Unknown";
    }
  };

  const fetchForm = async () => {
    setIsLoading(true);
    const response = await getFormById(formID, formVersionID);

    if (response.status >= 200 && response.status < 300) {
      const formData = response.data[0];
      setForm(formData);
      setCurrentVersion(formData.version_id);
      setIsTemplate(formData.template);
      setCurrentFormName(formData.name);
      setCurrentFormTitle(formData.structure.title);
      setCurrentFormDescription(formData.structure.description);
      // JS only copies first level properties, nested ones are referenced (Objects and Arrays)
      const formQuestionsDeepCopy = JSON.parse(JSON.stringify(formData.structure.questions));
      setCurrentQuestions(formQuestionsDeepCopy);
      // console.log(form.structure.title);
    } else {
      console.log("Error: ", response);
      setViewFormModal(response.status);
      onHide();
    }

    // get versions
    const versionResponse = await getFormById(formID);
    console.log(versionResponse.data);
    if (versionResponse && Array.isArray(versionResponse.data)) {
      setVersions(versionResponse.data.sort((a, b) =>
        new Date(b.last_updated) - new Date(a.last_updated)
      ));
    }

    const fieldResponse = await getAllFields();
    if (fieldResponse.status >= 200 && fieldResponse.status < 300) {
      setTemplateFields(fieldResponse.data);
    } else {
      console.log(" Error: ", fieldResponse);
      setViewFormModal(fieldResponse.status);
      onHide();
    }
    setIsLoading(false);
  };

  const handleVersionChange = async (newVersion) => {
    // const newVersion = ;
    setCurrentVersion(newVersion);
    setIsLoading(true);
    const response = await getFormById(formID, newVersion);
    if (response.status >= 200 && response.status < 300) {
      const formData = response.data[0];
      setForm(formData);
      setIsTemplate(formData.template);
      setCurrentFormName(formData.name);
      setCurrentFormTitle(formData.structure.title);
      setCurrentFormDescription(formData.structure.description);
      // JS only copies first level properties, nested ones are referenced (Objects and Arrays)
      const formQuestionsDeepCopy = JSON.parse(JSON.stringify(formData.structure.questions));
      setCurrentQuestions(formQuestionsDeepCopy);
      // console.log(form.structure.title);
    } else {
      console.log("Error: ", response);
      setViewFormModal(response.status);
      onHide();
    }
    setIsLoading(false);
  };

  const handleEditFormNameMode = () => {
    setEditFormName(!editFormName);
  };

  const handleFormNameChange = (event) => {
    setCurrentFormName(event.target.value);
  };

  const handleSaveFormName = () => {
    setForm({ ...form, name: currentFormName });
    setEditFormName(false);
  };

  const handleCancelFormNameChange = () => {
    setEditFormName(false);
  };

  const handleEditFormTitleMode = () => {
    setEditFormTitle(!editFormTitle);
  };

  const handleFormTitleChange = (event) => {
    setCurrentFormTitle(event.target.value);
  };

  const handleSaveFormTitle = () => {
    const updatedForm = { ...form };
    updatedForm.structure.title = currentFormTitle;
    setForm(updatedForm);
    setEditFormTitle(false);
  };

  const handleCancelFormTitleChange = () => {
    setEditFormTitle(false);
  };

  const handleEditFormDescriptionMode = () => {
    setEditFormDescription(!editFormDescription);
    console.log(form);
  };

  const handleFormDescriptionChange = (event) => {
    setCurrentFormDescription(event.target.value);
  };

  const handleSaveFormDescription = () => {
    const updatedForm = { ...form };
    updatedForm.description = currentFormDescription;
    updatedForm.structure.description = currentFormDescription;
    setForm(updatedForm);
    setEditFormDescription(false);
  };

  const handleCancelFormDescriptionChange = () => {
    setEditFormDescription(false);
  };

  const handleReorderQuestionsMode = () => {
    setExpandedQuestions([]);
    setIsReorderingQuestions(!isReorderingQuestions);
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleMoveUpQuestion = (index) => {
    if (index > 0) {
      const updatedQuestions = [...currentQuestions];
      const temp = updatedQuestions[index];
      updatedQuestions[index] = updatedQuestions[index - 1];
      updatedQuestions[index - 1] = temp;
      setCurrentQuestions(updatedQuestions);
    }
  };

  const handleMoveDownQuestion = (index) => {
    if (index < currentQuestions.length - 1) {
      const updatedQuestions = [...currentQuestions];
      const temp = updatedQuestions[index];
      updatedQuestions[index] = updatedQuestions[index + 1];
      updatedQuestions[index + 1] = temp;
      setCurrentQuestions(updatedQuestions);
    }
  };

  const handleQuestionDelete = (index) => {
    const updatedQuestions = [...currentQuestions];
    updatedQuestions.splice(index, 1);
    setCurrentQuestions(updatedQuestions);
  };

  const handleConfirmReorderQuestions = () => {
    const updatedForm = { ...form };
    updatedForm.structure.questions = currentQuestions;
    setForm(updatedForm);
    setIsReorderingQuestions(false);
  };

  const handleCancelReorderQuestions = () => {
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
    setIsReorderingQuestions(false);
  };

  const handleAddBlankQuestion = (type) => {
    const defaultField = {
      title: "New Question",
      type: type,
      isRequired: false,
      ...(type === "checkboxes" || type === "multipleChoice" || type === "scale" || type === "dropdown" ? { choices: [] } : {}),
      ...(type === "fileUpload" ? { fileTypes: [] } : {}),
      ...(type === "scale" ? { minNumber: null, maxNumber: null } : {}),
      ...(type === "group" ? { questions: [] } : {}),
    };

    const updatedForm = { ...form };
    updatedForm.structure.questions = [...updatedForm.structure.questions, defaultField];
    setForm(updatedForm);
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
    // console.log("Current Questions: ", currentQuestions);
    // console.log("Form: ", form);
  };

  const handleAddTemplateQuestion = async (fieldID) => {
    const field = await getFieldById(fieldID);
    if (field.status >= 200 && field.status < 300) {
      const updatedForm = { ...form };
      updatedForm.structure.questions = [...updatedForm.structure.questions, field.data[0].structure];
      setForm(updatedForm);
      const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
      setCurrentQuestions(formQuestionsDeepCopy);
    } else {
      setViewFormModal(field.status);
      onHide();
      console.log("Error: ", field);
    }
  };

  const isSubQuestion = (index) => {
    return index.toString().includes(".");
  };

  const getIndices = (index) => {
    if (isSubQuestion(index)) {
      const [parentIndex, subIndex] = index.toString().split(".").map(Number);
      return { parentIndex, subIndex };
    }
    return { parentIndex: Number(index), subIndex: null };
  };

  const handleQuestionTitleChange = (e, index) => {
    const { parentIndex, subIndex } = getIndices(index);

    console.log("Parent Index: ", parentIndex, "Sub Index: ", subIndex);
    const updatedQuestions = [...currentQuestions];
    if (subIndex !== null) {
      updatedQuestions[parentIndex].questions[subIndex].title = e.target.value;
    } else {
      updatedQuestions[parentIndex].title = e.target.value;
    }
    setCurrentQuestions(updatedQuestions);
  };

  const handleQuestionEditTitleMode = (index) => {
    if (!editingQuestionTitle.includes(index)) {
      setEditingQuestionTitle([...editingQuestionTitle, index]);
    }
  };

  const handleConfirmQuestionTitleChange = (index) => {
    const { parentIndex, subIndex } = getIndices(index);
    const updatedForm = { ...form };
    if (subIndex !== null) {
      updatedForm.structure.questions[parentIndex].questions[subIndex].title = currentQuestions[parentIndex].questions[subIndex].title;
    } else {
      updatedForm.structure.questions[parentIndex].title = currentQuestions[parentIndex].title;
    }
    setForm(updatedForm);
    setEditingQuestionTitle(editingQuestionTitle.filter((item) => item !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(updatedForm.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleCancelQuestionTitleChange = (index) => {
    setEditingQuestionTitle(editingQuestionTitle.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleQuestionRequiredChangeMode = (index) => {
    if (!editingQuestionRequired.includes(index)) {
      setEditingQuestionRequired([...editingQuestionRequired, index]);
    }
  };

  const handleQuestionRequiredChange = (isRequired, index) => {
    const { parentIndex, subIndex } = getIndices(index);
    const updatedQuestions = [...currentQuestions];
    if (subIndex !== null) {
      updatedQuestions[parentIndex].questions[subIndex].isRequired = isRequired;
    } else {
      updatedQuestions[parentIndex].isRequired = isRequired;
    }
    setCurrentQuestions(updatedQuestions);
  };

  const handleConfirmQuestionRequiredChange = (index) => {
    const { parentIndex, subIndex } = getIndices(index);
    const updatedForm = { ...form };
    if (subIndex !== null) {
      updatedForm.structure.questions[parentIndex].questions[subIndex].isRequired = currentQuestions[parentIndex].questions[subIndex].isRequired;
    } else {
      updatedForm.structure.questions[parentIndex].isRequired = currentQuestions[parentIndex].isRequired;
    }
    setForm(updatedForm);
    setEditingQuestionRequired(editingQuestionRequired.filter((item) => item !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(updatedForm.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleCancelQuestionRequiredChange = (index) => {
    setEditingQuestionRequired(editingQuestionRequired.filter((item) => item !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleQuestionTypeEditMode = (index) => {
    setEditingQuestionType([...editingQuestionType, index]);
  };

  const handleQuestionTypeChange = (type, index) => {
    const { parentIndex, subIndex } = getIndices(index);
    const updatedQuestions = [...currentQuestions];

    if (subIndex !== null) {
      if (type === "group") {
        alert("Subquestions cannot be changed to group type.");
        return;
      }
      updatedQuestions[parentIndex].questions[subIndex].type = type;
    } else {
      updatedQuestions[parentIndex].type = type;
    }

    setCurrentQuestions(updatedQuestions);
  };

  const handleConfirmQuestionTypeChange = (index) => {
    const { parentIndex, subIndex } = getIndices(index);
    const updatedForm = { ...form };
    let updatedQuestion;

    if (subIndex !== null) {
      updatedQuestion = updatedForm.structure.questions[parentIndex].questions[subIndex];
      updatedQuestion.type = currentQuestions[parentIndex].questions[subIndex].type;
    } else {
      updatedQuestion = updatedForm.structure.questions[parentIndex];
      updatedQuestion.type = currentQuestions[parentIndex].type;
    }

    // Remove choices or fileTypes based on the new question type
    if (updatedQuestion.type === "paragraph" || updatedQuestion.type === "shortAnswer") {
      delete updatedQuestion.choices;
      delete updatedQuestion.fileTypes;
      setEditingQuestionChoices(editingQuestionChoices.filter((i) => i !== index));
      setEditingQuestionFileType(editingQuestionFileType.filter((i) => i !== index));
    } else if (updatedQuestion.type === "checkboxes" || updatedQuestion.type === "multipleChoice" || updatedQuestion.type === "dropdown") {
      updatedQuestion.choices = [];
      setEditingQuestionFileType(editingQuestionFileType.filter((i) => i !== index));
    } else if (updatedQuestion.type === "fileUpload") {
      updatedQuestion.fileTypes = [];
      setEditingQuestionChoices(editingQuestionChoices.filter((i) => i !== index));
    } else if (updatedQuestion.type === "group") {
      if (subIndex === null) {
        updatedQuestion.questions = [];
      } else {
        alert("Subquestions cannot be changed to group type.");
        return;
      }
    } else if (updatedQuestion.type === "scale") {
      updatedQuestion.minNumber = null;
      updatedQuestion.maxNumber = null;
      setEditingQuestionScaleRange(editingQuestionScaleRange.filter((i) => i !== index));
    }

    setForm(updatedForm);
    setEditingQuestionType(editingQuestionType.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(updatedForm.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleCancelQuestionTypeChange = (index) => {
    setEditingQuestionType(editingQuestionType.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleQuestionEditChoiceMode = (index) => {
    setEditingQuestionChoices([...editingQuestionChoices, index]);
  };

  const handleQuestionChoiceTextChange = (e, index, choiceIndex) => {
    const { parentIndex, subIndex } = getIndices(index);
    const updatedQuestions = [...currentQuestions];
    if (subIndex !== null) {
      updatedQuestions[parentIndex].questions[subIndex].choices[choiceIndex] = e.target.value;
    } else {
      updatedQuestions[parentIndex].choices[choiceIndex] = e.target.value;
    }
    setCurrentQuestions(updatedQuestions);
  };

  const handleQuestionChoiceMoveUp = (index, choiceIndex) => {
    const { parentIndex, subIndex } = getIndices(index);
    if (choiceIndex > 0) {
      const updatedQuestions = [...currentQuestions];
      let choices;
      if (subIndex !== null) {
        choices = updatedQuestions[parentIndex].questions[subIndex].choices;
      } else {
        choices = updatedQuestions[parentIndex].choices;
      }
      const temp = choices[choiceIndex - 1];
      choices[choiceIndex - 1] = choices[choiceIndex];
      choices[choiceIndex] = temp;
      setCurrentQuestions(updatedQuestions);
    }
  };

  const handleQuestionChoiceMoveDown = (index, choiceIndex) => {
    const { parentIndex, subIndex } = getIndices(index);
    const updatedQuestions = [...currentQuestions];
    let choices;
    if (subIndex !== null) {
      choices = updatedQuestions[parentIndex].questions[subIndex].choices;
    } else {
      choices = updatedQuestions[parentIndex].choices;
    }
    if (choiceIndex < choices.length - 1) {
      const temp = choices[choiceIndex + 1];
      choices[choiceIndex + 1] = choices[choiceIndex];
      choices[choiceIndex] = temp;
      setCurrentQuestions(updatedQuestions);
    }
  };

  const handleQuestionChoiceDelete = (index, choiceIndex) => {
    const { parentIndex, subIndex } = getIndices(index);
    const updatedQuestions = [...currentQuestions];
    if (subIndex !== null) {
      updatedQuestions[parentIndex].questions[subIndex].choices.splice(choiceIndex, 1);
    } else {
      updatedQuestions[parentIndex].choices.splice(choiceIndex, 1);
    }
    setCurrentQuestions(updatedQuestions);
  };

  const handleQuestionChoiceAdd = (index) => {
    const { parentIndex, subIndex } = getIndices(index);
    const updatedQuestions = [...currentQuestions];
    if (subIndex !== null) {
      updatedQuestions[parentIndex].questions[subIndex].choices.push("");
    } else {
      updatedQuestions[parentIndex].choices.push("");
    }
    setCurrentQuestions(updatedQuestions);
  };

  const handleConfirmQuestionEditChoice = (index) => {
    const { parentIndex, subIndex } = getIndices(index);
    const updatedForm = { ...form };
    if (subIndex !== null) {
      updatedForm.structure.questions[parentIndex].questions[subIndex].choices =
        currentQuestions[parentIndex].questions[subIndex].choices;
    } else {
      updatedForm.structure.questions[parentIndex].choices =
        currentQuestions[parentIndex].choices;
    }
    setForm(updatedForm);
    setEditingQuestionChoices(editingQuestionChoices.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(updatedForm.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleCancelQuestionEditChoice = (index) => {
    setEditingQuestionChoices(editingQuestionChoices.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleQuestionFileTypeEditMode = (index) => {
    setEditingQuestionFileType([...editingQuestionFileType, index]);
  };

  const handleQuestionFileTypeTextChange = (e, index, fileTypeIndex) => {
    const { parentIndex, subIndex } = getIndices(index);
    const updatedQuestions = [...currentQuestions];
    if (subIndex !== null) {
      updatedQuestions[parentIndex].questions[subIndex].fileTypes[fileTypeIndex] = e.target.value;
    } else {
      updatedQuestions[parentIndex].fileTypes[fileTypeIndex] = e.target.value;
    }
    setCurrentQuestions(updatedQuestions);
  };

  const handleQuestionFileTypeDelete = (index, fileTypeIndex) => {
    const { parentIndex, subIndex } = getIndices(index);
    const updatedQuestions = [...currentQuestions];
    if (subIndex !== null) {
      updatedQuestions[parentIndex].questions[subIndex].fileTypes.splice(fileTypeIndex, 1);
    } else {
      updatedQuestions[parentIndex].fileTypes.splice(fileTypeIndex, 1);
    }
    setCurrentQuestions(updatedQuestions);
  };

  const handleQuestionFileTypeAdd = (index) => {
    const { parentIndex, subIndex } = getIndices(index);
    const updatedQuestions = [...currentQuestions];
    if (subIndex !== null) {
      updatedQuestions[parentIndex].questions[subIndex].fileTypes.push("");
    } else {
      updatedQuestions[parentIndex].fileTypes.push("");
    }
    setCurrentQuestions(updatedQuestions);
  };

  const handleConfirmQuestionFileTypeEdit = (index) => {
    const { parentIndex, subIndex } = getIndices(index);
    let fileTypes;
    if (subIndex !== null) {
      fileTypes = currentQuestions[parentIndex].questions[subIndex].fileTypes;
    } else {
      fileTypes = currentQuestions[parentIndex].fileTypes;
    }

    const invalidFileTypes = fileTypes.filter(fileType => !isValidFileType(fileType));
    if (invalidFileTypes.length > 0) {
      alert(`Invalid file types: ${invalidFileTypes.join(", ")}`);
      return;
    }

    const updatedForm = { ...form };
    if (subIndex !== null) {
      updatedForm.structure.questions[parentIndex].questions[subIndex].fileTypes = fileTypes;
    } else {
      updatedForm.structure.questions[parentIndex].fileTypes = fileTypes;
    }
    setForm(updatedForm);
    setEditingQuestionFileType(editingQuestionFileType.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(updatedForm.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleCancelQuestionFileTypeEdit = (index) => {
    setEditingQuestionFileType(editingQuestionFileType.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleQuestionScaleRangeEditMode = (index) => {
    setEditingQuestionScaleRange([...editingQuestionScaleRange, index]);
  };

  const handleMinNumberChange = (event, index) => {
    const { parentIndex, subIndex } = getIndices(index);
    const updatedQuestions = [...currentQuestions];
    if (subIndex !== null) {
      updatedQuestions[parentIndex].questions[subIndex].minNumber = event;
    } else {
      updatedQuestions[parentIndex].minNumber = event;
    }
    setCurrentQuestions(updatedQuestions);
  };

  const handleMaxNumberChange = (event, index) => {
    const { parentIndex, subIndex } = getIndices(index);
    const updatedQuestions = [...currentQuestions];
    if (subIndex !== null) {
      updatedQuestions[parentIndex].questions[subIndex].maxNumber = event;
    } else {
      updatedQuestions[parentIndex].maxNumber = event;
    }
    setCurrentQuestions(updatedQuestions);
  };

  const handleConfirmQuestionScaleRangeEdit = (index) => {
    const { parentIndex, subIndex } = getIndices(index);
    const updatedForm = { ...form };
    if (subIndex !== null) {
      updatedForm.structure.questions[parentIndex].questions[subIndex].maxNumber = currentQuestions[parentIndex].questions[subIndex].maxNumber;
      updatedForm.structure.questions[parentIndex].questions[subIndex].minNumber = currentQuestions[parentIndex].questions[subIndex].minNumber;
    } else {
      updatedForm.structure.questions[parentIndex].maxNumber = currentQuestions[parentIndex].maxNumber;
      updatedForm.structure.questions[parentIndex].minNumber = currentQuestions[parentIndex].minNumber;
    }
    setForm(updatedForm);
    setEditingQuestionScaleRange(editingQuestionScaleRange.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleCancelQuestionScaleRangeEdit = (index) => {
    setEditingQuestionScaleRange(editingQuestionScaleRange.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleQuestionSubQuestionsEditMode = (index) => {
    setEditingQuestionSubQuestions([...editingQuestionSubQuestions, index]);
  };

  const handleQuestionSubQuestionsAddBlankQuestion = (type, questionNumber) => {
    const defaultField = {
      title: "New Question",
      type: type,
      isRequired: false,
      ...(type === "checkboxes" || type === "multipleChoice" || type === "scale" || type === "dropdown" ? { choices: [] } : {}),
      ...(type === "fileUpload" ? { fileTypes: [] } : {}),
      ...(type === "scale" ? { minNumber: null, maxNumber: null } : {}),
      ...(type === "group" ? { questions: [] } : {}),
    };

    const { parentIndex, subIndex } = getIndices(questionNumber);
    const updatedQuestions = [...currentQuestions];
    if (subIndex !== null) {
      updatedQuestions[parentIndex].questions[subIndex].questions.push(defaultField);
    } else {
      updatedQuestions[parentIndex].questions.push(defaultField);
    }
    setCurrentQuestions(updatedQuestions);
  };

  const handleQuestionSubQuestionsAddTemplateQuestion = async (fieldID, questionNumber) => {
    const field = await getFieldById(fieldID);
    if (field.status >= 200 && field.status < 300) {
      const { parentIndex, subIndex } = getIndices(questionNumber);
      const updatedQuestions = [...currentQuestions];
      if (subIndex !== null) {
        updatedQuestions[parentIndex].questions[subIndex].questions.push(field.data[0].structure);
      } else {
        updatedQuestions[parentIndex].questions.push(field.data[0].structure);
      }
      setCurrentQuestions(updatedQuestions);
    } else {
      setViewFormModal(field.status);
      onHide();
      console.log("Error: ", field);
    }
  };

  const handleMoveUpQuestionSubQuestion = (questionNumber, subIndex) => {
    const { parentIndex } = getIndices(questionNumber);
    const updatedQuestions = [...currentQuestions];
    const subQuestion = updatedQuestions[parentIndex].questions[subIndex];
    updatedQuestions[parentIndex].questions.splice(subIndex, 1);
    updatedQuestions[parentIndex].questions.splice(subIndex - 1, 0, subQuestion);
    setCurrentQuestions(updatedQuestions);
  };

  const handleMoveDownQuestionSubQuestion = (questionNumber, subIndex) => {
    const { parentIndex } = getIndices(questionNumber);
    const updatedQuestions = [...currentQuestions];
    const subQuestion = updatedQuestions[parentIndex].questions[subIndex];
    updatedQuestions[parentIndex].questions.splice(subIndex, 1);
    updatedQuestions[parentIndex].questions.splice(subIndex + 1, 0, subQuestion);
    setCurrentQuestions(updatedQuestions);
  };

  const handleDeleteQuestionSubQuestion = (questionNumber, subIndex) => {
    const { parentIndex } = getIndices(questionNumber);
    const updatedQuestions = [...currentQuestions];
    updatedQuestions[parentIndex].questions.splice(subIndex, 1);
    setCurrentQuestions(updatedQuestions);
  };

  const handleConfirmQuestionSubQuestionsEdit = (index) => {
    const { parentIndex, subIndex } = getIndices(index);
    const updatedForm = { ...form };
    if (subIndex !== null) {
      updatedForm.structure.questions[parentIndex].questions[subIndex].questions = currentQuestions[parentIndex].questions[subIndex].questions;
    } else {
      updatedForm.structure.questions[parentIndex].questions = currentQuestions[parentIndex].questions;
    }
    setForm(updatedForm);
    setEditingQuestionSubQuestions(editingQuestionSubQuestions.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(updatedForm.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleCancelQuestionSubQuestionsEdit = (index) => {
    setEditingQuestionSubQuestions(editingQuestionSubQuestions.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const isValidFileType = (fileType) => {
    const validExtensions = [
      ".pdf", ".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx", ".txt", ".csv", ".json", ".xml",
      ".zip", ".rar", ".7z", ".gz", ".tar", ".tar.gz", ".tgz", ".png", ".jpg", ".jpeg", ".gif",
      ".bmp", ".webp", ".svg", ".mp3", ".mp4", ".avi", ".mov", ".mkv", ".tif", ".tiff", ".webm",
    ];
    return validExtensions.includes(fileType.toLowerCase());
  };

  const resetState = () => {
    setExpandedQuestions([]);
    setIsReorderingQuestions(false);
    setForm(null);
    setProfile(null);
    setIsLoading(false);
    setVersions([]);
    setCurrentVersion(null);
    setNewVersionForSave(null);
  };

  const handleAddForm = async () => {
    if (!newVersionForSave) {
      alert("Please enter a form version");
    }
    if (newVersionForSave.includes(" ")) {
      alert("Error: Form version cannot have spaces in the middle");
      return;
    }
    form.name = form.name + " Copy";
    const response = await addForm(form, isTemplate, newVersionForSave);
    switch (response.status) {
      case 200:
        alert("Form added successfully!");
        setViewFormModal(200);
        resetState();
        onHide();
        break;
      case 400:
        alert("Error: Bad Request, Details:" + response.data.message);
        // setViewFormModal(400);
        break;
      case 401:
        alert("Error: Unauthorized, Details:" + response.data.message);
        setViewFormModal(401);
        resetState();
        onHide();
        break;
      case 403:
        alert("Error: Forbidden, Details:" + response.data.message);
        setViewFormModal(403);
        resetState();
        onHide();
        break;
      case 500:
        alert("Error: Internal Server Error, Details:" + response.data.message);
        setViewFormModal(500);
        resetState();
        onHide();
        break;
      default:
        alert("Error: " + response.status + ", Details:" + response.data.message);
        setViewFormModal(response.status);
        resetState();
        onHide();
        break;
    }
  };

  const handleUpdateForm = async () => {
    const response = await updateForm(formID, form, currentVersion);
    switch (response.status) {
      case 200:
        alert("Form updated successfully!");
        setViewFormModal(200);
        resetState();
        onHide();
        break;
      case 400:
        alert("Error: Bad Request, Details:" + response.data.message);
        // setViewFormModal(400);
        break;
      case 401:
        alert("Error: Unauthorized, Details:" + response.data.message);
        setViewFormModal(401);
        resetState();
        onHide();
        break;
      case 403:
        alert("Error: Forbidden, Details:" + response.data.message);
        setViewFormModal(403);
        resetState();
        onHide();
        break;
      case 500:
        alert("Error: Internal Server Error, Details:" + response.data.message);
        setViewFormModal(500);
        resetState();
        onHide();
        break;
      default:
        alert("Error: " + response.status + ", Details:" + response.data.message);
        setViewFormModal(response.status);
        resetState();
        onHide();
        break;
    }
  };

  const handleUpdateFormNewVersion = async () => {
    const response = await addForm(form, isTemplate, newVersionForSave, formID);
    switch (response.status) {
      case 200:
        alert("Form updated successfully!");
        setViewFormModal(200);
        resetState();
        onHide();
        break;
      case 400:
        alert("Error: Bad Request, Details:" + response.data.message);
        // setViewFormModal(400);
        break;
      case 401:
        alert("Error: Unauthorized, Details:" + response.data.message);
        setViewFormModal(401);
        resetState();
        onHide();
        break;
      case 403:
        alert("Error: Forbidden, Details:" + response.data.message);
        setViewFormModal(403);
        resetState();
        onHide();
        break;
      case 500:
        alert("Error: Internal Server Error, Details:" + response.data.message);
        setViewFormModal(500);
        resetState();
        onHide();
        break;
      default:
        alert("Error: " + response.status + ", Details:" + response.data.message);
        setViewFormModal(response.status);
        resetState();
        onHide();
        break;
    }
  };

  const getCurrentQuestionTitle = (questionNumber) => {
    const { parentIndex, subIndex } = getIndices(questionNumber);
    if (subIndex !== null) {
      // console.log(currentQuestions[parentIndex].questions[subIndex].title);
      return currentQuestions[parentIndex].questions[subIndex].title;
    } else {
      // console.log(currentQuestions[parentIndex].title);
      return currentQuestions[parentIndex].title;
    }
  };

  const renderQuestions = (question, index, parentIndex = null) => {
    const questionNumber = parentIndex !== null
      ? `${parentIndex}.${index}`
      : `${index}`;

    return (
      <div key={questionNumber} className="questions-container">
        <div className="question-header" role="button" tabIndex="0"
          onClick={() => {
            if (!isReorderingQuestions) {
              setExpandedQuestions((prevState) =>
                prevState.includes(questionNumber) ? prevState.filter((i) => i !== questionNumber) : [...prevState, questionNumber]
              );
            }
          }}
          onKeyDown={(event) => {
            if (!isReorderingQuestions && event.key === "Enter") {
              setExpandedQuestions((prevState) =>
                prevState.includes(questionNumber) ? prevState.filter((i) => i !== questionNumber) : [...prevState, questionNumber]
              );
            }
          }}>
          <strong style={{ fontSize: isMobile ? "14px" : "18px" }}>
            Question {(questionNumber.split(".").length > 1 ? `${Number(questionNumber.split(".").map(Number)[0]) + 1}.${questionNumber.split(".").map(Number)[1] + 1}` : Number(questionNumber.split(".").map(Number)[0]) + 1)}:
          </strong>{" "}
          <span style={{ fontSize: isMobile ? "14px" : "18px", maxWidth: "100%", paddingLeft: "10px", textWrap: "nowrap", overflowX: "auto" }}>
            {question.title}
          </span>
          <div>
            {isReorderingQuestions ? (
              <div>
                <ThemeButton variant="outline-primary" style={{
                  marginLeft: "10px", padding: "2px 8px", fontSize: isMobile ? "12px" : "14px",
                }} onClick={() => handleMoveUpQuestion(questionNumber)} disabled={questionNumber === 0}>
                  <FaAngleUp />
                </ThemeButton>
                <ThemeButton variant="outline-primary" style={{
                  marginLeft: "10px", padding: "2px 8px", fontSize: isMobile ? "12px" : "14px",
                }} onClick={() => handleMoveDownQuestion(questionNumber)} disabled={questionNumber === form.structure.questions.length - 1}>
                  <FaAngleDown />
                </ThemeButton>
                <ThemeButton
                  variant="outline-danger" style={{
                    marginLeft: "10px",
                    padding: "2px 8px",
                    fontSize: isMobile ? "12px" : "14px",
                  }} onClick={() => handleQuestionDelete(questionNumber)}>
                  <FaTrash />
                </ThemeButton>
              </div>
            ) : (
              <FaAngleDown className={`expanded-icon ${expandedQuestions.includes(questionNumber) ? "expanded" : ""}`} />
            )}
          </div>
        </div>
        {expandedQuestions.includes(questionNumber) && (
          <>
            <div className="field-attribute-container-in-form">
              <strong style={{ fontSize: isMobile ? "12px" : "16px", textWrap: "nowrap" }}>Question Title:</strong>{" "}
              {editingQuestionTitle.includes(questionNumber) ? (
                <Form.Control
                  type="text"
                  value={getCurrentQuestionTitle(questionNumber)}
                  onChange={(event) => handleQuestionTitleChange(event, questionNumber)}
                />
              ) : (
                <span style={{ fontSize: isMobile ? "12px" : "14px", maxWidth: "100%", paddingLeft: "10px", textWrap: "nowrap", overflowX: "auto" }} title={question.title}>
                  {question.title}
                </span>
              )}
              {editingQuestionTitle.includes(questionNumber) ? (
                <>
                  <Button variant="outline-success"
                    style={{
                      marginLeft: "10px",
                      padding: "2px 8px",
                      fontSize: "14px",
                    }} onClick={() => handleConfirmQuestionTitleChange(questionNumber)}>
                    <FaCheck />
                  </Button>
                  <Button variant="outline-danger"
                    style={{
                      marginLeft: "5px",
                      padding: "2px 8px",
                      fontSize: "14px",
                    }} onClick={() => handleCancelQuestionTitleChange(questionNumber)}>
                    <FaXmark />
                  </Button>
                </>
              ) : (
                <ThemeButton variant="outline-primary" style={{
                  marginLeft: "10px",
                  padding: "2px 8px",
                  fontSize: isMobile ? "12px" : "14px",
                }} onClick={() => handleQuestionEditTitleMode(questionNumber)}>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <FaPen /> {isMobile ? "" : "Edit"}
                  </div>
                </ThemeButton>
              )}
            </div>
            <div className="field-attribute-container-in-form">
              <strong style={{ fontSize: isMobile ? "12px" : "16px" }}>Question Type:</strong>{" "}
              {editingQuestionType.includes(questionNumber) ? (
                <>
                  <Dropdown >
                    <Dropdown.Toggle
                      variant="outline-primary"
                      id="dropdown-basic"
                      style={{
                        "--bs-btn-color": "#50011a",
                        "--bs-btn-border-color": "#50011a",
                        "--bs-btn-hover-color": "#fff",
                        "--bs-btn-hover-bg": "#50011a",
                        "--bs-btn-hover-border-color": "#50011a",
                        "--bs-btn-focus-shadow-rgb": "33,37,41",
                        "--bs-btn-active-color": "#fff",
                        "--bs-btn-active-bg": "#50011a",
                        "--bs-btn-active-border-color": "#50011a",
                        marginLeft: "10px",
                        padding: "2px 8px",
                        fontSize: isMobile ? "12px" : "14px",
                      }}>
                      {getQuestionTypeText(question.type)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleQuestionTypeChange("shortAnswer", questionNumber)}>Short Answer</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleQuestionTypeChange("paragraph", questionNumber)}>Paragraph</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleQuestionTypeChange("checkboxes", questionNumber)}>Checkboxes</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleQuestionTypeChange("multipleChoice", questionNumber)}>Multiple Choice</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleQuestionTypeChange("dropdown", questionNumber)}>Dropdown</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleQuestionTypeChange("date", questionNumber)}>Date</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleQuestionTypeChange("time", questionNumber)}>Time</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleQuestionTypeChange("fileUpload", questionNumber)}>File Upload</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleQuestionTypeChange("scale", questionNumber)}>Scale</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleQuestionTypeChange("numeric", questionNumber)}>Numeric</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleQuestionTypeChange("group", questionNumber)}>Group</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button variant="outline-success" style={{
                    marginLeft: "10px",
                    padding: "2px 8px",
                    fontSize: "14px",
                  }} onClick={() => handleConfirmQuestionTypeChange(questionNumber)}>
                    <FaCheck />
                  </Button>
                  <Button variant="outline-danger" style={{
                    marginLeft: "5px",
                    padding: "2px 8px",
                    fontSize: "14px",
                  }} onClick={() => handleCancelQuestionTypeChange(questionNumber)}>
                    <FaXmark />
                  </Button>
                </>
              ) : (
                <>
                  <span style={{ fontSize: isMobile ? "12px" : "14px", paddingLeft: "10px" }} title={getQuestionTypeText(question.type)}>
                    {getQuestionTypeText(question.type)}
                  </span>
                  <ThemeButton variant="outline-primary" style={{
                    marginLeft: "10px",
                    padding: "2px 8px",
                    fontSize: isMobile ? "12px" : "14px",
                  }} onClick={() => handleQuestionTypeEditMode(questionNumber)}>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <FaPen />{isMobile ? "" : "Edit"}
                    </div>
                  </ThemeButton>
                </>
              )}
            </div>
            <div className="field-attribute-container-in-form">
              <strong style={{ fontSize: isMobile ? "12px" : "16px" }}>Question Required:</strong>{" "}
              {editingQuestionRequired.includes(questionNumber) ? (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-primary"
                    id="dropdown-basic"
                    style={{
                      "--bs-btn-color": "#50011a",
                      "--bs-btn-border-color": "#50011a",
                      "--bs-btn-hover-color": "#fff",
                      "--bs-btn-hover-bg": "#50011a",
                      "--bs-btn-hover-border-color": "#50011a",
                      "--bs-btn-focus-shadow-rgb": "33,37,41",
                      "--bs-btn-active-color": "#fff",
                      "--bs-btn-active-bg": "#50011a",
                      "--bs-btn-active-border-color": "#50011a",
                      marginLeft: "10px",
                      padding: "2px 8px",
                      fontSize: "14px",
                    }}>
                    {question.isRequired ? "Yes" : "No"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleQuestionRequiredChange(true, questionNumber)}>
                      Yes
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleQuestionRequiredChange(false, questionNumber)}>
                      No
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <span style={{ fontSize: isMobile ? "12px" : "14px", paddingLeft: "10px" }}>
                  {question.isRequired ? "Yes" : "No"}
                </span>
              )}
              {editingQuestionRequired.includes(questionNumber) ? (
                <>
                  <Button variant="outline-success"
                    style={{
                      marginLeft: "10px",
                      padding: "2px 8px",
                      fontSize: "14px",
                    }} onClick={() => handleConfirmQuestionRequiredChange(questionNumber)}>
                    <FaCheck />
                  </Button>
                  <Button variant="outline-danger"
                    style={{
                      marginLeft: "5px",
                      padding: "2px 8px",
                      fontSize: "14px",
                    }} onClick={() => handleCancelQuestionRequiredChange(questionNumber)}>
                    <FaXmark />
                  </Button>
                </>
              ) : (
                <ThemeButton variant="outline-primary" style={{
                  marginLeft: "10px",
                  padding: "2px 8px",
                  fontSize: isMobile ? "12px" : "14px",
                }} onClick={() => handleQuestionRequiredChangeMode(questionNumber)}>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <FaPen /> {isMobile ? "" : "Edit"}
                  </div>
                </ThemeButton>
              )}

            </div>
            {(question.type === "checkboxes" || question.type === "multipleChoice" || question.type === "dropdown")
              && !editingQuestionType.includes(questionNumber) &&
              (
                <div className="field-attribute-container-in-form" style={{ display: "block" }}>
                  <strong style={{ fontSize: isMobile ? "12px" : "16px", marginBottom: "0" }}>Choices:</strong>
                  {editingQuestionChoices.includes(questionNumber) ? (
                    <>
                      {question.choices.map((choice, choiceIndex) => (
                        <div key={choiceIndex} className="field-list-item-container">
                          <input type="text" value={choice} onChange={
                            (e) => handleQuestionChoiceTextChange(e, questionNumber, choiceIndex)}
                          />
                          <ThemeButton variant="outline-primary" style={{
                            marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                          }} onClick={() => handleQuestionChoiceDelete(questionNumber, choiceIndex)}>
                            <FaTrash />
                          </ThemeButton>
                          <ThemeButton variant="outline-primary" style={{
                            marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                          }} disabled={choiceIndex === 0} onClick={() => handleQuestionChoiceMoveUp(questionNumber, choiceIndex)}>
                            <FaAngleUp />
                          </ThemeButton>
                          <ThemeButton variant="outline-primary" style={{
                            marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                          }} disabled={choiceIndex === question.choices.length - 1} onClick={() => handleQuestionChoiceMoveDown(questionNumber, choiceIndex)}>
                            <FaAngleDown />
                          </ThemeButton>
                        </div>
                      ))}
                      <ThemeButton variant="outline-primary" style={{
                        marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                      }} onClick={() => handleQuestionChoiceAdd(questionNumber)}>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          <FaPlus /> Add Choice
                        </div>
                      </ThemeButton>
                      <ThemeButton variant="outline-primary" style={{
                        marginLeft: "10px",
                        padding: "2px 8px",
                        fontSize: "14px"
                      }} onClick={() => handleConfirmQuestionEditChoice(questionNumber)}>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          <FaCheck /> Confirm
                        </div>
                      </ThemeButton>
                      <ThemeButton variant="outline-primary" style={{
                        marginLeft: "10px",
                        padding: "2px 8px",
                        fontSize: "14px"
                      }} onClick={() => handleCancelQuestionEditChoice(questionNumber)}>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          <FaXmark /> Cancel
                        </div>
                      </ThemeButton>
                    </>
                  ) : (
                    <>
                      <ThemeButton variant="outline-primary" style={{
                        marginLeft: "10px",
                        padding: "2px 8px",
                        fontSize: isMobile ? "12px" : "14px",
                      }} onClick={() => handleQuestionEditChoiceMode(questionNumber)}>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          <FaPen /> {isMobile ? "" : "Edit"}
                        </div>
                      </ThemeButton>
                      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                        {(
                          question.choices.map((choice, index) => (
                            <div key={index} className="field-list-item-container">
                              {" > "}
                              <span style={{ fontSize: isMobile ? "10px" : "" }}>
                                {choice}
                              </span>
                            </div>
                          ))
                        )}
                      </ul>
                    </>
                  )}
                </div>
              )}
            {question.type === "fileUpload" && !editingQuestionType.includes(questionNumber) && (
              <div className="field-attribute-container-in-form" style={{ display: "block" }}>
                <strong style={{ fontSize: isMobile ? "12px" : "16px", marginBottom: "0" }}>File Types Allowed:</strong>
                {editingQuestionFileType.includes(questionNumber) ? (
                  <>
                    {question.fileTypes.map((fileType, fileTypeIndex) => (
                      <div key={fileTypeIndex} className="field-list-item-container">
                        <input type="text" value={fileType}
                          onChange={(e) => handleQuestionFileTypeTextChange(e, questionNumber, fileTypeIndex)}
                          style={{ width: "100px", fontSize: isMobile ? "12px" : "" }}
                        />
                        <ThemeButton
                          variant="outline-primary" style={{
                            marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                          }} onClick={() => handleQuestionFileTypeDelete(questionNumber, fileTypeIndex)}>
                          <FaTrash />
                        </ThemeButton>
                      </div>
                    ))}
                    <ThemeButton variant="outline-primary" style={{
                      marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                    }} onClick={() => handleQuestionFileTypeAdd(questionNumber)}>
                      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <FaPlus /> Add File Type
                      </div>
                    </ThemeButton>
                    <ThemeButton variant="outline-primary" style={{
                      marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                    }} onClick={() => handleConfirmQuestionFileTypeEdit(questionNumber)}>
                      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <FaCheck /> Confirm
                      </div>
                    </ThemeButton>
                    <ThemeButton variant="outline-primary" style={{
                      marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                    }} onClick={() => handleCancelQuestionFileTypeEdit(questionNumber)}>
                      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <FaXmark /> Cancel
                      </div>
                    </ThemeButton>
                    <OverlayTrigger placement="bottom"
                      overlay={<Tooltip id="button-tooltip-2">Supported File Types: .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .csv, .json, .xml,
                        .zip, .rar, .7z, .gz, .tar, .tar.gz, .tgz, .png, .jpg, .jpeg, .gif,
                        .bmp, .webp, .svg, .mp3, .mp4, .avi, .mov, .mkv, .tif, .tiff, .webm</Tooltip>}>
                      {({ ...triggerHandler }) => (
                        <Button style={{ backgroundColor: "transparent", border: "none" }}{...triggerHandler} className="d-inline-flex align-items-center">
                          <FaCircleInfo style={{ color: "#50011a" }} />
                        </Button>
                      )}
                    </OverlayTrigger>
                  </>
                ) : (
                  <>
                    <ThemeButton variant="outline-primary" style={{
                      marginLeft: "10px", padding: "2px 8px", fontSize: isMobile ? "12px" : "14px",
                    }} onClick={() => handleQuestionFileTypeEditMode(questionNumber)}>
                      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <FaPen /> {isMobile ? "" : "Edit"}
                      </div>
                    </ThemeButton>
                    <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                      {question.fileTypes.map((fileType, index) => (
                        <div key={index} className="field-list-item-container">
                          {" > "}
                          <span style={{ fontSize: isMobile ? "10px" : "" }}>
                            {fileType}
                          </span>
                        </div>
                      ))}
                    </ul>
                  </>
                )}

              </div>
            )}
            {question.type === "scale" && !editingQuestionType.includes(questionNumber) && (
              <div className="field-attribute-container-in-form" style={{ display: "block" }}>
                <strong style={{ fontSize: isMobile ? "12px" : "16px", marginBottom: "0" }}>Range: </strong>
                {editingQuestionScaleRange.includes(questionNumber) ? (
                  <>
                    <ThemeButton variant="outline-primary" style={{
                      marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                    }} onClick={() => handleConfirmQuestionScaleRangeEdit(questionNumber)}>
                      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <FaCheck /> Confirm
                      </div>
                    </ThemeButton>
                    <ThemeButton variant="outline-primary" style={{
                      marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                    }} onClick={() => handleCancelQuestionScaleRangeEdit(questionNumber)}>
                      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <FaXmark /> Cancel
                      </div>
                    </ThemeButton>
                    <Form.Group className="mb-3">
                      <Form.Label style={{ fontSize: isMobile ? "12px" : "" }}>Minimum:</Form.Label>
                      <Form.Control
                        type="number"
                        value={question.minNumber}
                        onChange={(e) => handleMinNumberChange(parseFloat(e.target.value), questionNumber)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label style={{ fontSize: isMobile ? "12px" : "" }}>Maximum:</Form.Label>
                      <Form.Control
                        type="number"
                        value={question.maxNumber}
                        onChange={(e) => handleMaxNumberChange(parseFloat(e.target.value), questionNumber)}
                      />
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <ThemeButton variant="outline-primary" style={{
                      marginLeft: "10px", padding: "2px 8px", fontSize: isMobile ? "12px" : "14px",
                    }} onClick={() => handleQuestionScaleRangeEditMode(questionNumber)}>
                      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <FaPen />{isMobile ? "" : "Edit"}
                      </div>
                    </ThemeButton>
                    <div className="field-attribute-container">
                      <span style={{ fontSize: isMobile ? "10px" : "" }}><strong>Minimum:</strong> {question.minNumber}</span>
                    </div>
                    <div className="field-attribute-container">
                      <span style={{ fontSize: isMobile ? "10px" : "" }}><strong>Maximum:</strong> {question.maxNumber}</span>
                    </div>
                  </>
                )}
              </div>
            )}
            {question.type === "group" && !editingQuestionType.includes(questionNumber) && (
              <div className="field-attribute-container-in-form" style={{ display: "block" }}>
                <strong style={{ fontSize: isMobile ? "12px" : "16px", marginBottom: "0" }}>Sub-Questions:</strong>
                {editingQuestionSubQuestions.includes(questionNumber) ? (
                  <>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px", flexDirection: isMobile ? "column" : "row" }}>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="outline-primary"
                          id="dropdown-basic"
                          style={{
                            "--bs-btn-color": "#50011a",
                            "--bs-btn-border-color": "#50011a",
                            "--bs-btn-hover-color": "#fff",
                            "--bs-btn-hover-bg": "#50011a",
                            "--bs-btn-hover-border-color": "#50011a",
                            "--bs-btn-focus-shadow-rgb": "33,37,41",
                            "--bs-btn-active-color": "#fff",
                            "--bs-btn-active-bg": "#50011a",
                            "--bs-btn-active-border-color": "#50011a",
                            marginLeft: "10px",
                            padding: "2px 8px",
                            fontSize: isMobile ? "12px" : "14px"
                          }}>
                          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <FaPlus />
                            Add Question
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ fontSize: isMobile ? "12px" : "" }}>
                          <Dropdown.Item onClick={() => { handleQuestionSubQuestionsAddBlankQuestion("shortAnswer", questionNumber); }}>Short Answer</Dropdown.Item>
                          <Dropdown.Item onClick={() => { handleQuestionSubQuestionsAddBlankQuestion("paragraph", questionNumber); }}>Paragraph</Dropdown.Item>
                          <Dropdown.Item onClick={() => { handleQuestionSubQuestionsAddBlankQuestion("checkboxes", questionNumber); }}>Checkboxes</Dropdown.Item>
                          <Dropdown.Item onClick={() => { handleQuestionSubQuestionsAddBlankQuestion("multipleChoice", questionNumber); }}>Multiple Choice</Dropdown.Item>
                          <Dropdown.Item onClick={() => { handleQuestionSubQuestionsAddBlankQuestion("dropdown", questionNumber); }}>Dropdown</Dropdown.Item>
                          <Dropdown.Item onClick={() => { handleQuestionSubQuestionsAddBlankQuestion("date", questionNumber); }}>Date</Dropdown.Item>
                          <Dropdown.Item onClick={() => { handleQuestionSubQuestionsAddBlankQuestion("time", questionNumber); }}>Time</Dropdown.Item>
                          <Dropdown.Item onClick={() => { handleQuestionSubQuestionsAddBlankQuestion("fileUpload", questionNumber); }}>File Upload</Dropdown.Item>
                          <Dropdown.Item onClick={() => { handleQuestionSubQuestionsAddBlankQuestion("scale", questionNumber); }}>Scale</Dropdown.Item>
                          <Dropdown.Item onClick={() => { handleQuestionSubQuestionsAddBlankQuestion("numeric", questionNumber); }}>Numeric</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="outline-primary"
                          id="dropdown-basic"
                          style={{
                            "--bs-btn-color": "#50011a",
                            "--bs-btn-border-color": "#50011a",
                            "--bs-btn-hover-color": "#fff",
                            "--bs-btn-hover-bg": "#50011a",
                            "--bs-btn-hover-border-color": "#50011a",
                            "--bs-btn-focus-shadow-rgb": "33,37,41",
                            "--bs-btn-active-color": "#fff",
                            "--bs-btn-active-bg": "#50011a",
                            "--bs-btn-active-border-color": "#50011a",
                            marginLeft: "10px",
                            padding: "2px 8px",
                            fontSize: isMobile ? "12px" : "14px"
                          }}>
                          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <FaPlus />
                            Add a Preset Question
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ fontSize: isMobile ? "12px" : "" }}>
                          {templateFields.map((field) => (
                            <Dropdown.Item key={field.id} onClick={() => { handleQuestionSubQuestionsAddTemplateQuestion(field.id, questionNumber); }}>
                              {field.title}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                      <ThemeButton variant="outline-primary" style={{
                        marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                      }} onClick={() => handleConfirmQuestionSubQuestionsEdit(questionNumber)}>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          <FaCheck /> Confirm
                        </div>
                      </ThemeButton>
                      <ThemeButton variant="outline-primary" style={{
                        marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                      }} onClick={() => handleCancelQuestionSubQuestionsEdit(questionNumber)}>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          <FaXmark /> Cancel
                        </div>
                      </ThemeButton>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      {question.questions.map((subQuestion, subIndex) => (
                        <div key={subIndex} className="questions-container" style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                          <strong style={{ fontSize: isMobile ? "14px" : "18px" }}>
                            Question {Number(questionNumber) + 1}.{subIndex + 1}:
                          </strong>{" "}
                          <div key={subIndex} className="field-attribute-container-in-form">
                            {subQuestion.title}
                          </div>
                          <div style={{ marginLeft: "10px" }}>
                            <ThemeButton variant="outline-primary" style={{ padding: "2px 8px", fontSize: "14px" }} onClick={() => handleMoveUpQuestionSubQuestion(questionNumber, subIndex)} disabled={subIndex === 0}>
                              <FaAngleUp />
                            </ThemeButton>
                            <ThemeButton variant="outline-primary" style={{ padding: "2px 8px", fontSize: "14px" }} onClick={() => handleMoveDownQuestionSubQuestion(questionNumber, subIndex)} disabled={subIndex === question.questions.length - 1}>
                              <FaAngleDown />
                            </ThemeButton>
                            <ThemeButton variant="outline-primary" style={{ padding: "2px 8px", fontSize: "14px" }} onClick={() => handleDeleteQuestionSubQuestion(questionNumber, subIndex)}>
                              <FaTrash />
                            </ThemeButton>
                          </div>
                        </div>

                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    <ThemeButton variant="outline-primary" style={{
                      marginLeft: "10px", padding: "2px 8px", fontSize: isMobile ? "12px" : "14px",
                    }} onClick={() => handleQuestionSubQuestionsEditMode(questionNumber)}>
                      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <FaPen />{isMobile ? "" : "Edit"}
                      </div>
                    </ThemeButton>
                    <div>
                      {question.questions.map((subQuestion, subIndex) => renderQuestions(subQuestion, subIndex, index))}
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <Modal show={show} onHide={() => {
      resetState();
      onHide();
    }} centered dialogClassName="modal-dialog-centered" size="lg">
      <Modal.Header closeButton style={{ gap: "10px" }}>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Form
        </Modal.Title>
        {versions.length > 0 && (
          <div className="d-flex align-items-center">
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-secondary"
                size="sm"
                style={{
                  "--bs-btn-color": "#50011a",
                  "--bs-btn-border-color": "#50011a",
                  "--bs-btn-hover-color": "#fff",
                  "--bs-btn-hover-bg": "#50011a",
                  "--bs-btn-hover-border-color": "#50011a",
                  "--bs-btn-active-color": "#fff",
                  "--bs-btn-active-bg": "#50011a",
                  "--bs-btn-active-border-color": "#50011a",
                  "--bs-btn-active-shadow": "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
                  "--bs-btn-disabled-color": "#50011a",
                  "--bs-btn-disabled-bg": "transparent",
                  "--bs-btn-disabled-border-color": "#50011a",
                  fontSize: isMobile ? "12px" : "18px"
                }}
              >
                Version: {currentVersion || "Select Version"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {versions.map((version) => (
                  <Dropdown.Item
                    key={version.version_id}
                    active={currentVersion === version.version_id}
                    onClick={() => handleVersionChange(version.version_id)}
                    style={{ "--bs-dropdown-link-active-bg": "#50011a", }}
                  >
                    {version.version_id}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "650px", overflowY: "auto", maxWidth: "800px", overflowX: "auto" }}>
        {isLoading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : form ? (
          <div>
            <div className="form-attribute-container">
              {editFormName ? (
                <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                  <strong style={{ fontSize: isMobile ? "14px" : "20px", textWrap: "nowrap" }}>From Name:</strong>{" "}
                  <Form.Control style={{ fontSize: isMobile ? "14px" : "" }} type="text" value={currentFormName} onChange={handleFormNameChange} />
                  <>
                    <Button variant="outline-success"
                      style={{
                        marginLeft: "10px",
                        padding: "2px 8px",
                        fontSize: "14px",
                      }} onClick={handleSaveFormName}>
                      <FaCheck />
                    </Button>
                    <Button variant="outline-danger"
                      style={{
                        marginLeft: "5px",
                        padding: "2px 8px",
                        fontSize: "14px",
                      }} onClick={handleCancelFormNameChange}>
                      <FaXmark />
                    </Button>
                  </>
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                  <strong style={{ fontSize: isMobile ? "14px" : "20px", textWrap: "nowrap" }}>From Name:</strong>{" "}
                  <span style={{ fontSize: isMobile ? "14px" : "18px", maxWidth: "100%", paddingLeft: "10px", textWrap: "nowrap", overflowX: "auto" }}>
                    {form.name}
                  </span>
                  <ThemeButton variant="outline-primary" style={{
                    marginLeft: "10px",
                    padding: "2px 8px",
                    fontSize: isMobile ? "12px" : "14px",
                  }} onClick={handleEditFormNameMode}>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <FaPen />{isMobile ? null : "Edit"}
                    </div>
                  </ThemeButton>
                </div>
              )}
            </div>
            <div className="form-attribute-container">
              {editFormTitle ? (
                <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                  <strong style={{ fontSize: isMobile ? "14px" : "20px", textWrap: "nowrap" }}>Title:</strong>{" "}
                  <Form.Control style={{ fontSize: isMobile ? "14px" : "" }} type="text" value={currentFormTitle} onChange={handleFormTitleChange} />
                  <>
                    <Button variant="outline-success"
                      style={{
                        marginLeft: "10px",
                        padding: "2px 8px",
                        fontSize: "14px",
                      }} onClick={handleSaveFormTitle}>
                      <FaCheck />
                    </Button>
                    <Button variant="outline-danger"
                      style={{
                        marginLeft: "5px",
                        padding: "2px 8px",
                        fontSize: "14px",
                      }} onClick={handleCancelFormTitleChange}>
                      <FaXmark />
                    </Button>
                  </>
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                  <strong style={{ fontSize: isMobile ? "14px" : "20px" }}>Title:</strong>{" "}
                  <span style={{ fontSize: isMobile ? "14px" : "18px", maxWidth: "100%", paddingLeft: "10px", textWrap: "nowrap", overflowX: "auto" }}>
                    {form.structure.title}
                  </span>
                  <ThemeButton variant="outline-primary" style={{
                    marginLeft: "10px",
                    padding: "2px 8px",
                    fontSize: isMobile ? "12px" : "14px",
                  }} onClick={handleEditFormTitleMode}>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <FaPen /> {isMobile ? null : "Edit"}
                    </div>
                  </ThemeButton>
                </div>
              )}
            </div>
            <div className="form-attribute-container">
              {editFormDescription ? (
                <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                  <strong style={{ fontSize: isMobile ? "14px" : "20px" }}>Description:</strong>{" "}
                  <Form.Control style={{ fontSize: isMobile ? "14px" : "" }} type="text" value={currentFormDescription} onChange={handleFormDescriptionChange} />
                  <>
                    <Button variant="outline-success"
                      style={{
                        marginLeft: "10px",
                        padding: "2px 8px",
                        fontSize: "14px",
                      }} onClick={handleSaveFormDescription}>
                      <FaCheck />
                    </Button>
                    <Button variant="outline-danger"
                      style={{
                        marginLeft: "5px",
                        padding: "2px 8px",
                        fontSize: "14px",
                      }} onClick={handleCancelFormDescriptionChange}>
                      <FaXmark />
                    </Button>
                  </>
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                  <strong style={{ fontSize: isMobile ? "14px" : "20px" }}>Description:</strong>{" "}
                  <span style={{ fontSize: isMobile ? "14px" : "18px", maxWidth: "100%", paddingLeft: "10px", textWrap: "nowrap", overflowX: "auto" }}>
                    {form.structure.description}
                  </span>
                  <ThemeButton variant="outline-primary" style={{
                    marginLeft: "10px",
                    padding: "2px 8px",
                    fontSize: isMobile ? "12px" : "14px",
                  }} onClick={handleEditFormDescriptionMode}>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <FaPen /> {isMobile ? null : "Edit"}
                    </div>
                  </ThemeButton>
                </div>
              )}
            </div>
            <div className="questionContainer">
              <strong style={{ fontSize: isMobile ? "14px" : "20px" }}>Questions:</strong>
              <div style={{ display: "flex", alignItems: "center", gap: "5px", flexDirection: isMobile ? "column" : "row" }}>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-primary"
                    id="dropdown-basic"
                    style={{
                      "--bs-btn-color": "#50011a",
                      "--bs-btn-border-color": "#50011a",
                      "--bs-btn-hover-color": "#fff",
                      "--bs-btn-hover-bg": "#50011a",
                      "--bs-btn-hover-border-color": "#50011a",
                      "--bs-btn-focus-shadow-rgb": "33,37,41",
                      "--bs-btn-active-color": "#fff",
                      "--bs-btn-active-bg": "#50011a",
                      "--bs-btn-active-border-color": "#50011a",
                      marginLeft: "10px",
                      padding: "2px 8px",
                      fontSize: isMobile ? "12px" : "14px",
                    }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <FaPlus />
                      Add Question
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ fontSize: isMobile ? "12px" : "" }}>
                    <Dropdown.Item onClick={() => { handleAddBlankQuestion("shortAnswer"); }}>Short Answer</Dropdown.Item>
                    <Dropdown.Item onClick={() => { handleAddBlankQuestion("paragraph"); }}>Paragraph</Dropdown.Item>
                    <Dropdown.Item onClick={() => { handleAddBlankQuestion("checkboxes"); }}>Checkboxes</Dropdown.Item>
                    <Dropdown.Item onClick={() => { handleAddBlankQuestion("multipleChoice"); }}>Multiple Choice</Dropdown.Item>
                    <Dropdown.Item onClick={() => { handleAddBlankQuestion("dropdown"); }}>Dropdown</Dropdown.Item>
                    <Dropdown.Item onClick={() => { handleAddBlankQuestion("date"); }}>Date</Dropdown.Item>
                    <Dropdown.Item onClick={() => { handleAddBlankQuestion("time"); }}>Time</Dropdown.Item>
                    <Dropdown.Item onClick={() => { handleAddBlankQuestion("fileUpload"); }}>File Upload</Dropdown.Item>
                    <Dropdown.Item onClick={() => { handleAddBlankQuestion("scale"); }}>Scale</Dropdown.Item>
                    <Dropdown.Item onClick={() => { handleAddBlankQuestion("numeric"); }}>Numeric</Dropdown.Item>
                    <Dropdown.Item onClick={() => { handleAddBlankQuestion("group"); }}>Group</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown >
                  <Dropdown.Toggle
                    variant="outline-primary"
                    id="dropdown-basic"
                    style={{
                      "--bs-btn-color": "#50011a",
                      "--bs-btn-border-color": "#50011a",
                      "--bs-btn-hover-color": "#fff",
                      "--bs-btn-hover-bg": "#50011a",
                      "--bs-btn-hover-border-color": "#50011a",
                      "--bs-btn-focus-shadow-rgb": "33,37,41",
                      "--bs-btn-active-color": "#fff",
                      "--bs-btn-active-bg": "#50011a",
                      "--bs-btn-active-border-color": "#50011a",
                      marginLeft: "10px",
                      padding: "2px 8px",
                      fontSize: isMobile ? "12px" : "14px",
                    }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <FaPlus />
                      Add a Preset Question
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ fontSize: isMobile ? "12px" : "" }}>
                    {templateFields.map((field) => (
                      <Dropdown.Item key={field.id} onClick={() => { handleAddTemplateQuestion(field.id); }}>
                        {field.title}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <div>
                  <ThemeButton variant="outline-primary" style={{
                    marginLeft: "10px",
                    padding: "2px 8px",
                    fontSize: isMobile ? "12px" : "14px",
                  }} onClick={handleReorderQuestionsMode}>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <FaUpDown />
                      Reorder Questions
                    </div>
                  </ThemeButton>
                  {isReorderingQuestions && (
                    <>
                      <ThemeButton variant="outline-primary" style={{ marginLeft: "10px", }}
                        onClick={handleConfirmReorderQuestions}>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          <FaCheck />
                        </div>
                      </ThemeButton>
                      <ThemeButton variant="outline-primary" style={{ marginLeft: "10px", }}
                        onClick={handleCancelReorderQuestions}>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          <FaXmark />
                        </div>
                      </ThemeButton>
                    </>
                  )}
                </div>
              </div>
              <div>
                {currentQuestions.map((question, index) => (
                  renderQuestions(question, index)
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <h3>No form found</h3>
          </div>
        )}
      </Modal.Body>
      {
        form && (
          <Modal.Footer>
            <div className="flex items-center gap-2">
              Form version:
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Note: Form version identifiers should have no space in between. Examples: &ldquo;v1&ldquo;, &ldquo;V2&ldquo;, &ldquo;form_v1&ldquo;, &ldquo;Form-V2&ldquo;
                  </Tooltip>
                }>
                <span className="text-sm">
                  <FaCircleInfo />
                </span>
              </OverlayTrigger>
            </div>

            <Form className="flex-grow">
              <Form.Group controlId="formVersion" className="m-0">
                <Form.Control
                  type="text"
                  value={newVersionForSave}
                  onChange={e => setNewVersionForSave(e.target.value)}
                  placeholder={currentVersion}
                />
              </Form.Group>
            </Form>

            <ThemeButton variant="outline-primary" onClick={handleUpdateFormNewVersion}>
              <div className="flex items-center gap-2">
                <FaRegSquarePlus />
                Update Form (New Version)
              </div>
            </ThemeButton>

            <ThemeButton variant="outline-primary" onClick={handleAddForm}>
              <div className="flex items-center gap-2">
                <FaCopy />
                Save a Copy
              </div>
            </ThemeButton>

            <ThemeButton variant="outline-primary" onClick={handleUpdateForm}>
              <div className="flex items-center gap-2">
                <FaPenToSquare />
                Update Form
              </div>
            </ThemeButton>
          </Modal.Footer>
        )
      }
    </Modal >
  );
};

export default EditFormModal;