import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import {
  FaPen, FaPlus, FaEye, FaGoogleDrive, FaDownload, FaTrash, FaFolder, FaFolderOpen, FaCheck, FaXmark, FaAngleRight,
  // FaFolderPlus
} from "react-icons/fa6";
// import { BiRename } from "react-icons/bi";
// import { LuBookTemplate } from "react-icons/lu";

import ThemeNavbar from "../components/themeNavbar.jsx";
import ThemeButton from "../components/themeButton.jsx";
import ViewFolderStructureModal from "../modals/viewFolderStructureModal.jsx";
import EditFolderStructureModal from "../modals/editFolderStructureModal.jsx";
import AddFolderStructureModal from "../modals/addFolderStructureModal.jsx";
import PushFolderStructureModal from "../modals/pushFolderStructureModal.jsx";
import "../styles/folderPageStyles.css";
import {
  getUserFolderStructures, getTemplateFolderStructure, getFolderStructureById,
  deleteFolderStructureById, addChildFolderToDrive
} from "../apis/folderApis";
import { getThisUser } from "../apis/userApis";
import { getChildFoldersOfUploadedFolder, updateGoogleFolderName } from "../apis/folderApis";

function FolderPage() {

  const rootFolderName = "Drive";
  const rootFolderGoogleID = "***";
  const EmptyFolderStructure = {
    name: rootFolderName,
    google_drive_id: rootFolderGoogleID,
    children: []
  };

  const [profile, setProfile] = useState(null);
  const [serverError, setServerError] = useState("");
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [userRole, setUserRole] = useState(null);

  const [userFolderStructures, setUserFolderStructures] = useState(null);
  const [templateFolderStructure, setTemplateFolderStructure] = useState(null);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [selectedViewModalFolderStructure, setViewModalSelectedFolderStructure] = useState(null);
  const [viewModalResponse, setViewModalResponse] = useState("");
  const [editModalShow, setEditModalShow] = useState(false);
  const [selectedEditModalFolderStructure, setEditModalSelectedFolderStructure] = useState(null);
  const [editModalResponse, setEditModalResponse] = useState("");
  const [addModalShow, setAddModalShow] = useState(false);
  const [addModalResponse, setAddModalResponse] = useState("");
  const [addTemplateOrStructure, setAddTemplateOrStructure] = useState(null);
  const [selectedPushFolderStructure, setSelectedPushFolderStructure] = useState(null);
  const [pushToDriveModalShow, setPushToDriveModalShow] = useState(false);
  const [pushToDriveModalResponse, setPushToDriveModalResponse] = useState("");

  const [expandedDriveFolders, setExpandedDriveFolders] = useState([]);
  const [hoveredDriveFolder, setHoveredDriveFolder] = useState(null);
  const [driveFileSystemIsLoading, setDriveFileSystemIsLoading] = useState(false);
  const [driveFolderStructure, setDriveFolderStructure] = useState(EmptyFolderStructure);
  const [editingDriveFolderName, setEditingDriveFolderName] = useState(null);
  const [newDriveFolderName, setNewDriveFolderName] = useState("");
  const [addingChildToDriveFolder, setAddingChildToDriveFolder] = useState(null);
  const [newChildDriveFolderName, setNewChildDriveFolderName] = useState("");

  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);

  const userStructuresTableRef = useRef(null);
  const templateStructuresTableRef = useRef(null);

  const scrollTableRight = (tableRef) => {
    if (tableRef.current) {
      const scrollContainer = tableRef.current;
      const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;

      scrollContainer.scrollTo({
        left: maxScrollLeft,
        behavior: "smooth"
      });
    }
  };

  const fetchAllUserFolderStructures = () => {
    getUserFolderStructures()
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          //console.log(response.data);
          setUserFolderStructures(response.data);
        } else {
          handleFetchError(response.status);
        }
      })
      .catch((error) => {
        console.error(error);
        handleFetchError(503);
      });
  };

  const fetchAllTemplateFolderStructure = () => {
    getTemplateFolderStructure()
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setTemplateFolderStructure(response.data);
        } else {
          handleFetchError(response.status);
        }
      })
      .catch((error) => {
        console.error(error);
        handleFetchError(503);
      });
  };

  const fetchUserProfile = async () => {
    // console.log("Fetching profile for: " + email);
    try {
      const response = await getThisUser();
      if (response.status >= 200 && response.status < 300) {
        const user = response.data.user;
        setProfile(user);
        const user_role = user.clients.find(client => client.id === user.active_client_id)?.user_role;
        setUserRole(user_role);
      } else {
        handleFetchError(response.status);
        console.error(response.data.message);
        setServerErrorMessage(response.data.message);
      }
    } catch (error) {
      console.error(error);
      handleFetchError(503);
      setServerErrorMessage(error);
    }
  };

  /**
     * Handles fetch errors based on the status code.
     *
     * @param {number} status - The status code of the fetch error.
     * @return {void} This function does not return anything.
     */
  const handleFetchError = (status) => {
    switch (status) {
      case 400:
        setServerError("400 Bad Request");
        break;
      case 401:
        setServerError("401 Unauthorized Access");
        break;
      case 403:
        setServerError("403 Forbidden Access");
        break;
      case 404:
        setServerError("404 Not Found");
        break;
      case 429:
        setServerError("429 Too Many Requests");
        break;
      case 500:
        setServerError("500 Internal Server Error");
        break;
      case 503:
        setServerError("503 Service Unavailable");
        break;
      default:
        setServerError("Unknown Error");
        break;
    }
  };

  useEffect(() => {
    const userFromStorage = JSON.parse(localStorage.getItem("user"));
    if (!userFromStorage) {
      setServerError("401 Unauthorized Access"); // Set 401 if user is empty
    } else {
      const fetchdate = async () => {
        await fetchUserProfile();
        await fetchAllUserFolderStructures();
        await fetchAllTemplateFolderStructure();
      };

      fetchdate();
      window.addEventListener("resize", handleResize);
    }
  }, []);

  const handleResize = () => {
    if (window.innerWidth < 750) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const toggleFolder = async (folderName) => {
    // console.log("folderName", folderName);
    if (expandedDriveFolders.includes(folderName)) {
      const newExpandedFolders = expandedDriveFolders.filter((folder) => folder !== folderName);
      setExpandedDriveFolders(newExpandedFolders);
    } else {
      const specificFolder = findFolderWithGoogleID(driveFolderStructure, folderName);
      if (specificFolder && (!specificFolder.children || specificFolder.children.length === 0)) {
        await fetchFolderStructure(specificFolder);
      }
      const newExpandedFolders = [...expandedDriveFolders, folderName];
      setExpandedDriveFolders(newExpandedFolders);
    }
  };

  const findFolderWithGoogleID = (folderStructureToSearch, googleID) => {
    if (folderStructureToSearch.google_drive_id === googleID) {
      return folderStructureToSearch;
    } else if (folderStructureToSearch.children) {
      for (const child of folderStructureToSearch.children) {
        const foundFolder = findFolderWithGoogleID(child, googleID);
        if (foundFolder) {
          return foundFolder;
        }
      }
    }
    return null;
  };

  const fetchFolderStructure = async (parentFolder) => {
    setDriveFileSystemIsLoading(true);

    let parentFolderID;

    if (parentFolder.google_drive_id === "***") {
      parentFolderID = -1;
    } else {
      parentFolderID = parentFolder.google_drive_id;
    }

    try {
      const fetchedFolderStructure = await getChildFoldersOfUploadedFolder(parentFolderID);

      if (fetchedFolderStructure.status >= 200 && fetchedFolderStructure.status < 300) {
        // This is a subfolder, update the children of the parent folder
        parentFolder.children = fetchedFolderStructure.data;
        setDriveFolderStructure(prev => ({ ...prev }));
      } else {
        setServerError(fetchedFolderStructure.status);
      }
    } catch (error) {
      console.error("Error fetching folder structure or forms:", error);
      setServerError("An error occurred while fetching data");
    } finally {
      setDriveFileSystemIsLoading(false);
    }
  };

  // const handleRenameDriveFolder = (driveFolderGoogleID, originalDriveFolderName) => {
  //   // console.log(driveFolderGoogleID);
  //   setEditingDriveFolderName(driveFolderGoogleID);
  //   setNewDriveFolderName(originalDriveFolderName);
  // };

  const handleCancelRenameDriveFolder = () => {
    setEditingDriveFolderName(null);
    setNewDriveFolderName(null);
  };

  const handleConfirmRenameDriveFolder = async () => {
    setDriveFileSystemIsLoading(true);
    const reponse = await updateGoogleFolderName(editingDriveFolderName, newDriveFolderName);
    // console.log(reponse);
    if (reponse.status >= 200 && reponse.status < 300) {
      alert("Drive folder name updated successfully.");
      setDriveFolderStructure(EmptyFolderStructure);
    } else {
      alert("Failed to update drive folder name. Error: " + reponse.status);
    }

    setEditingDriveFolderName(null);
    setNewDriveFolderName(null);
    setDriveFileSystemIsLoading(false);
  };

  const handleConfirmAddChildFolder = async () => {
    setDriveFileSystemIsLoading(true);
    const response = await addChildFolderToDrive(addingChildToDriveFolder, newChildDriveFolderName);
    if (response.status >= 200 && response.status < 300) {
      alert("Child folder added successfully.");
      setDriveFolderStructure(EmptyFolderStructure);
    } else {
      alert("Failed to add child folder. Error: " + response.status);
    }
    setAddingChildToDriveFolder(null);
    setNewChildDriveFolderName(null);
    setDriveFileSystemIsLoading(false);
    // console.log("Adding child folder:", newChildDriveFolderName);
  };

  const renderFolderStructure = (folder) => {
    // console.log(folder);
    const folderPath = `${folder.google_drive_id}`;
    const isExpanded = expandedDriveFolders.includes(folderPath);
    const isHovered = hoveredDriveFolder === folderPath;
    const isEditingThisDriveFolderName = editingDriveFolderName === folder.google_drive_id;
    const isAddingChildToThisDriveFolder = addingChildToDriveFolder === folder.google_drive_id;

    return (
      <div key={folderPath} style={{ marginBottom: "5px", paddingLeft: "10px", borderLeft: "2px solid transparent" }}>
        <div
          style={{
            display: "flex", alignItems: "center", cursor: "pointer", padding: "5px", borderRadius: "3px",
            transition: "background-color 0.3s", backgroundColor: isHovered ? "#ede5e8" : "transparent"
          }}
          onMouseEnter={() => setHoveredDriveFolder(folderPath)}
          onMouseLeave={() => setHoveredDriveFolder(null)}
          onClick={(event) => {
            toggleFolder(folderPath);
            event.stopPropagation();
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              toggleFolder(folderPath);
            }
            event.stopPropagation();
          }} role="button" tabIndex="0">
          <div style={{
            background: "none", border: "none", cursor: "pointer", marginRight: "5px",
            display: "flex", alignItems: "center", fontSize: isMobile ? "12px" : "inherit",
          }}>
            <div style={{
              background: "none", border: "none", cursor: "pointer", marginRight: "5px",
              display: "flex", alignItems: "center", fontSize: isMobile ? "12px" : "inherit",
            }}>
              <div style={{ display: "flex", alignItems: "center", whiteSpace: "nowrap" }}>
                {isExpanded ? <FaFolderOpen /> : <FaFolder />}
                <div>
                  {isEditingThisDriveFolderName ? (
                    <input
                      type="text"
                      value={newDriveFolderName}
                      onChange={(e) => setNewDriveFolderName(e.target.value)}
                      style={{ marginLeft: "5px" }}
                    />
                  ) : (
                    <span style={{ marginLeft: "5px", whiteSpace: "nowrap" }}>{folder.name}</span>
                  )}
                  {isMobile ?
                    <></>
                    :
                    <span style={{ marginLeft: "5px", fontSize: "10px", color: "gray", whiteSpace: "nowrap" }}>
                      (googleID: {folder.google_drive_id})
                    </span>}
                </div>
              </div>
            </div>
          </div>
          <>
            {folder.google_drive_id !== rootFolderGoogleID &&
              <div style={{ marginLeft: "15px", fontSize: isMobile ? "14px" : "inherit" }}>
                {isHovered && !isEditingThisDriveFolderName && !isAddingChildToThisDriveFolder && (
                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    {/* <BiRename
                      style={{ transition: "color 1.3s" }}
                      onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                      onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRenameDriveFolder(folder.id, folder.name);
                      }}
                      onKeyDown={() => handleRenameDriveFolder(folder.id, folder.name)}
                      role="button"
                      tabIndex="0"
                    ></BiRename>
                    <FaFolderPlus
                      onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                      onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                      onClick={(e) => {
                        e.stopPropagation();
                        setAddingChildToDriveFolder(folder.id);
                      }}
                      onKeyDown={() => setAddingChildToDriveFolder(folder.id)}
                      role="button"
                      tabIndex="0"
                    >
                    </FaFolderPlus>
                    <LuBookTemplate
                      onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                      onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                    > </LuBookTemplate> */}
                  </ div>
                )}

                {isEditingThisDriveFolderName && (
                  <div style={{ marginLeft: "auto", display: "flex", alignItems: "center", flexDirection: "row" }}>
                    <div
                      style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s", }}
                      onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                      onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                      title="Confirm rename"
                      onClick={(e) => { e.stopPropagation(); handleConfirmRenameDriveFolder(); }}
                      onKeyDown={handleConfirmRenameDriveFolder}
                      role="button"
                      tabIndex="0"
                    >
                      <FaCheck />
                    </div>
                    <div
                      style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s", }}
                      title="Cancel rename"
                      onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                      onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                      onClick={(e) => { e.stopPropagation(); handleCancelRenameDriveFolder(); }}
                      onKeyDown={handleCancelRenameDriveFolder} role="button" tabIndex="0">
                      <FaXmark />
                    </div>
                  </div>
                )}

                {isAddingChildToThisDriveFolder && (
                  <div style={{ marginLeft: "auto", display: "flex", alignItems: "center", flexDirection: "row" }}>
                    <input
                      type="text"
                      value={newChildDriveFolderName}
                      onChange={(e) => setNewChildDriveFolderName(e.target.value)}
                      placeholder="Enter child folder name"
                      style={{ marginRight: "5px" }}
                    />
                    <div
                      style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s" }}
                      onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                      onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                      title="Confirm add child folder"
                      onClick={(e) => { e.stopPropagation(); handleConfirmAddChildFolder(); }}
                      onKeyDown={handleConfirmAddChildFolder}
                      role="button"
                      tabIndex="0"
                    >
                      <FaCheck />
                    </div>
                    <div
                      style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s" }}
                      title="Cancel add child folder"
                      onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                      onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                      onClick={(e) => { e.stopPropagation(); setAddingChildToDriveFolder(null); }}
                      onKeyDown={() => setAddingChildToDriveFolder(null)}
                      role="button"
                      tabIndex="0"
                    >
                      <FaXmark />
                    </div>
                  </div>
                )}
              </div>
            }
          </>
        </div>

        {isExpanded && folder.children && folder.children.length > 0 && (
          <div style={{ marginLeft: "20px" }}>
            {folder.children.map((child) => (
              <div key={child.google_drive_id}>
                {renderFolderStructure(child, folderPath)}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const handleViewFolderStructure = (folderStructureID) => {
    setViewModalSelectedFolderStructure(folderStructureID);
    setViewModalShow(true);
  };

  const handleEditFolderStructure = (folderStructureID) => {
    setEditModalSelectedFolderStructure(folderStructureID);
    setEditModalShow(true);
  };

  const handleDrivePush = async (folderStructureID) => {
    setPushToDriveModalShow(true);
    setSelectedPushFolderStructure(folderStructureID);
  };

  const handleDownloadJSON = async (folderStructureID) => {
    const response = await getFolderStructureById(folderStructureID);
    if (response.status === 200 && response.data) {
      const folderStructure = response.data.structure;
      const fileName = `${response.data.name}.json`;
      const jsonData = JSON.stringify(folderStructure, null, 2);
      const blob = new Blob([jsonData], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      console.log(response);
      alert("Failed to download JSON. Error: " + response.status);
    }
  };

  const handleDeleteFolderStructure = async (folderStructureID) => {
    // Show confirmation popup
    const confirmDelete = window.confirm("Are you sure you want to delete this folder structure?");

    if (confirmDelete) {
      const response = await deleteFolderStructureById(folderStructureID);
      await fetchAllUserFolderStructures();
      await fetchAllTemplateFolderStructure();
      if (response.status >= 200 && response.status < 300) {
        window.alert("Successfully deleted folder structure");
      } else {
        window.alert("Failed to delete folder strucutre. Error: " + response.status + "\n" + response.data.message);
      }
    }
  };

  const handleAddFolderStructure = (addTemplate) => {
    setAddModalShow(true);
    setAddTemplateOrStructure(addTemplate);
  };

  return (
    <div>
      {profile && !serverError && (
        <div>
          <ThemeNavbar />
          {userFolderStructures && (
            <div>
              <div style={{
                display: "flex", flexDirection: "row", alignItems: "center",
                justifyContent: "space-between", paddingLeft: "10px", paddingRight: "20px",
                paddingTop: "20px",
              }}>
                <h1 style={{ fontSize: isMobile ? "1.75em" : "" }}>User Tree Structures</h1>
                <ThemeButton variant="outline-priamary"
                  onClick={() => handleAddFolderStructure(false)} disabled={userRole !== "admin" && userRole !== "manager"}>
                  <div style={{
                    display: "flex", alignItems: "center", gap: "5px", fontSize: isMobile ? "10px" : "16px",
                    whiteSpace: "nowrap"
                  }}>
                    <FaPlus />
                    Make Folder Structure
                  </div>
                </ThemeButton>
              </div>
              <div className="table-container" ref={userStructuresTableRef}>
                {isMobile && (
                  <FaAngleRight onClick={() => scrollTableRight(userStructuresTableRef)}
                    style={{ fontSize: "15px", zIndex: "100", position: "absolute", color: "#fff", left: "90%", marginTop: "2px" }} />
                )}
                <Table striped bordered hover className="curved-table" style={{ minWidth: "450px" }}>
                  <thead style={{ fontSize: isMobile ? "14px" : "20px", height: isMobile ? "40px" : "65px" }}>
                    <tr>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "5%" }}>#</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "10%" }}>Structure ID</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "35%" }}>Structure Name</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "13%" }}>Number of Folders</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "37%" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: isMobile ? "10px" : "18px" }}>
                    {userFolderStructures.map((structure, index) => (
                      <tr key={index} style={{ height: isMobile ? "40px" : "60px" }}>
                        <td>{index + 1}</td>
                        <td>{structure.id}</td>
                        <td>{structure.name}</td>
                        <td>{structure.number_of_folders}</td>
                        <td>
                          <div style={{ display: "flex", gap: "10px", height: isMobile ? "25px" : "45px" }}>
                            <ThemeButton variant="outline-primary"
                              onClick={() => handleViewFolderStructure(structure.id)}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaEye /> {isMobile ? <></> : <>View</>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              onClick={() => handleEditFolderStructure(structure.id)} disabled={userRole !== "admin" && userRole !== "manager"}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaPen /> {isMobile ? <></> : <>Edit</>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              onClick={() => handleDownloadJSON(structure.id)} disabled={userRole !== "admin" && userRole !== "manager"}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaDownload /> {isMobile ? <></> : <>Download JSON</>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              onClick={() => handleDrivePush(structure.id)} disabled={userRole !== "admin" && userRole !== "manager"}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaGoogleDrive /> {isMobile ? <></> : <>Add to Drive</>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              onClick={() => handleDeleteFolderStructure(structure.id)} disabled={userRole !== "admin" && userRole !== "manager"}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaTrash />
                              </div>
                            </ThemeButton>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
          {templateFolderStructure && (
            <div>
              <div style={{
                display: "flex", flexDirection: "row", alignItems: "center",
                justifyContent: "space-between", paddingLeft: "10px", paddingRight: "20px",
                paddingTop: "20px",
              }}>
                <h1 style={{ fontSize: isMobile ? "1.75em" : "" }}>Template Tree Structures</h1>
                <ThemeButton variant="outline-primary"
                  onClick={() => handleAddFolderStructure(true)} disabled={userRole !== "admin" && userRole !== "manager"}>
                  <div style={{
                    display: "flex", alignItems: "center", gap: "5px", fontSize: isMobile ? "10px" : "16px",
                    whiteSpace: "nowrap"
                  }}>                    <FaPlus />
                    Make Template Structure
                  </div>
                </ThemeButton>
              </div>
              <div className="table-container" ref={templateStructuresTableRef}>

                {isMobile && (
                  <FaAngleRight onClick={() => scrollTableRight(templateStructuresTableRef)}
                    style={{ fontSize: "15px", zIndex: "100", position: "absolute", color: "#fff", left: "90%", marginTop: "2px" }} />
                )}
                <Table striped bordered hover className="curved-table">
                  <thead style={{ fontSize: isMobile ? "14px" : "20px", height: isMobile ? "40px" : "65px" }}>
                    <tr>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "5%" }}>#</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "10%" }}>Structure ID</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "35%" }}>Structure Name</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "13%" }}>Number of Folders</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "37%" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: isMobile ? "10px" : "18px" }}>
                    {templateFolderStructure.map((structure, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{structure.id}</td>
                        <td>{structure.name}</td>
                        <td>{structure.number_of_folders}</td>
                        <td>
                          <div style={{ display: "flex", gap: "10px", height: isMobile ? "25px" : "45px" }}>
                            <ThemeButton variant="outline-primary"
                              onClick={() => handleViewFolderStructure(structure.id)}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaEye /> {isMobile ? <></> : <>View</>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              onClick={() => handleEditFolderStructure(structure.id)} disabled={userRole !== "admin" && userRole !== "manager"}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaPen /> {isMobile ? <></> : <>Edit</>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              onClick={() => handleDownloadJSON(structure.id)} disabled={userRole !== "admin" && userRole !== "manager"}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaDownload /> {isMobile ? <></> : <>Download JSON</>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              onClick={() => handleDrivePush(structure.id)} disabled={userRole !== "admin" && userRole !== "manager"}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaGoogleDrive /> {isMobile ? <></> : <>Add to Drive</>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              onClick={() => handleDeleteFolderStructure(structure.id)} disabled={userRole !== "admin" && userRole !== "manager"}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaTrash />
                              </div>
                            </ThemeButton>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
          <div style={{ paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px" }}>
            <h3>Drive Explorer</h3>
            <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              <div style={{
                display: "block", height: "50vh", width: "100%", border: "1px solid #50011a", overflowY: "auto",
                overflowX: "auto", backgroundColor: "#f3f6f4", borderRadius: "10px"
              }}>
                <h5 style={{ backgroundColor: "#50011a", color: "white", padding: "10px", marginBlockEnd: "0px" }}>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div style={{ flex: "1", fontSize: isMobile ? "12px" : "18px" }}>Interactive Drive Explorer</div>
                    {driveFileSystemIsLoading && (
                      <div style={{ textAlign: "center", marginLeft: "10px" }}>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        {isMobile ? <></> : <span className="sr-only">Processing...</span>}
                      </div>
                    )}
                  </div>
                </h5>
                <div style={{ maxHeight: "450px", overflowY: "auto", padding: "10px", paddingRight: "20px" }}>
                  {renderFolderStructure(driveFolderStructure)}
                </div>
              </div>
            </div>

          </div>
        </div>
      )}
      {serverError === "401 Unauthorized Access" && serverError && (
        <div className='site-container'>
          <header className="site-header">
            <div className="title-area">
              <h1 className="site-title">
                <a href="https://birdshot.tamu.edu/">Birdshot Center</a>
              </h1>
            </div>
            <div className="widget-area header-widget-area">
              <a id="site-logo" href="https://birdshot.tamu.edu" style={{ display: "block", maxWidth: "350px" }}>
                <img id="custom-header" src="https://birdshot.tamu.edu/wp-content/themes/coe-theme/images/logos/coe/logos-horizontal/ENGINEERING_logo_HWHT.png" alt="Texas A&M University College of Engineering" />
              </a>
            </div>
          </header>
          <body className='site-body'>
            <h2 className='body-title'>Automated Google Web Form Creator and Manager</h2>
            <p className='login-response'>401 Unauthorized Access</p>
            <p>Please <Link to="/" className='link'>Login</Link> to continue.</p>
          </body>
        </div>
      )}
      {serverError && serverError !== "401 Unauthorized Access" && (
        <div>
          <ThemeNavbar></ThemeNavbar>
          <div className='server-error-container'>
            {serverError === "403 Forbidden Access"
              ? (
                <>
                  <p className='login-response'>
                    {serverError}
                  </p>
                  <p style={{ color: "#50011a" }}>
                    Cause of Error: {serverErrorMessage}
                  </p>
                  <p>
                    Logout of the website and login again, or ask your admin for permission
                  </p>
                </>
              )
              : (
                <>
                  <p className='login-response'>
                    {serverError}
                  </p>
                  <p style={{ color: "#50011a" }}>
                    Cause of Error: {serverErrorMessage}
                  </p>
                  <p>
                    Contact our development team at <a href='mailto:divyanshu@tamu.edu'>divyanshu@tamu.edu</a>, and <a href='mailto:attar.v@tamu.edu'>attar.v@tamu.edu</a>
                  </p>
                </>
              )
            }
          </div>
        </div>
      )}
      <ViewFolderStructureModal
        show={viewModalShow}
        folderStructureID={selectedViewModalFolderStructure}
        onHide={() => {
          setViewModalShow(false);
          if (viewModalResponse !== 200) {
            console.log(viewModalResponse);
            handleFetchError(viewModalResponse);
          }
          const userFromStorage = JSON.parse(localStorage.getItem("user"));
          if (!userFromStorage) {
            setServerError("401 Unauthorized Access"); // Set 401 if user is empty
          } else {
            setProfile(userFromStorage);
            fetchAllUserFolderStructures();
            fetchAllTemplateFolderStructure();
          }
        }}
        setViewFolderStructureResponse={setViewModalResponse}
      />
      <EditFolderStructureModal
        show={editModalShow}
        folderStructureID={selectedEditModalFolderStructure}
        onHide={() => {
          setEditModalShow(false);
          if (editModalResponse !== 200) {
            console.log(editModalResponse);
            handleFetchError(editModalResponse);
          }
          const userFromStorage = JSON.parse(localStorage.getItem("user"));
          if (!userFromStorage) {
            setServerError("401 Unauthorized Access"); // Set 401 if user is empty
          } else {
            setProfile(userFromStorage);
            fetchAllUserFolderStructures();
            fetchAllTemplateFolderStructure();
          }
        }}
        setViewFolderStructureResponse={setEditModalResponse}
      />
      <AddFolderStructureModal
        show={addModalShow}
        onHide={() => {
          setAddModalShow(false);
          if (addModalResponse !== 200) {
            console.log(addModalResponse);
            handleFetchError(addModalResponse);
          }
          const userFromStorage = JSON.parse(localStorage.getItem("user"));
          if (!userFromStorage) {
            setServerError("401 Unauthorized Access"); // Set 401 if user is empty
          } else {
            setProfile(userFromStorage);
            fetchAllUserFolderStructures();
            fetchAllTemplateFolderStructure();
          }
        }}
        setViewFolderStructureResponse={setAddModalResponse}
        structureTemplate={addTemplateOrStructure}
      />
      <PushFolderStructureModal
        show={pushToDriveModalShow}
        onHide={() => {
          setPushToDriveModalShow(false);
          if (pushToDriveModalResponse !== 200) {
            console.log(pushToDriveModalResponse);
            handleFetchError(pushToDriveModalResponse);
          }
          const userFromStorage = JSON.parse(localStorage.getItem("user"));
          if (!userFromStorage) {
            setServerError("401 Unauthorized Access"); // Set 401 if user is empty
          } else {
            setProfile(userFromStorage);
            fetchAllUserFolderStructures();
            fetchAllTemplateFolderStructure();
          }
        }}
        setPushFolderStructureReponse={setPushToDriveModalResponse}
        folderStructureId={selectedPushFolderStructure}
      />
    </div>

  );

}

export default FolderPage;
