import React, { useState, useEffect } from "react";
import dataScribeLogoSmall from "../assets/images/DataScribeLogoSmallCloud.png";
import { FaShieldHalved, FaBug, FaEnvelope, FaFileSignature } from "react-icons/fa6";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 750);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const footerStyle = {
    backgroundColor: "#55001a",
    padding: "1rem",
    marginTop: "auto",
    bottom: 0,
    width: "100%",
    borderRadius: "10px 10px 0 0",
    height: "115px",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  };

  const logoContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  };

  const logoStyle = {
    height: isMobile ? "30px" : "70px",
  };

  const titleStyle = {
    color: "white",
    fontSize: isMobile ? "0" : "42px",
    fontFamily: "'Arial', sans-serif",
    fontWeight: "bold",
    margin: "0",
  };

  const copyrightStyle = {
    color: "white",
    fontSize: "11px",
    marginTop: "1px",
    marginBottom: "0",
  };

  const linksContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    gap: "1rem",
    marginTop: isMobile ? "1rem" : "0",
  };

  const linkStyle = {
    display: "flex",
    alignItems: "center",
    color: "white",
    textDecoration: "none",
    fontSize: "14px",
    fontFamily: "'Arial', sans-serif",
  };

  const iconStyle = {
    marginRight: "0.5rem",
  };

  return (
    <footer style={footerStyle}>
      <div style={containerStyle}>
        <div style={logoContainerStyle}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={dataScribeLogoSmall} alt="DataScribe Logo" style={logoStyle} />
            <p style={titleStyle}>DataScribe.Cloud</p>
          </div>
          <p style={copyrightStyle}>
            Copyright &copy; {new Date().getFullYear()} Datascribe.cloud
          </p>
        </div>
        <div style={linksContainerStyle}>
          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" style={linkStyle}>
            <FaShieldHalved style={iconStyle} />
            {isMobile ? "" : "Privacy Policy"}
          </a>
          <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer" style={linkStyle}>
            <FaFileSignature style={iconStyle} />
            {isMobile ? "" : "Terms & Conditions"}
          </a>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSd22F0NfqY3c-rtJGjcdpM80RFFHcrGOvKj81jgVtWBEkPjOA/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer" style={linkStyle}>
            <FaBug style={iconStyle} />
            {isMobile ? "" : "Report Bug/Request Feature"}
          </a>
          <a href="/contact" style={linkStyle}>
            <FaEnvelope style={iconStyle} />
            {isMobile ? "" : "Contact Us"}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;