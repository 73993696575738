const baseURL = process.env.NODE_ENV === "development" ? "http://localhost:5000" : "";


/**
 * Retrieves the user associated with the access token stored in the cookies.
 * @return {Promise<Object>} A promise that resolves to an object containing the response
 * status and data if successful, otherwise status only.
 */
export const getThisUser = async () => {
  try {
    const response = await fetch(`${baseURL}/api/admin/getThisUser`, { method: "GET", credentials: "include" });
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Adds a user to the system.
 *
 * @param {string} email - The email of the user.
 * @param {string} firstName - The first name of the user.
 * @param {string} lastName - The last name of the user.
 * @param {string} role - The role of the user.
 * @return {Promise<Object>} A promise that resolves to an object containing the status and data of the response.
 */
export const addUserToClient = async (email, firstName, lastName, role) => {
  try {
    const response = await fetch(`${baseURL}/api/admin/addUserToClient?email=${email}&firstName=${firstName}&lastName=${lastName}&role=${role}`,
      { method: "POST", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };

  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Retrieves all users from the server.
 *
 * @return {Object} An object containing the response status and data if successful, otherwise status only.
 */
export const getAllUsers = async (page_number) => {
  try {
    const response = await fetch(`${baseURL}/api/admin/getAllUsers?page_number=${page_number}`,
      { method: "GET", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Retrieves all users associated with the client from the server. The users are
 * paginated with 20 users per page.
 *
 * @param {number} page_number - The page number of users to retrieve.
 * @return {Object} An object containing the response status and data if successful, otherwise status only.
 */
export const getAllUsersByClient = async (page_number) => {
  try {
    const response = await fetch(`${baseURL}/api/admin/getAllUsersByClient?page_number=${page_number}`,
      { method: "GET", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Updates the role of a user.
 *
 * @param {string} email - The email of the user.
 * @param {string} role - The new role for the user.
 * @return {Promise<Object>} An object containing the response status and data if successful, otherwise status only.
 */
export const updateUserRoleForClient = async (email, role) => {
  try {
    const response = await fetch(`${baseURL}/api/admin/updateUserRoleForClient?email=${email}&role=${role}`,
      { method: "POST", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};


/**
 * Retrieves user information by email.
 *
 * @param {string} email - The email of the user to retrieve.
 * @return {Promise<Object>} An object containing the response status and data if successful, otherwise status only.
 */
export const getUserByEmail = async (email) => {
  try {
    const response = await fetch(`${baseURL}/api/admin/getUserByEmail?email=${email}`,
      { method: "GET", credentials: "include" }
    );
    // console.log(response);
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};


/**
 * Switches the active client for the current user.
 *
 * @param {string} clientID - The ID of the client to switch to.
 * @return {Promise<Object>} An object containing the response status and data if successful, otherwise status only.
 */
export const switchActiveClient = async (clientID) => {
  try {
    const response = await fetch(`${baseURL}/api/admin/switchActiveClient?clientID=${clientID}`,
      { method: "PUT", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};