const baseURL = process.env.NODE_ENV === "development" ? "http://localhost:5000" : "";

/**
 * Registers a new user with the server.
 *
 * @param {string} email - The email address of the user to register.
 * @param {string} firstName - The first name of the user to register.
 * @param {string} lastName - The last name of the user to register.
 * @return {Promise} A Promise that resolves to an object containing the response
 * status and data if successful, otherwise status only.
 */
export const registerNewUser = async (email, firstName, lastName, termsAccepted = false, privacyAccepted = false) => {
  try {
    const response = await fetch(`${baseURL}/api/register/registerNewUser?email=${email}&firstName=${firstName}&lastName=${lastName}&termsAndConditionsAccepted=${termsAccepted}&privacyPolicyAccepted=${privacyAccepted}`,
      { method: "POST", credentials: "include" }
    );
    if (response.status === 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Creates a demo client with the server.
 *
 * @param {string} email - The email address of the user to register.
 * @param {string} firstName - The first name of the user to register.
 * @param {string} lastName - The last name of the user to register.
 * @param {string} clientName - The name of the client to be registered.
 * @return {Promise} A Promise that resolves to an object containing the response
 * status and data if successful, otherwise status only.
 */
export const createDemoClient = async (email, firstName, lastName, clientName, termsAccepted = false, privacyAccepted = false) => {
  try {
    const response = await fetch(`${baseURL}/api/register/createDemoClient?email=${email}&firstName=${firstName}&lastName=${lastName}&clientName=${clientName}&termsAndConditionsAccepted=${termsAccepted}&privacyPolicyAccepted=${privacyAccepted}`,
      { method: "POST", credentials: "include" }
    );
    if (response.status === 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};


/**
 * Registers a user with a client using the client registration code.
 *
 * @param {string} email - The email address of the user to register.
 * @param {string} firstName - The first name of the user to register.
 * @param {string} lastName - The last name of the user to register.
 * @param {string} clientRegistrationCode - The registration code for the client.
 * @return {Promise<Object>} A Promise that resolves to an object containing the response
 * status and data if successful, otherwise status only.
 */
export const registerUserWithClient = async (email, firstName, lastName, clientRegistrationCode, termsAccepted = false, privacyAccepted = false) => {
  try {
    const response = await fetch(`${baseURL}/api/register/registerUserWithClient?email=${email}&firstName=${firstName}&lastName=${lastName}&clientRegistrationCode=${clientRegistrationCode}&termsAndConditionsAccepted=${termsAccepted}&privacyPolicyAccepted=${privacyAccepted}`,
      { method: "POST", credentials: "include" }
    );
    if (response.status === 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};
