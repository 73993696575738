import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { getThisUser } from "../apis/userApis";
import { getGroupById } from "../apis/groupsApis";
import { FaSearch } from "react-icons/fa";
import ThemeButton from "../components/themeButton.jsx";

const GOOGLE_DRIVE_ROLES = {
  reader: "Viewer",
  commenter: "Commenter",
  writer: "Contributor",
  fileOrganizer: "Content Manager",
  organizer: "Manager"
};

const ViewUsersInGroupModal = ({ show, onHide, groupId }) => {
  const [profile, setProfile] = useState(null);
  const [group, setGroup] = useState({ name: "", users: [] });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  const fetchUserProfile = () => {
    getThisUser()
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setProfile(response.data.user);
        } else {
          console.error("Failed to fetch user profile");
          onHide();
        }
      })
      .catch((error) => {
        console.error(error);
        onHide();
      });
  };

  const fetchGroup = () => {
    getGroupById(groupId)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setGroup(response.data.group);
          setFilteredUsers(response.data.group.users);
        } else {
          console.error("Failed to fetch group data");
          alert("Failed to fetch group data");
          onHide();
        }
      })
      .catch((error) => {
        console.error(error);
        alert("Failed to fetch group data");
        onHide();
      });
  };

  useEffect(() => {
    if (show) {
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (userFromStorage) {
        fetchUserProfile();
        fetchGroup();
      } else {
        alert("Failed to fetch user profile. User not logged in.");
        onHide();
      }
    }
  }, [show, onHide]);

  useEffect(() => {
    const filtered = group.users.filter(
      (user) =>
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        `${user.first_name} ${user.last_name}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [searchTerm, group.users]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const translateRole = (role) => {
    return GOOGLE_DRIVE_ROLES[role] || role;
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          View Users in {group.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
        <Form.Group className="mb-3">
          <div className="input-group">
            <span className="input-group-text">
              <FaSearch />
            </span>
            <Form.Control
              type="text"
              placeholder="Search by name or email"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </Form.Group>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Google Drive Role</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.id}>
                <td>
                  {user.email === profile?.email ? (
                    <b>{`${user.first_name || ""} ${user.last_name || ""} (You)`}</b>
                  ) : (
                    `${user.first_name || ""} ${user.last_name || ""}`
                  )}
                </td>
                <td>{user.email}</td>
                <td>{translateRole(user.group_permission_role)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <ThemeButton variant="outline-primary" onClick={onHide}>
          Close
        </ThemeButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewUsersInGroupModal;