import React from "react";
import { Modal } from "react-bootstrap";
import ThemeButton from "../components/themeButton";
import { updateUserAcceptedPrivacyPolicyTermsAndConditions } from "../apis/authorizationApis";

const PrivacyPolTermsAndCondModal = ({ showModal, setShowModal, userEmail, onClose }) => {

  const acceptPrivacyPolicyTermsAndConditions = async () => {
    const response = await updateUserAcceptedPrivacyPolicyTermsAndConditions(true, true, userEmail);
    if (response.status === 200) {
      alert("Privacy policy/terms and conditions accepted successfully!");
      setShowModal(false);
      onClose();
    } else {
      console.error("Error accepting privacy policy/terms and conditions:", response);
      alert("Error accepting privacy policy/terms and conditions. Please try again later.\nError: " + response.data.message);
    }
  };


  return (
    <Modal
      show={showModal}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Privacy Policy/Terms and Conditions
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          It looks like you have not accepted our latest Privacy Policy/Terms and
          Conditions. Please accept the Privacy Policy/Terms and Conditions to continue.
        </p>
        <p>
          Please visit our{" "}
          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>{" "}
          page for more details.
        </p>
        <p>
          Please visit our{" "}
          <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
            Terms and Conditions
          </a>{" "}
          page for more details.
        </p>
        <p>(Accepting as <b>{userEmail}</b>)</p>
      </Modal.Body>
      <Modal.Footer>
        <ThemeButton variant="outline-primary" onClick={acceptPrivacyPolicyTermsAndConditions}>
          I accept the privacy policy/terms and conditions
        </ThemeButton>
      </Modal.Footer>
    </Modal>
  );
};

export default PrivacyPolTermsAndCondModal;