import React, { useState, useEffect } from "react";
import "./../styles/rootStyle.css";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ThemeButton from "../components/themeButton";
import LandingPageNavbar from "../components/landingPageNavbar";
import { useNavigate, useLocation } from "react-router-dom";
import { FaCircleInfo } from "react-icons/fa6";
import { registerNewUser, createDemoClient, registerUserWithClient } from "../apis/registerationApis";
import Footer from "../components/footer";

const RegistrationPage = () => {
  const [formType, setFormType] = useState("user");
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [clientRegistrationId, setClientRegistrationId] = useState("");
  const [orgName, setOrgName] = useState("");
  const [activeButton, setActiveButton] = useState("user");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();


  const handleResize = () => {
    setIsMobile(window.innerWidth < 750);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const registerWithClient = searchParams.get("registerWithClient");
    if (registerWithClient) {
      setClientRegistrationId(registerWithClient);
      setActiveButton("orgUser");
      setFormType("orgUser");
    }
    window.addEventListener("resize", handleResize);
  }, [location]);

  const infoTooltip = (
    <Tooltip>
      Name will be updated by the backend to match with names associated with user&apos;s email id.
    </Tooltip>
  );

  const handleInputChange = (e, setter) => {
    setter(e.target.value);
  };

  const handleButtonClick = (type) => {
    setFormType(type);
    setStep(1);
    setActiveButton(type);
  };

  const renderForm = () => {
    const formStyle = {
      backgroundColor: "white",
      borderRadius: "8px",
      boxShadow: "0 0 10px rgba(0,0,0,0.1)",
      padding: isMobile ? "10px" : "40px 20px 60px 20px",
      width: "100%",
    };

    const inputStyle = {
      width: "100%"
    };

    const handleUserRegistration = async () => {
      // sanity checks
      if (email === "") {
        alert("Email is required");
        return;
      }
      if (firstName === "") {
        alert("First name is required");
        return;
      }
      if (lastName === "") {
        alert("Last name is required");
        return;
      }

      if (!termsAccepted || !privacyAccepted) {
        alert("Please accept both the Terms and Conditions and the Privacy Policy to register.");
        return;
      }

      // check if email is in valid format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        alert("Invalid email format, please enter a valid email address");
        return;
      }


      const response = await registerNewUser(email, firstName, lastName, termsAccepted, privacyAccepted);

      if (response.status === 200) {
        // User registration successful
        alert("User registration successful!. \nServer response: " + JSON.stringify(response.data.message));
        navigate("/");
        return;
      } else {
        // User registration failed
        alert("User registration failed.\nServer Error: " + response.status + " " + response.data.message);
        return;
      }
    };

    const handleOrgRegistration = async () => {
      // sanity checks
      if (email === "") {
        alert("Email is required");
        return;
      }
      if (firstName === "") {
        alert("First name is required");
        return;
      }
      if (lastName === "") {
        alert("Last name is required");
        return;
      }
      if (clientRegistrationId === "") {
        alert("Organization Registration ID is required");
        return;
      }
      if (!termsAccepted || !privacyAccepted) {
        alert("Please accept both the Terms and Conditions and the Privacy Policy to register.");
        return;
      }

      // check if email is in valid format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        alert("Invalid email format, please enter a valid email address");
        return;
      }

      const response = await registerUserWithClient(email, firstName, lastName, clientRegistrationId, termsAccepted, privacyAccepted);
      if (response.status === 200) {
        // User registration successful
        alert("Organization registration successful!. \nServer response: " + JSON.stringify(response.data.message));
        navigate("/");
        return;
      } else {
        // User registration failed
        alert("Organization registration failed.\nServer Error: " + response.status + " " + response.data.message);
        return;
      }
    };

    const handleNewOrgRegistration = async () => {
      // sanity checks
      if (email === "") {
        alert("Email is required");
        return;
      }
      if (firstName === "") {
        alert("First name is required");
        return;
      }
      if (lastName === "") {
        alert("Last name is required");
        return;
      }
      if (orgName === "") {
        alert("Organization name is required");
        return;
      }
      // check if email is in valid format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        alert("Invalid email format, please enter a valid email address");
        return;
      }


      const response = await createDemoClient(email, firstName, lastName, orgName, termsAccepted, privacyAccepted);
      if (response.status === 200) {
        // User registration successful
        alert("Organization registration successful!. \nServer response: " + JSON.stringify(response.data.message));
        navigate("/");
        return;
      } else {
        // User registration failed
        alert("Organization registration failed.\nServer Error: " + response.status + " " + response.data.message);
        return;
      }

    };

    const renderCheckboxes = () => (
      <>
        <Form.Group className="mb-3" controlId="formTerms">
          <Form.Check
            type="checkbox"
            checked={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
            required
            label={
              <span>
                I accept the <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
              </span>
            }
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formPrivacy">
          <Form.Check
            type="checkbox"
            checked={privacyAccepted}
            onChange={(e) => setPrivacyAccepted(e.target.checked)}
            required
            label={
              <span>
                I accept the <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
              </span>
            }
          />
        </Form.Group>
      </>
    );


    switch (formType) {
      case "user":
        return (
          <Form style={formStyle}>
            <Form.Label style={{ fontSize: "24px", fontWeight: "bold", color: "#50011a" }}>User Registration</Form.Label>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" value={email} onChange={(e) => handleInputChange(e, setEmail)} placeholder="Google Email" required style={inputStyle} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formFirstName">
              <OverlayTrigger placement="right" overlay={infoTooltip}>
                <Form.Label>
                  <span>First Name</span>
                  <FaCircleInfo style={{ marginLeft: "5px", cursor: "pointer" }} />
                </Form.Label>
              </OverlayTrigger>
              <Form.Control type="text" value={firstName} onChange={(e) => handleInputChange(e, setFirstName)} placeholder="First Name" required style={inputStyle} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formLastName">
              <OverlayTrigger placement="right" overlay={infoTooltip}>
                <Form.Label>
                  <span>Last Name</span>
                  <FaCircleInfo style={{ marginLeft: "5px", cursor: "pointer" }} />
                </Form.Label>
              </OverlayTrigger>
              <Form.Control type="text" value={lastName} onChange={(e) => handleInputChange(e, setLastName)} placeholder="Last Name" required style={inputStyle} />
            </Form.Group>
            {renderCheckboxes()}
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
              <ThemeButton variant="outline-primary" type="submit" onClick={handleUserRegistration}>Register</ThemeButton>
              <Form.Text>Already have an account? <a href="/" style={{ color: "#50011a" }}>Sign In</a></Form.Text>
            </div>
          </Form>
        );
      case "orgUser":
        return (
          <Form onSubmit={handleOrgRegistration} style={formStyle}>
            <Form.Label style={{ fontSize: "24px", fontWeight: "bold", color: "#50011a" }}>User Registration For Organization</Form.Label>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" value={email} onChange={(e) => handleInputChange(e, setEmail)} placeholder="Google Email" required style={inputStyle} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formFirstName">
              <OverlayTrigger placement="right" overlay={infoTooltip}>
                <Form.Label>
                  <span>First Name</span>
                  <FaCircleInfo style={{ marginLeft: "5px", cursor: "pointer" }} />
                </Form.Label>
              </OverlayTrigger>
              <Form.Control type="text" value={firstName} onChange={(e) => handleInputChange(e, setFirstName)} placeholder="First Name" required style={inputStyle} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formLastName">
              <OverlayTrigger placement="right" overlay={infoTooltip}>
                <Form.Label>
                  <span>Last Name</span>
                  <FaCircleInfo style={{ marginLeft: "5px", cursor: "pointer" }} />
                </Form.Label>
              </OverlayTrigger>
              <Form.Control type="text" value={lastName} onChange={(e) => handleInputChange(e, setLastName)} placeholder="Last Name" required style={inputStyle} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formClientId">
              <Form.Label>Organization Registration ID</Form.Label>
              <Form.Control type="text" value={clientRegistrationId} onChange={(e) => handleInputChange(e, setClientRegistrationId)} placeholder="Organization Registration ID" required style={inputStyle} />
            </Form.Group>
            {renderCheckboxes()}
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
              <ThemeButton variant="outline-primary" type="submit" onClick={handleOrgRegistration}>Register</ThemeButton>
              <Form.Text>Already have an account? <a href="/" style={{ color: "#50011a" }}>Sign In</a></Form.Text>
            </div>
          </Form>
        );
      case "newOrg":
        return (
          <Form style={formStyle}>
            <Form.Label style={{ fontSize: "24px", fontWeight: "bold", color: "#50011a" }}>Organization Registration</Form.Label>
            {step === 1 && (
              <>
                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" value={email} onChange={(e) => handleInputChange(e, setEmail)} placeholder="Google Email" required style={inputStyle} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formFirstName">
                  <OverlayTrigger placement="right" overlay={infoTooltip}>
                    <Form.Label>
                      <span>First Name</span>
                      <FaCircleInfo style={{ marginLeft: "5px", cursor: "pointer" }} />
                    </Form.Label>
                  </OverlayTrigger>
                  <Form.Control type="text" value={firstName} onChange={(e) => handleInputChange(e, setFirstName)} placeholder="First Name" required style={inputStyle} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formLastName">
                  <OverlayTrigger placement="right" overlay={infoTooltip}>
                    <Form.Label>
                      <span>Last Name</span>
                      <FaCircleInfo style={{ marginLeft: "5px", cursor: "pointer" }} />
                    </Form.Label>
                  </OverlayTrigger>
                  <Form.Control type="text" value={lastName} onChange={(e) => handleInputChange(e, setLastName)} placeholder="Last Name" required style={inputStyle} />
                </Form.Group>
                {renderCheckboxes()}
                <ThemeButton variant="outline-primary" onClick={() => {
                  if (email === "" || firstName === "" || lastName === "") {
                    alert("Please fill out all fields before proceeding.");
                  } else if (!termsAccepted || !privacyAccepted) {
                    alert("Please accept both the Terms and Conditions and the Privacy Policy to register.");
                  } else {
                    setStep(2);
                  }
                }}>Next</ThemeButton>
              </>
            )}
            {step === 2 && (
              <>
                <Form.Group className="mb-3" controlId="formOrgName">
                  <Form.Control type="text" value={orgName} onChange={(e) => handleInputChange(e, setOrgName)} placeholder="Organization Name" required style={inputStyle} />
                </Form.Group>
                <ThemeButton variant="outline-primary" style={{ marginRight: "5px" }} onClick={() => setStep(1)}>Go Back</ThemeButton>
                <ThemeButton variant="outline-primary" type="submit" onClick={handleNewOrgRegistration}>Create New Organization</ThemeButton>
              </>
            )}
          </Form>
        );
      default:
        return null;
    }
  };

  const buttonStyle = (type) => ({
    ...(activeButton === type ? {
      backgroundColor: "#50011a",
      color: "white",
      boxShadow: "0 0 0 0.2rem rgba(80,1,26,.5)",
      fontSize: isMobile ? "12px" : "16px",
    } : {
      fontSize: isMobile ? "12px" : "16px",
    }),
  });


  return (
    <div className='site-container'>
      <LandingPageNavbar />
      <main className="site-body" style={{ backgroundColor: "transparent" }}>
        <h5 className='body-title' style={{ marginTop: "10px" }}>DataScribe Registration</h5>
        <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: "10px", width: "100%", height: "100%", padding: "10px" }}>
          <div className="container py-4 bg-white rounded shadow-lg registeration-details-container">
            <div style={{ display: "flex", gap: "10px" }}>
              <ThemeButton
                variant="outline-primary"
                onClick={() => handleButtonClick("user")}
                style={buttonStyle("user")}
              >
                User Registration
              </ThemeButton>
              <ThemeButton
                variant="outline-primary"
                onClick={() => handleButtonClick("orgUser")}
                style={buttonStyle("orgUser")}
              >
                User Registration for Organization
              </ThemeButton>
              <ThemeButton
                variant="outline-primary"
                onClick={() => handleButtonClick("newOrg")}
                style={buttonStyle("newOrg")}
              >
                New Demo Organization
              </ThemeButton>
            </div>
            <div style={{ marginTop: "10px", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "90%" }}>
              <p style={{ color: "#50011a", fontSize: isMobile ? "12px" : "16px", paddingTop: "10px", marginBottom: "20px" }}>
                {activeButton === "user" && (
                  <>
                    Please enter the following information to create a new user account.<br />
                    Registring as a user will allow you access the DataScribe platform. <br />
                    You will be able to access all the DataScribe pages to get youself familiar with the platform. <br />
                    You won&apos;t be a part of any organization, therefore you won&apos;t be able to push data to the platform.
                  </>
                )}
                {activeButton === "orgUser" && (
                  <>
                    Please enter the following information to create a new user account for your organization. <br />
                    Registring as a user will allow you access the DataScribe platform and be part of your organization. <br />
                    You will be able to access all the DataScribe pages your role in the organization allows.
                  </>
                )}
                {activeButton === "newOrg" && (
                  <>
                    Please enter the following information to create a new organization. <br />
                    The Demo Organization will allow you access the features of the DataScribe platform for 7 days. <br />
                    We will allow you to have a limited number of users, folder and data.
                  </>
                )}
              </p>
              <div style={{ marginTop: "auto" }}>
                <FeatureList registrationType={formType} fontSize={isMobile ? "12px" : "16px"} />
              </div>
            </div>
          </div>
          <div className="justify-content-center" style={{ flex: 1, height: "100%", fontSize: isMobile ? "12px" : "16px" }}>
            {renderForm()}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

const FeatureList = ({ registrationType, fontSize }) => {
  const features = {
    user: [
      { name: "Access to datascribe.cloud", value: "Limited access", color: "#FFC300" },
      { name: "Access to administrative view", value: "No access", color: "#E65F5F" },
      { name: "Duration of access", value: "Unlimited", color: "#55883B" },
      { name: "Number of users allowed", value: "N/A (Individual account)", color: "#FFC300" },
      { name: "Number of data structures allowed", value: "N/A (not part of organization)", color: "#FFC300" },
      { name: "Number of data travellers allowed", value: "N/A (not part of organization)", color: "#FFC300" },
      { name: "Number of data dispatches allowed", value: "N/A (not part of organization)", color: "#FFC300" }
    ],
    orgUser: [
      { name: "Access to datascribe.cloud", value: "Full access", color: "#55883B" },
      { name: "Access to administrative view", value: "Based on role in organization", color: "#FFC300" },
      { name: "Duration of access", value: "Based on organization subscription", color: "#55883B" },
      { name: "Number of users allowed", value: "Based on organization plan", color: "#55883B" },
      { name: "Number of data structures allowed", value: "Based on organization plan", color: "#55883B" },
      { name: "Number of data travellers allowed", value: "Based on organization plan", color: "#55883B" },
      { name: "Number of data dispatches allowed", value: "Based on organization plan", color: "#55883B" }
    ],
    newOrg: [
      { name: "Access to datascribe.cloud", value: "Full access", color: "#55883B" },
      { name: "Access to administrative view", value: "Full access", color: "#55883B" },
      { name: "Duration of access", value: "7 days (demo period)", color: "#FFC300" },
      { name: "Number of users allowed", value: "Up to 5 users", color: "#FFC300" },
      { name: "Number of data structures allowed", value: "Up to 3 data structures", color: "#FFC300" },
      { name: "Number of data travellers allowed", value: "Up to 5 data travellers", color: "#FFC300" },
      { name: "Number of data dispatches allowed", value: "Up to 10 data dispatches", color: "#FFC300" }
    ]
  };

  const selectedFeatures = features[registrationType];

  return (
    <div>
      <h5 className="mb-3" style={{ color: "#50011a" }}>Features for {registrationType === "orgUser" ? "Organization User" : registrationType === "newOrg" ? "New Demo Organization" : "User"}</h5>
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {selectedFeatures.map((feature, index) => (
          <li key={index} style={{
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline"
          }}>
            <span style={{ fontSize: fontSize }}>{feature.name}: </span>
            <span style={{ color: feature.color, fontWeight: "bold", fontSize: fontSize }}>{feature.value}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};


export default RegistrationPage;