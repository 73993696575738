import React, { useState, useEffect } from "react";
import dataScribeLogo from "../assets/images/DataScribeLogo.png";
import dataScribeLogoSmall from "../assets/images/DataScribeLogoSmallCloud.png";
import smallTamuLogo from "../assets/images/tamu_white_logo_small.png";



const LandingPageNavbar = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 750);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);



  return (
    <header className="site-header">
      <div className="title-area">
        <div className="site-title">
          <a href="/">
            <img src={isMobile ? dataScribeLogoSmall : dataScribeLogo} alt="DataScribe Logo" className="datascribe-logo" />
          </a>
        </div>
      </div>
      <div className="widget-area header-widget-area">
        <a id="site-logo" href="https://birdshot.tamu.edu">
          {isMobile ?
            <img src={smallTamuLogo} alt="Texas A&M University College of Engineering" /> :
            <img src="https://birdshot.tamu.edu/wp-content/themes/coe-theme/images/logos/coe/logos-horizontal/ENGINEERING_logo_HWHT.png" alt="Texas A&M University College of Engineering" />
          }
        </a>
      </div>
    </header>
  );
};

export default LandingPageNavbar;