import { Dropdown, Table, Badge } from "react-bootstrap";
import {
  FaCheck, FaPen, FaPlus, FaXmark, FaUserPlus, FaUserMinus, FaAngleRight,
  FaCloudArrowUp, FaCloudArrowDown, FaUpRightFromSquare, FaCopy, FaLink, FaToggleOff, FaToggleOn,
  FaUsersGear, FaUsers, FaRotate
} from "react-icons/fa6";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import ThemeNavbar from "../components/themeNavbar";
import ThemeButton from "../components/themeButton";
import AddUserForm from "../modals/addUserForm";
import AddGroupForm from "../modals/addGroupForm";
import AddUsersToGroupModal from "../modals/addUsersToGroupModal";
import RemoveUsersFromGroupModal from "../modals/removeUsersFromGroupModal";
import AssignDriveToGroupModal from "../modals/assignDriveToGroupModal";
import ImportDriveToGroupModal from "../modals/importDriveToGroupModal";
import ViewUsersInGroupModal from "../modals/viewUsersInGroupModal";
import ManageUsersInGroupModal from "../modals/manageUsersInGroupModal";
import "../styles/profilePageStyles.css";
import { getAllUsersByClient, updateUserRoleForClient, getThisUser } from "../apis/userApis";
import { getAllGroups } from "../apis/groupsApis";
import { getClientForAdmin, openClientForRegistration, closeClientForRegistration } from "../apis/clientApis";
import RecalibrateDriveModal from "../modals/recalibrateDriveModal";

const ROLES = ["admin", "manager", "user", "defunct"];

function ProfilePage() {

  const [profile, setProfile] = useState([]);
  const [profileRole, setProfileRole] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);
  const [serverError, setServerError] = useState("");
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [addUserResponse, setAddUserResponse] = useState(null);
  const [groupsData, setGroupsData] = useState([]);
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);
  const [addGroupResponse, setAddGroupResponse] = useState(null);
  const [showAddUsersToGroupModal, setShowAddUsersToGroupModal] = useState(false);
  const [addUsersToGroupResponse, setAddUsersToGroupResponse] = useState(null);
  const [showRemoveUsersFromGroupModal, setShowRemoveUsersFromGroupModal] = useState(false);
  const [removeUsersFromGroupResponse, setRemoveUsersFromGroupResponse] = useState(null);
  const [showAssignDriveToGroupModal, setShowAssignDriveToGroupModal] = useState(false);
  const [showViewUsersInGroupModal, setShowViewUsersInGroupModal] = useState(false);
  const [showManageUsersInGroupModal, setShowManageUsersInGroupModal] = useState(false);
  const [showRecalibrateDriveModal, setShowRecalibrateDriveModal] = useState(false);
  const [assignDriveToGroupResponse, setAssignDriveToGroupResponse] = useState(null);
  const [showImportDriveToGroupModal, setShowImportDriveToGroupModal] = useState(false);
  const [importDriveToGroupResponse, setImportDriveToGroupResponse] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const usersTableRef = useRef(null);
  const groupsTableRef = useRef(null);
  const observer = useRef();

  /**
   * Fetches all users using the provided access token.
   *
   * @return {void} This function does not return anything.
   */
  const fetchAllUsers = useCallback((pageNumber = 0) => {
    getAllUsersByClient(pageNumber)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setUsersData(prevUsers => {
            if (pageNumber === 0) {
              return response.data.users;
            } else {
              return [...prevUsers, ...response.data.users];
            }
          });
          setHasMore(response.data.users.length > 0);
        } else {
          handleFetchError(response.status);
          setServerErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
        handleFetchError(503);
      });
  }, []);

  const fetchAllGroups = async () => {
    try {
      const response = await getAllGroups();
      if (response.status >= 200 && response.status < 300) {
        setGroupsData(response.data);
      } else {
        handleFetchError(response.status);
      }
    } catch (error) {
      console.error(error);
      handleFetchError(503);
    }
  };

  const fetchUserProfile = async () => {
    // console.log("Fetching profile for: " + email);
    try {
      const response = await getThisUser();
      if (response.status >= 200 && response.status < 300) {
        const user = response.data.user;
        setProfile(user);
        localStorage.setItem("user", JSON.stringify(user));
        const user_role = user.clients.find(client => client.id === user.active_client_id)?.user_role;
        setProfileRole(user_role);
        if (user_role === "admin" || user_role === "manager") {
          await fetchAllGroups();
        }
      } else {
        handleFetchError(response.status);
      }
    } catch (error) {
      console.error(error);
      handleFetchError(503);
    }
  };

  const fetchClientData = async () => {
    try {
      const response = await getClientForAdmin();
      if (response.status >= 200 && response.status < 300) {
        setClientData(response.data);
      } else {
        handleFetchError(response.status);
      }
    } catch (error) {
      console.error(error);
      handleFetchError(503);
    }
  };

  /**
    * Handles fetch errors based on the status code.
    *
    * @param {number} status - The status code of the fetch error.
    * @return {void} This function does not return anything.
    */
  const handleFetchError = (status) => {
    switch (status) {
      case 304:
        break;
      case 400:
        setServerError("400 Bad Request");
        break;
      case 401:
        setServerError("401 Unauthorized Access");
        break;
      case 403:
        setServerError("403 Forbidden Access");
        break;
      case 404:
        setServerError("404 Not Found");
        break;
      case 429:
        setServerError("429 Too Many Requests");
        break;
      case 500:
        setServerError("500 Internal Server Error");
        break;
      case 503:
        setServerError("503 Service Unavailable");
        break;
      default:
        setServerError("500 Internal Server Error");
        break;
    }
  };

  const lastUserElementRef = useCallback(node => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [hasMore]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (!userFromStorage) {
        setServerError("401 Unauthorized Access");
        setIsLoading(false);
        return;
      }

      try {
        await fetchUserProfile(userFromStorage.email);
        await fetchAllUsers(page);

        const activeClient = userFromStorage.clients.find(
          client => client.id === userFromStorage.active_client_id
        );

        if (activeClient && activeClient.user_role === "admin") {
          await fetchClientData();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setServerError("500 Internal Server Error");
      } finally {
        setIsLoading(false);
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    };

    fetchData();
  }, []);

  useEffect(() => {
    fetchAllUsers(page);
  }, [page, fetchAllUsers]);


  const handleResize = () => {
    if (window.innerWidth < 750) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const copyToClipboard = async (text) => {
    console.log("copyToClipboard: " + text);
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const scrollTableRight = (tableRef) => {
    if (tableRef.current) {
      const scrollContainer = tableRef.current;
      const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;

      scrollContainer.scrollTo({
        left: maxScrollLeft,
        behavior: "smooth"
      });
    }
  };

  const handleRoleChange = (email) => {
    if (!selectedRole) {
      alert("Please select a role.");
      return;
    }

    updateUserRoleForClient(email, selectedRole)
      .then((response) => {
        if (response.status === 200) {
          // Role change successful
          alert("Role updated successfully.");
          // Refresh user data
          fetchAllUsers();
        } else {
          // Role change unsuccessful
          alert("Failed to update role.\n" + response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
        // Role change unsuccessful
        alert("Failed to update role.");
      });
  };

  const handleAddUser = () => {
    setShowAddUserModal(true);
  };

  const handleCloseAddUserModal = () => {
    console.log(addUserResponse);
    if (addUserResponse === 401) {
      setProfile(null);
      setServerError("401 Unauthorized Access");
    }
    else {
      fetchAllUsers();
    }
    setShowAddUserModal(false);
  };

  const handleAddGroup = () => {
    setShowAddGroupModal(true);
  };

  const handleCloseAddGroupModal = () => {
    console.log(addGroupResponse);
    if (addGroupResponse >= 399) {
      setServerError("500 Internal Server Error");
    }
    else {
      fetchUserProfile(profile.email);
      fetchAllGroups();
      fetchAllUsers();
    }
    setShowAddGroupModal(false);
  };

  const handleEdit = (userId, currentRole) => {
    setEditMode(true);
    setSelectedUserId(userId);
    setSelectedRole(currentRole);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setSelectedUserId(null);
    setSelectedRole(null);
  };

  const handleSaveEdit = () => {
    if (!selectedRole) {
      alert("Please select a role.");
      return;
    }

    handleRoleChange(usersData.find(user => user.id === selectedUserId).email);

    setEditMode(false);
    setSelectedUserId(null);
    setSelectedRole(null);
  };

  const handleAddUserToGroup = async (groupId) => {
    setSelectedGroupId(groupId);
    setShowAddUsersToGroupModal(true);
  };

  const handleRemoveUserFromGroup = async (groupId) => {
    setSelectedGroupId(groupId);
    setShowRemoveUsersFromGroupModal(true);
  };

  const handleCloseAddUsersToGroupsModal = () => {
    if (addUsersToGroupResponse >= 399) {
      handleFetchError(addUsersToGroupResponse);
    }
    setShowAddUsersToGroupModal(false);
    fetchAllUsers();
    setGroupsData([]);
    fetchAllGroups();
    setSelectedGroupId(null);
  };

  const handleCloseRemoveUsersFromGroupsModal = () => {
    if (removeUsersFromGroupResponse >= 399) {
      handleFetchError(removeUsersFromGroupResponse);
    }
    setShowRemoveUsersFromGroupModal(false);
    fetchAllUsers();
    setGroupsData([]);
    fetchAllGroups();
    setSelectedGroupId(null);
  };

  const handleAssignDriveToGroup = (groupId) => {
    setSelectedGroupId(groupId);
    setShowAssignDriveToGroupModal(true);
  };

  const handleCloseAssignDriveToGroupModal = () => {
    if (assignDriveToGroupResponse >= 399) {
      handleFetchError(assignDriveToGroupResponse);
    }
    setShowAssignDriveToGroupModal(false);
    setSelectedGroupId(null);
    fetchAllGroups();
  };

  const handleRecalibrateDrive = (groupId) => {
    setSelectedGroupId(groupId);
    setShowRecalibrateDriveModal(true);
  };

  const handleCloseRecalibrateDriveModal = () => {
    setShowRecalibrateDriveModal(false);
    setSelectedGroupId(null);
  };

  const calculateRemainingTime = (registrationTime) => {
    const registrationDate = new Date(registrationTime);
    const expirationDate = new Date(registrationDate.getTime() + 7 * 24 * 60 * 60 * 1000); // 7 days after registration
    const now = new Date();
    const remainingTime = expirationDate - now;

    if (remainingTime <= 0) {
      return "Expired";
    }

    const days = Math.floor(remainingTime / (24 * 60 * 60 * 1000));
    const hours = Math.floor((remainingTime % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    const minutes = Math.floor((remainingTime % (60 * 60 * 1000)) / (60 * 1000));

    return `${days}d ${hours}h ${minutes}m`;
  };

  const handleOpenClientForRegistration = () => {
    openClientForRegistration()
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          // Client registration successful
          setClientData(response.data.client);
          alert("Client open for registration.");
        } else {
          // Client registration unsuccessful
          alert("Client opening for registration unsuccessful.\n" + response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
        // Client registration unsuccessful
        alert("Client could not be opened for registration.");
      });
  };

  const handleCloseClientForRegistration = () => {
    closeClientForRegistration()
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          // Client registration successful
          setClientData(response.data.client);
          alert("Client closed for registration.");
        } else {
          // Client registration unsuccessful
          alert("Client closing for registration unsuccessful.");
        }
      })
      .catch((error) => {
        console.error(error);
        // Client registration unsuccessful
        alert("Client could not be closed for registration.");
      });
  };

  const buttonDiabledForGroupButtons = (groupId) => {
    if (!profile) {
      return true;
    }
    if (profile.active_client_id === null) {
      return true;
    }
    if (profileRole !== "admin" && profileRole !== "manager") {
      return true;
    }
    const group = profile.groups.find(group => group.id == groupId);
    if (!group) {
      return true;
    }

    return false;
  };

  const handleImportDriveToGroup = (groupId) => {
    setSelectedGroupId(groupId);
    setShowImportDriveToGroupModal(true);
  };

  const handleCloseImportDriveToGroupModal = () => {
    if (importDriveToGroupResponse >= 399) {
      handleFetchError(importDriveToGroupResponse);
    }
    setShowImportDriveToGroupModal(false);
    setSelectedGroupId(null);
    fetchAllGroups();
    setSelectedGroupId(null);
  };

  const handleViewUsersInGroup = (groupId) => {
    setSelectedGroupId(groupId);
    setShowViewUsersInGroupModal(true);
  };

  const handleCloseViewUsersInGroupModal = () => {
    setShowViewUsersInGroupModal(false);
    setSelectedGroupId(null);
  };

  const handleManageUsersInGroup = (groupId) => {
    setSelectedGroupId(groupId);
    setShowManageUsersInGroupModal(true);
  };

  const handleCloseManageUsersInGroupModal = () => {
    setShowManageUsersInGroupModal(false);
    setSelectedGroupId(null);
  };

  if (isLoading) {
    return <div>
      <ThemeNavbar />
      Loading...
    </div>;
  }

  return (
    <div>
      {profile && !serverError && (
        <div>
          <ThemeNavbar />
          <div className="profile-section">
            <div>
              {profile.profile_picture && <img src={profile.profile_picture} alt="Profile" className="profile-picture" />}
            </div>
            <div className="profile-info">
              <div style={{ fontSize: isMobile ? "25px" : "50px", fontWeight: "bold" }}>Welcome, {profile.first_name} {profile.last_name}!</div>
              <div><b>Email:</b> {profile.email}</div>
              <div><b>Role:</b> {profileRole}</div>
              <div>
                <b>Groups: </b>
                {profile.groups && profile.groups.length > 0 ?
                  profile.groups.map((group, index) => (
                    <Badge key={index} pill bg="secondary" style={{ marginRight: "5px", }}>{group.name}</Badge>
                  ))
                  : "Not in any groups"
                }
              </div>
              <div>
                <b>Organizations: </b>
                {profile.clients && profile.clients.length > 0 ?
                  profile.clients.map((client, index) => (
                    <Badge key={index} pill bg={client.id === profile.active_client_id ? "primary" : "secondary"} style={{ marginRight: "5px", }}>{client.name}</Badge>
                  ))
                  : "Not in any organizations"
                }
              </div>
            </div>
          </div>
          {profileRole === "admin" && (
            <>
              <div style={{
                display: "flex", flexDirection: "row", alignItems: "center",
                justifyContent: "space-between", paddingLeft: "5px", paddingRight: "20px",
              }}>
                <h3>Organization Management</h3>
              </div>
              <div className="profile-info" style={{ paddingTop: "5px", marginBottom: "10px" }}>
                <div><b>Org Name:</b> {clientData.name}</div>
                <div><b>Org ID:</b> {clientData.id}</div>
                <div>
                  <b>Status:</b> <Badge pill bg={clientData.status === "active" ? "success" : clientData.status === "demo" ? "warning" : "danger"}>{clientData.status}</Badge>
                </div>
                <div><b>Registered At:</b> {new Date(clientData.registration_time).toLocaleString()}</div>
                {clientData.status === "demo" && (
                  <div style={{ color: "red", fontSize: "12px", paddingLeft: "5px" }}><b>Time Remaining:</b> {calculateRemainingTime(clientData.registration_time)}</div>
                )}
                <div>
                  <b>User Registration Code:</b> {clientData.registration_id}
                  <ThemeButton variant="outline-primary" style={{ marginLeft: "5px", fontSize: "12px" }}
                    onClick={() => copyToClipboard(clientData.registration_id)}>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <FaCopy />
                      Copy
                    </div>
                  </ThemeButton>
                  <ThemeButton variant="outline-primary" style={{ marginLeft: "5px", fontSize: "12px" }}
                    onClick={async () => {
                      const url = `${window.location.origin}/register?registerWithClient=${clientData.registration_id}`;
                      copyToClipboard(url);
                    }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <FaLink />
                      Share
                    </div>
                  </ThemeButton>
                  {copySuccess && <span style={{ color: "green", marginLeft: "10px" }}>Copied!</span>}
                </div>
                <div>
                  <b>Open For Registration:</b> {clientData.accept_registration ? "Yes" : "No"}
                  {clientData.accept_registration ?
                    <FaToggleOn style={{ marginLeft: "5px", color: "#50011a", fontSize: "21px", cursor: "pointer" }}
                      onClick={() => handleCloseClientForRegistration()} /> :
                    <FaToggleOff style={{ marginLeft: "5px", color: "#50011a", fontSize: "21px", cursor: "pointer" }}
                      onClick={() => handleOpenClientForRegistration()}
                    />}
                </div>
              </div>
            </>
          )}
          <div style={{
            display: "flex", flexDirection: "row", alignItems: "center",
            justifyContent: "space-between", paddingLeft: "5px", paddingRight: "20px"
          }}>
            <h3>Registered Users</h3>
            <ThemeButton variant="outline-primary"
              onClick={handleAddUser} disabled={profileRole !== "admin"}>
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <FaPlus />
                Register New User
              </div>
            </ThemeButton>
          </div>
          {usersData.length > 0 && (
            <div className="table-container" ref={usersTableRef}>
              {isMobile && (
                <FaAngleRight onClick={() => scrollTableRight(usersTableRef)}
                  style={{ fontSize: "15px", zIndex: "100", position: "absolute", color: "#fff", left: "90%", marginTop: "2px" }} />
              )}
              <Table striped bordered className="curved-table" style={{ minWidth: "450px" }}>
                <thead style={{ fontSize: isMobile ? "14px" : "20px", height: isMobile ? "40px" : "60px" }}>
                  <tr>
                    <th style={{ backgroundColor: "#50011a", color: "white", width: "20%" }}>Name</th>
                    <th style={{ backgroundColor: "#50011a", color: "white", width: "25%" }}>Email</th>
                    <th style={{ backgroundColor: "#50011a", color: "white", width: "40%" }}>Groups</th>
                    <th style={{ backgroundColor: "#50011a", color: "white", width: "15%" }}>Role</th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: isMobile ? "12px" : "18px", minWidth: "450px" }}>
                  {usersData.map((user, index) => (
                    <tr key={user.id} ref={index === usersData.length - 1 ? lastUserElementRef : null} style={{ height: editMode && selectedUserId === user.id ? "200px" : "40px" }}>
                      <td>
                        {user.email === profile.email ?
                          <span>{user.first_name} {user.last_name} <strong>(You)</strong></span> :
                          `${index + 1}. ${user.first_name} ${user.last_name}`
                        }
                      </td>
                      <td>{user.email}</td>
                      <td>
                        {profileRole !== "admin" && profileRole !== "manager" ? (
                          <>
                            {user.email === profile.email ? (
                              <>
                                {profile.groups.length > 0 ?
                                  profile.groups.map((group, index) => (
                                    <Badge key={index} pill bg="secondary" style={{ marginRight: "5px" }}>{group.name}</Badge>
                                  ))
                                  : "Not in any groups"
                                }
                              </>
                            ) : (
                              <p style={{ fontStyle: "italic", fontSize: "14px" }}>
                                Need admin level access to view groups of other users
                              </p>
                            )
                            }
                          </>
                        ) : (
                          <>
                            {user.email === profile.email ? (
                              <>
                                {profile.groups && profile.groups.length > 0 ?
                                  profile.groups.map((group, index) => (
                                    <Badge key={index} pill bg="secondary" style={{ marginRight: "5px", }}>{group.name}</Badge>
                                  ))
                                  : "Not in any groups"
                                }
                              </>
                            ) : (
                              <>
                                {user.groups.length > 0 ?
                                  user.groups.map((group, index) => (
                                    <Badge key={index} pill bg="secondary" style={{ marginRight: "5px" }}>{group.name}</Badge>
                                  ))
                                  : "Not in any groups"}
                              </>
                            )}
                          </>
                        )}
                      </td>
                      <td>
                        {editMode && selectedUserId === user.id ? (
                          <div style={{ display: "grid", gap: "10px", }}>
                            <Dropdown style={{ gridRow: 1, gridColumn: 1 }}>
                              <Dropdown.Toggle variant="outline-primary"
                                style={{
                                  "--bs-btn-color": "#50011a",
                                  "--bs-btn-border-color": "#50011a",
                                  "--bs-btn-hover-color": "#fff",
                                  "--bs-btn-hover-bg": "#50011a",
                                  "--bs-btn-hover-border-color": "#50011a",
                                  "--bs-btn-active-color": "#fff",
                                  "--bs-btn-active-bg": "#50011a",
                                  "--bs-btn-active-border-color": "#50011a",
                                  "--bs-btn-active-shadow": "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
                                  "--bs-btn-disabled-color": "#50011a",
                                  "--bs-btn-disabled-bg": "transparent",
                                  "--bs-btn-disabled-border-color": "#50011a",
                                  fontSize: isMobile ? "12px" : "18px"
                                }} >
                                {selectedRole}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {ROLES.map(role => (
                                  <Dropdown.Item key={role} onClick={() => setSelectedRole(role)}
                                    style={{ "--bs-dropdown-link-active-bg": "#50011a", }}>
                                    {role}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                            <div style={{ gridRow: 1, gridColumn: 2, display: "flex", justifyContent: "flex-end" }}>
                              <ThemeButton variant="outline-primary"
                                style={{ marginRight: "10px", fontSize: isMobile ? "8px" : "18px" }} onClick={handleSaveEdit}>
                                <FaCheck />
                              </ThemeButton>
                              <ThemeButton variant="outline-primary"
                                style={{ marginRight: "10px", fontSize: isMobile ? "8px" : "18px" }} onClick={handleCancelEdit}>
                                <FaXmark />
                              </ThemeButton>
                            </div>

                          </ div>
                        ) : (
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            {user.user_role}
                            <ThemeButton variant="outline-primary" style={{ marginRight: "15px", marginLeft: "5px", fontSize: isMobile ? "8px" : "18px" }}
                              onClick={() => handleEdit(user.id, user.user_role)}
                              disabled={user.email === profile.email || profileRole !== "admin"}>
                              <FaPen />
                            </ThemeButton>
                          </ div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
          {(profileRole === "admin" || profileRole === "manager") &&
            <>
              <div style={{
                display: "flex", flexDirection: "row", alignItems: "center",
                justifyContent: "space-between", paddingLeft: "5px", paddingRight: "20px"
              }}>
                <h3>Groups</h3>
                <ThemeButton
                  variant="outline-primary" onClick={handleAddGroup} disabled={profileRole !== "admin" && profileRole !== "manager"}>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <FaPlus />
                    Make New Group
                  </div>
                </ThemeButton>
              </div>
              <div className="table-container" ref={groupsTableRef}>
                {isMobile && (
                  <FaAngleRight onClick={() => scrollTableRight(groupsTableRef)}
                    style={{ fontSize: "15px", zIndex: "100", position: "absolute", color: "#fff", left: "90%", marginTop: "2px" }} />
                )}
                <Table striped bordered className="curved-table" style={{ minWidth: "450px" }}>
                  <thead style={{ fontSize: isMobile ? "12px" : "20px", height: isMobile ? "40px" : "60px" }}>
                    <tr>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "10%" }}>Group ID</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "15%" }}>Group Name</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "15%" }}># of Users</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "25%" }}>Drive</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "35%" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: isMobile ? "12px" : "18px" }}>
                    {groupsData && groupsData.map((group) => (
                      <tr key={group.id}>
                        <td>{group.id}</td>
                        <td>{group.name}</td>
                        <td>{group.numberOfUsers}</td>
                        <td>
                          {group.googleDriveId ? (
                            <div>
                              {!isMobile && group.googleDriveId}
                              <ThemeButton variant="outline-primary" style={{ marginLeft: "5px" }}
                                onClick={() => window.open(`https://drive.google.com/drive/u/0/folders/${group.googleDriveId}`)}>
                                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                  <FaUpRightFromSquare />
                                </div>
                              </ThemeButton>
                            </div>
                          ) : (
                            <div style={{ display: "flex", gap: "10px", flexDirection: "row" }}>
                              <ThemeButton variant="outline-primary"
                                disabled={buttonDiabledForGroupButtons(group.id)}
                                onClick={() => handleAssignDriveToGroup(group.id)}>
                                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                  <FaCloudArrowUp /> {isMobile ? <></> : <div style={{ whiteSpace: "nowrap" }}>Assign Shared Drive</div>}
                                </div>
                              </ThemeButton>
                              <ThemeButton variant="outline-primary"
                                disabled={buttonDiabledForGroupButtons(group.id)}
                                onClick={() => handleImportDriveToGroup(group.id)}>
                                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                  <FaCloudArrowDown /> {isMobile ? <></> : <div style={{ whiteSpace: "nowrap" }}>Import Shared Drive</div>}
                                </div>
                              </ThemeButton>
                            </div>
                          )}
                        </td>
                        <td>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <ThemeButton variant="outline-primary"
                              disabled={buttonDiabledForGroupButtons(group.id)}
                              onClick={() => handleViewUsersInGroup(group.id)}>
                              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <FaUsers /> {isMobile ? <></> : <div style={{ whiteSpace: "nowrap" }}>View Users</div>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              disabled={buttonDiabledForGroupButtons(group.id)}
                              onClick={() => handleManageUsersInGroup(group.id)}>
                              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <FaUsersGear /> {isMobile ? <></> : <div style={{ whiteSpace: "nowrap" }}>Manage Users</div>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              disabled={buttonDiabledForGroupButtons(group.id)}
                              onClick={() => handleAddUserToGroup(group.id)}>
                              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <FaUserPlus /> {isMobile ? <></> : <div style={{ whiteSpace: "nowrap" }}>Add Users</div>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              disabled={buttonDiabledForGroupButtons(group.id)}
                              onClick={() => handleRemoveUserFromGroup(group.id)}>
                              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <FaUserMinus /> {isMobile ? <></> : <div style={{ whiteSpace: "nowrap" }}>Remove Users</div>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              disabled={buttonDiabledForGroupButtons(group.id)}
                              onClick={() => handleRecalibrateDrive(group.id)}>
                              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <FaRotate /> {isMobile ? <></> : <div style={{ whiteSpace: "nowrap" }}>Recalibrate Drive</div>}
                              </div>
                            </ThemeButton>
                          </div>

                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          }
        </div>
      )}
      {
        serverError && serverError === "401 Unauthorized Access" && (
          <div className='site-container'>
            <header className="site-header">
              <div className="title-area">
                <h1 className="site-title">
                  <a href="https://birdshot.tamu.edu/">Birdshot Center</a>
                </h1>
              </div>
              <div className="widget-area header-widget-area">
                <a id="site-logo" href="https://birdshot.tamu.edu" style={{ display: "block", maxWidth: "350px" }}>
                  <img id="custom-header" src="https://birdshot.tamu.edu/wp-content/themes/coe-theme/images/logos/coe/logos-horizontal/ENGINEERING_logo_HWHT.png" alt="Texas A&M University College of Engineering" />
                </a>
              </div>
            </header>
            <body className='site-body'>
              <h2 className='body-title'>Automated Google Web Form Creator and Manager</h2>
              <p className='login-response'>401 Unauthorized Access</p>
              <p>Please <Link to="/" className='link'>Login</Link> to continue.</p>
            </body>
          </div>
        )}
      {
        serverError && serverError !== "401 Unauthorized Access" && (
          <div>
            <ThemeNavbar></ThemeNavbar>
            <div className='server-error-container'>
              {serverError === "403 Forbidden Access"
                ? (
                  <>
                    <p className='login-response'>
                      {serverError}
                    </p>
                    <p style={{ color: "#50011a" }}>
                      Cause of Error: {serverErrorMessage}
                    </p>
                  </>
                )
                : (
                  <>
                    <p className='login-response'>
                      {serverError}
                    </p>
                    <p style={{ color: "#50011a" }}>
                      Cause of Error: {serverErrorMessage}
                    </p>
                  </>
                )
              }
            </div>
          </div>
        )
      }
      <AddUserForm show={showAddUserModal} handleClose={handleCloseAddUserModal}
        setAddUserResponse={setAddUserResponse} />
      <AddGroupForm
        show={showAddGroupModal}
        handleClose={handleCloseAddGroupModal}
        setAddGroupResponse={setAddGroupResponse}
      />
      <div>
        <AddUsersToGroupModal
          show={showAddUsersToGroupModal}
          onHide={handleCloseAddUsersToGroupsModal}
          groupId={selectedGroupId}
          addUserToGroupsResponse={setAddUsersToGroupResponse} />
      </div>
      <div>
        <RemoveUsersFromGroupModal
          show={showRemoveUsersFromGroupModal}
          onHide={handleCloseRemoveUsersFromGroupsModal}
          groupId={selectedGroupId}
          removeUserFromGroupsResponse={setRemoveUsersFromGroupResponse} />
      </div>
      <AssignDriveToGroupModal
        show={showAssignDriveToGroupModal}
        onHide={handleCloseAssignDriveToGroupModal}
        groupId={selectedGroupId}
        setAssignDriveResponse={setAssignDriveToGroupResponse} />
      <ImportDriveToGroupModal
        show={showImportDriveToGroupModal}
        onHide={handleCloseImportDriveToGroupModal}
        groupId={selectedGroupId}
        setAssignDriveResponse={setImportDriveToGroupResponse} />
      <ViewUsersInGroupModal
        show={showViewUsersInGroupModal}
        onHide={handleCloseViewUsersInGroupModal}
        groupId={selectedGroupId} />
      <ManageUsersInGroupModal
        show={showManageUsersInGroupModal}
        onHide={handleCloseManageUsersInGroupModal}
        groupId={selectedGroupId} />
      <RecalibrateDriveModal
        show={showRecalibrateDriveModal}
        onHide={handleCloseRecalibrateDriveModal}
        groupId={selectedGroupId} />
    </div >
  );
}

export default ProfilePage;
