import React, { useState, useEffect } from "react";
import "./../styles/rootStyle.css";
import GoogleLoginButton from "./../components/googleLoginButton.jsx";
import { useNavigate } from "react-router-dom";
import { validateUser, initLogin, authorizeUserFromCookie } from "../apis/authorizationApis.js";
import LandingPageNavbar from "../components/landingPageNavbar.jsx";
import Footer from "../components/footer.jsx";
import PrivacyPolTermsAndCondModal from "../modals/privacyPolTermsAndCondModal.jsx";

/**
 * Renders the landing page component.
 *
 * @param {Object} setUser - A function to set the user state.
 * @return {JSX.Element} The rendered landing page component.
 */
function LandingPage({ setUser }) {
  const [isUserValidated, setIsUserValidated] = useState(false);
  const [serverError, setServerError] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [showPrivacyPolTermsAndCondModal, setShowPrivacyPolTermsAndCondModal] = useState(false);
  const [userForModal, setUserForModal] = useState(null);
  const [cookieForModal, setCookieForModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    checkActiveSession();
  }, []);

  const checkActiveSession = async () => {
    setIsAuthenticating(true);
    try {
      const response = await initLogin();
      if (response.status === 200) {
        setIsUserValidated(true);
        setServerError("");
        localStorage.setItem("user", JSON.stringify(response.data.userInfo));
        setUser(response.data.userInfo);
        navigate("/home");
      } else {
        if (response.status === 401) {
          setIsUserValidated(false);
          if (response.data.showTermsAndConditions || response.data.showPrivacyPolicy) {
            setServerError("401 Unauthorized.\n Server Error: " + response.data.message);
          }
          setUserForModal(response.data.user);
          console.log(response.data.showTermsAndConditions, response.data.showPrivacyPolicy);
          setShowPrivacyPolTermsAndCondModal(response.data.showTermsAndConditions || response.data.showPrivacyPolicy);
        }
        setIsAuthenticating(false);
      }
    } catch (error) {
      setIsAuthenticating(false);
      console.error("Error checking active session:", error);
    }
  };

  /**
     * Handles the Google login process by validating the user and fetching user information.
     *
     * @param {string} USER_GOOGLE_AUTH_CODE - The Google authirization code sent by the user.
     * @return {Promise<void>} - A promise that resolves when the login process is complete.
     */
  const handleGoogleLogin = async (USER_GOOGLE_AUTH_CODE) => {
    console.log(USER_GOOGLE_AUTH_CODE);
    const response = await validateUser(USER_GOOGLE_AUTH_CODE);
    console.log(response.status);
    switch (response.status) {
      case 200:
        setIsUserValidated(true);
        setServerError("");
        localStorage.setItem("user", JSON.stringify(response.data.userInfo));
        setUser(response.data.userInfo);
        navigate("/home");
        break;
      case 401:
        setIsUserValidated(false);
        if (response.data.showTermsAndConditions || response.data.showPrivacyPolicy) {
          setServerError("401 Unauthorized.\n Server Error: " + response.data.message);
        }
        setUserForModal(response.data.user);
        setCookieForModal(response.data.cookie);
        console.log(response.data.authCode);
        setShowPrivacyPolTermsAndCondModal(response.data.showTermsAndConditions || response.data.showPrivacyPolicy);
        break;
      case 403:
        setIsUserValidated(false);
        setServerError("403 Forbidden Access");
        break;
      case 404:
        setIsUserValidated(false);
        setServerError("404 Not Found");
        break;
      case 429:
        setIsUserValidated(false);
        setServerError("429 Too Many Requests");
        break;
      case 500:
        setIsUserValidated(false);
        setServerError("500 Internal Server Error");
        break;
      case 503:
        setIsUserValidated(false);
        setServerError("503 Service Unavailable");
        break;
      default:
        setIsUserValidated(false);
        setServerError("Unexpected Server Error");
    }
  };

  return (
    <div className='site-container'>
      <LandingPageNavbar />
      <body className='site-body' style={{ backgroundColor: "transparent" }}>
        {isAuthenticating ? (
          <div className="signing-in-modal">
            <p>Signing you in...</p>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "20px", marginBottom: "20px" }}>
            <div className="container py-4 bg-white rounded shadow-sm">
              <h2 className="display-4 mb-4">Datascribe Studio</h2>
              <p>
                This webapp is intended to aid in the creation of folder structures in Google Drive, as well as provide a framework for creating, sharing, and filling out forms with pertinent data.
              </p>
              <h4 className="mb-4" style={{ fontSize: "1.5rem" }}>Datascribe Information</h4>
              <p>
                At Datascribe.cloud, <b>Our vision</b> is to support researchers and small businesses in unlocking the full potential of their data through accessible and affordable cloud-based solutions. We strive to be the go-to platform for innovative data management and analytics, empowering our users to drive impactful discoveries and informed decisions. By focusing on simplicity, reliability, and exceptional support, we aim to foster a collaborative environment that accelerates research and growth.
              </p>
              <p>
                At Datascribe.cloud, <b>Our mission</b> is to accelerate research with innovative, scalable, and secure cloud-based data solutions. We aim to simplify data management, enhance analytics capabilities, and drive informed decision-making by providing intuitive, reliable, and cost-effective services tailored to meet the unique needs of reseachers.
              </p>
            </div>
            <h5 className='body-title' style={{ marginTop: "10px" }}>Sign In to Datascribe</h5>
            <GoogleLoginButton
              validateUserFunction={handleGoogleLogin}
            ></GoogleLoginButton>
            <div>
              new users start <a className="link" href="/register">here </a>
            </div>
            {isUserValidated === false && serverError && (
              <div className="server-error-container">
                <p className="login-response">{serverError}</p>
                {serverError === "403 Forbidden Access" && (
                  <>
                    <p style={{ color: "#50011a" }}>
                      Please first register your account with Datascribe.cloud
                    </p>
                    <p>
                      If you have already registered, and the issue persists.
                    </p>
                  </>
                )}
                <p>
                  Contact our development team at{" "}
                  <a href="mailto:divyanshu@tamu.edu">divyanshu@tamu.edu</a>, and{" "}
                  <a href="mailto:c2003@tamu.edu">c2003@tamu.edu</a>
                </p>
              </div>
            )}
          </div>
        )}
      </body>
      <Footer />
      <PrivacyPolTermsAndCondModal
        showModal={showPrivacyPolTermsAndCondModal}
        setShowModal={setShowPrivacyPolTermsAndCondModal}
        userEmail={userForModal}
        onClose={ async () => {
          if (cookieForModal) {
            const response = await authorizeUserFromCookie(cookieForModal);
            if (response.status !== 200) {
              console.error("Error authorizing user from cookie:", response);
              setServerError(response.status + " " + response.data.message);
              return;
            }
            window.location.reload();
          }
        }}
      />
    </div>
  );
}

export default LandingPage;
