import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Dropdown } from "react-bootstrap";
import { getFieldById, getAllFields, addField } from "../apis/formsApis";
import {
  FaRegSquarePlus, FaPen, FaCheck, FaXmark, FaTrash, FaAngleDown, FaAngleUp, FaPlus
} from "react-icons/fa6";
import "../styles/FieldViewStyles.css";
import ThemeButton from "../components/themeButton";

const AddFieldModal = ({ show, onHide, setViewFieldModal }) => {
  const [field, setField] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editTitleMode, setEditTitleMode] = useState(false);
  const [currentTitle, setCurrentTitle] = useState("");
  const [editQuestionType, setEditQuestionType] = useState(false);
  const [currentQuestionType, setCurrentQuestionType] = useState("");
  const [editQuestionRequired, setEditQuestionRequired] = useState(false);
  const [currentQuestionRequired, setCurrentQuestionRequired] = useState(false);
  const [choices, setChoices] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);
  const [editChoices, setEditChoices] = useState(false);
  const [editFileTypes, setEditFileTypes] = useState(false);
  const [editScaleRange, setEditScaleRange] = useState(false);
  const [currentMinNumber, setCurrentMinNumber] = useState(null);
  const [currentMaxNumber, setCurrentMaxNumber] = useState(null);
  const [showQuestionTypeSelection, setShowQuestionTypeSelection] = useState(true);
  const [availableFields, setAvailableFields] = useState([]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);


  useEffect(() => {
    if (show) {
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (userFromStorage) {
        setProfile(userFromStorage);
        setViewFieldModal(200);
        window.addEventListener("resize", handleResize);
      } else {
        setViewFieldModal(401);
        onHide();
      }
    }
  }, [show, onHide, setViewFieldModal]);

  const handleResize = () => {
    if (window.innerWidth < 750) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    if (show && profile) {
      fetchAvailableFields();
    }
  }, [show, profile]);

  const resetState = () => {
    setShowQuestionTypeSelection(true);
    setField(null);
    setProfile(null);
    setIsLoading(false);
  };

  const fetchAvailableFields = async () => {
    setIsLoading(true);
    const response = await getAllFields();
    if (response.status >= 200 && response.status < 300) {
      setAvailableFields(response.data);
    } else {
      setViewFieldModal(response.status);
      onHide();
    }
    setIsLoading(false);
  };

  const fetchField = async (fieldId) => {
    setIsLoading(true);
    const response = await getFieldById(fieldId);
    if (response.status >= 200 && response.status < 300) {
      const fieldData = response.data[0];
      setField(fieldData);
      setCurrentTitle(fieldData.title);
      setCurrentQuestionType(fieldData.type);
      setCurrentQuestionRequired(fieldData.structure.isRequired);
      setChoices(fieldData.structure.choices || []);
      setFileTypes(fieldData.structure.fileTypes || []);
      setCurrentMinNumber(fieldData.structure.minNumber || null);
      setCurrentMaxNumber(fieldData.structure.maxNumber || null);
    } else {
      console.log(" Error: ", response);
      setViewFieldModal(response.status);
      onHide();
    }
    setIsLoading(false);
  };

  const getQuestionTypeText = (questionType) => {
    switch (questionType) {
      case "shortAnswer":
        return "Short Answer";
      case "paragraph":
        return "Paragraph";
      case "checkboxes":
        return "Checkboxes";
      case "multipleChoice":
        return "Multiple Choice";
      case "dropdown":
        return "Dropdown";
      case "date":
        return "Date";
      case "time":
        return "Time";
      case "fileUpload":
        return "File Upload";
      case "scale":
        return "Scale";
      case "numeric":
        return "Numeric";
      default:
        return "Unknown";
    }
  };

  const handleTitleChange = (e) => {
    setCurrentTitle(e.target.value);
  };

  const handleEditTitleMode = () => {
    setEditTitleMode(!editTitleMode);
  };

  const handleConfirmTitleChange = () => {
    // Implement logic to update the title
    const updatedField = { ...field };
    updatedField.title = currentTitle;
    updatedField.structure.title = currentTitle;
    setField(updatedField);
    console.log("Field after update", field);
    setEditTitleMode(false);
  };

  const handleCancelTitleChange = () => {
    setCurrentTitle(field.title);
    setEditTitleMode(false);
  };

  const handleQuestionTypeChange = (questionType) => {
    setCurrentQuestionType(questionType);
  };

  const handleEditQuestionType = () => {
    setEditQuestionType(!editQuestionType);
  };

  const handleConfirmQuestionTypeChange = () => {
    // Implement logic to update the question type
    const updatedField = { ...field };
    updatedField.type = currentQuestionType;
    updatedField.structure.type = currentQuestionType;

    // Remove choices or fileTypes based on the new question type
    if (currentQuestionType === "shortAnswer" || currentQuestionType === "paragraph") {
      delete updatedField.structure.choices;
      delete updatedField.structure.fileTypes;
    } else if (currentQuestionType === "checkboxes" || currentQuestionType === "multipleChoice" ||
      currentQuestionType === "dropdown" || currentQuestionType === "scale") {
      if (!updatedField.structure.choices) {
        updatedField.structure.choices = [];
      }
      delete updatedField.structure.fileTypes;
    } else if (currentQuestionType === "fileUpload") {
      if (!updatedField.structure.fileTypes) {
        updatedField.structure.fileTypes = [];
      }
      delete updatedField.structure.choices;
    }

    setField(updatedField);
    console.log("Field after update", field);
    setEditQuestionType(false);
  };

  const handleCancelQuestionTypeChange = () => {
    setCurrentQuestionType(field.type);
    setEditQuestionType(false);
  };

  const handleQuestionRequiredChange = (isRequired) => {
    setCurrentQuestionRequired(isRequired);
  };

  const handleEditQuestionRequired = () => {
    setEditQuestionRequired(!editQuestionRequired);
  };

  const handleConfirmQuestionRequiredChange = () => {
    const updatedField = { ...field };
    updatedField.structure.isRequired = currentQuestionRequired;
    setField(updatedField);
    console.log("Field after update", updatedField);
    setEditQuestionRequired(false);
  };

  const handleCancelQuestionRequiredChange = () => {
    setCurrentQuestionRequired(field.isRequired);
    setEditQuestionRequired(false);
  };

  const handleEditChoices = () => {
    setEditChoices(!editChoices);
  };

  const handleChoiceChange = (value, index) => {
    const updatedChoices = [...choices];
    updatedChoices[index] = value;
    setChoices(updatedChoices);
  };

  const handleDeleteChoice = (index) => {
    const updatedChoices = [...choices];
    updatedChoices.splice(index, 1);
    setChoices(updatedChoices);
  };

  const handleMoveUpChoice = (index) => {
    if (index > 0) {
      const updatedChoices = [...choices];
      const temp = updatedChoices[index];
      updatedChoices[index] = updatedChoices[index - 1];
      updatedChoices[index - 1] = temp;
      setChoices(updatedChoices);
    }
  };

  const handleMoveDownChoice = (index) => {
    if (index < choices.length - 1) {
      const updatedChoices = [...choices];
      const temp = updatedChoices[index];
      updatedChoices[index] = updatedChoices[index + 1];
      updatedChoices[index + 1] = temp;
      setChoices(updatedChoices);
    }
  };

  const handleAddChoice = () => {
    const updatedChoices = [...choices, ""];
    setChoices(updatedChoices);
  };

  const handleConfirmChoicesChange = () => {
    const updatedField = { ...field };
    updatedField.structure.choices = choices;
    setField(updatedField);
    setChoices(field.structure.choices);
    setEditChoices(false);
  };

  const handleCancelChoicesChange = () => {
    setChoices(field.structure.choices);
    setEditChoices(false);
  };

  const handleEditFileTypes = () => {
    setEditFileTypes(!editFileTypes);
  };

  const handleFileTypeChange = (value, index) => {
    const updatedFileTypes = [...fileTypes];
    updatedFileTypes[index] = value;
    setFileTypes(updatedFileTypes);
  };

  const handleDeleteFileType = (index) => {
    const updatedFileTypes = [...fileTypes];
    updatedFileTypes.splice(index, 1);
    setFileTypes(updatedFileTypes);
  };

  const handleAddFileType = () => {
    const updatedFileTypes = [...fileTypes, ""];
    setFileTypes(updatedFileTypes);
  };

  const handleConfirmFileTypesChange = () => {
    const invalidFileTypes = fileTypes.filter(fileType => !isValidFileType(fileType));
    if (invalidFileTypes.length > 0) {
      alert(`Invalid file types: ${invalidFileTypes.join(", ")}`);
      return;
    }

    const updatedField = { ...field };
    updatedField.structure.fileTypes = fileTypes;
    setField(updatedField);
    setFileTypes(field.structure.fileTypes);
    setEditFileTypes(false);
  };

  const handleCancelFileTypesChange = () => {
    setFileTypes(field.structure.fileTypes);
    setEditFileTypes(false);
  };

  const handleBaseQuestionTypeSelection = (type) => {
    setShowQuestionTypeSelection(false);
    const defaultField = {
      title: "",
      type: type,
      structure: {
        title: "",
        type: type,
        isRequired: false,
        ...(type === "checkboxes" || type === "multipleChoice" || type === "dropdown" ? { choices: [] } : {}),
        ...(type === "fileUpload" ? { fileTypes: [] } : {}),
        ...(type === "scale" ? { minNumber: null, maxNumber: null } : {}),
      },
    };

    setField(defaultField);
    setCurrentTitle(defaultField.title);
    setCurrentQuestionType(defaultField.type);
    setCurrentQuestionRequired(defaultField.structure.isRequired);
    setChoices(defaultField.structure.choices || []);
    setFileTypes(defaultField.structure.fileTypes || []);
    setCurrentMinNumber(defaultField.structure.minNumber || null);
    setCurrentMaxNumber(defaultField.structure.maxNumber || null);
  };

  const handleBaseFieldSelection = (field) => {
    setShowQuestionTypeSelection(false);
    fetchField(field.id);
  };

  const isValidFileType = (fileType) => {
    const validExtensions = [
      ".pdf", ".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx", ".jpg", ".jpeg", ".png", ".gif",
      ".bmp", ".tiff", ".mp4", ".avi", ".mov", ".mkv", ".zip", ".rar", ".7z", ".tar", ".gz"
    ];
    return validExtensions.includes(fileType.toLowerCase());
  };

  const handleEditScaleRange = () => {
    setEditScaleRange(!editScaleRange);
    setCurrentMinNumber(field.structure.minNumber);
    setCurrentMaxNumber(field.structure.maxNumber);
  };

  const handleConfirmScaleRangeChange = () => {
    const updatedField = { ...field };
    updatedField.structure.minNumber = currentMinNumber;
    updatedField.structure.maxNumber = currentMaxNumber;
    setField(updatedField);
    setEditScaleRange(false);
  };

  const handleCancelScaleRangeChange = () => {
    setCurrentMinNumber(field.structure.minNumber);
    setCurrentMaxNumber(field.structure.maxNumber);
    setEditScaleRange(false);
  };

  const handleAddField = async () => {
    if (!field.title) {
      alert("Error: Title cannot be empty.");
      return;
    }
    if (field.type === "uploadFile" && fileTypes.length === 0) {
      alert("Error: File types cannot be empty if question type is uploadFile.");
      return;
    }
    if (["multipleChoice", "dropdown", "checkboxes"].includes(field.type) && choices.length === 0) {
      alert("Error: Choices cannot be empty if question type is multiple choice, dropdown, or checkbox.");
      return;
    }
    if (field.type === "scale" && (currentMinNumber === null || currentMaxNumber === null)) {
      alert("Error: Scale range cannot be empty if question type is scale.");
      return;
    }
    if (field.type === "scale" && currentMinNumber > currentMaxNumber) {
      alert("Error: Scale range minimum cannot be greater than maximum.");
      return;
    }
    const response = await addField(field);
    switch (response.status) {
      case 200:
        alert("Field added successfully.");
        resetState();
        onHide();
        break;
      case 400:
        alert("Error: Bad request. Please check your input and try again.\n Error: " + response.data.message);
        onHide();
        break;
      case 401:
        alert("Error: Unauthorized. Please log in again.\n Error: " + response.data.message);
        onHide();
        break;
      case 403:
        alert("Error: Forbidden. You do not have the necessary permissions to perform this action.\n Error: " + response.data.message);
        onHide();
        break;
      case 409:
        alert("Error: A field with the same ID already exists. Please try a different name.\n Error: " + response.data.message);
        onHide();
        break;
      default:
        alert("Error: Unexpected error occurred. Please try again later.");
        onHide();
        break;
    }
  };

  return (
    <Modal show={show} onHide={() => {
      resetState();
      onHide();
    }} centered dialogClassName="modal-dialog-centered">
      <Modal.Header closeButton>
        <Modal.Title>Field Creator</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{
        maxHeight: "650px", overflowY: "auto", maxWidth: "800px", overflowX: "auto", minHeight: "400px",
      }}>

        {showQuestionTypeSelection ? (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <h5 style={{ fontSize: isMobile ? "14px" : "" }}>How would you like to start?</h5>
            <Dropdown >
              <Dropdown.Toggle
                variant="outline-primary" id="dropdown-basic"
                style={{
                  "--bs-btn-color": "#50011a",
                  "--bs-btn-border-color": "#50011a",
                  "--bs-btn-hover-color": "#fff",
                  "--bs-btn-hover-bg": "#50011a",
                  "--bs-btn-hover-border-color": "#50011a",
                  "--bs-btn-focus-shadow-rgb": "33,37,41",
                  "--bs-btn-active-color": "#fff",
                  "--bs-btn-active-bg": "#50011a",
                  "--bs-btn-active-border-color": "#50011a",
                  marginLeft: "10px",
                  fontSize: isMobile ? "12px" : "inherit",
                }}>
                Select a Question Type
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ fontSize: isMobile ? "12px" : "inherit" }}>
                <Dropdown.Item onClick={() => handleBaseQuestionTypeSelection("shortAnswer")}>Short Answer</Dropdown.Item>
                <Dropdown.Item onClick={() => handleBaseQuestionTypeSelection("paragraph")}>Paragraph</Dropdown.Item>
                <Dropdown.Item onClick={() => handleBaseQuestionTypeSelection("checkboxes")}>Checkboxes</Dropdown.Item>
                <Dropdown.Item onClick={() => handleBaseQuestionTypeSelection("multipleChoice")}>Multiple Choice</Dropdown.Item>
                <Dropdown.Item onClick={() => handleBaseQuestionTypeSelection("dropdown")}>Dropdown</Dropdown.Item>
                <Dropdown.Item onClick={() => handleBaseQuestionTypeSelection("date")}>Date</Dropdown.Item>
                <Dropdown.Item onClick={() => handleBaseQuestionTypeSelection("time")}>Time</Dropdown.Item>
                <Dropdown.Item onClick={() => handleBaseQuestionTypeSelection("fileUpload")}>File Upload</Dropdown.Item>
                <Dropdown.Item onClick={() => handleBaseQuestionTypeSelection("scale")}>Scale</Dropdown.Item>
                <Dropdown.Item onClick={() => handleBaseQuestionTypeSelection("numeric")}>Numeric</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle variant="outline-primary" align={{ lg: "end" }}
                style={{
                  "--bs-btn-color": "#50011a",
                  "--bs-btn-border-color": "#50011a",
                  "--bs-btn-hover-color": "#fff",
                  "--bs-btn-hover-bg": "#50011a",
                  "--bs-btn-hover-border-color": "#50011a",
                  "--bs-btn-active-color": "#fff",
                  "--bs-btn-active-bg": "#50011a",
                  "--bs-btn-active-border-color": "#50011a",
                  "--bs-btn-active-shadow": "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
                  "--bs-btn-disabled-color": "#50011a",
                  "--bs-btn-disabled-bg": "transparent",
                  "--bs-btn-disabled-border-color": "#50011a",
                  marginTop: "10px",
                  fontSize: isMobile ? "12px" : "inherit",
                }}>
                Base it off a template
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ fontSize: isMobile ? "12px" : "inherit" }}>
                {availableFields.map((template) => (
                  <Dropdown.Item key={template.id} onClick={() => handleBaseFieldSelection(template)}
                    style={{ "--bs-dropdown-link-active-bg": "#50011a", }}>
                    {template.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

          </div>
        ) : (
          <>
            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span className="sr-only">Loading...</span>
              </div>
            ) : field ? (
              <>
                <div className="field-attribute-container">
                  <strong style={{ fontSize: isMobile ? "14px" : "20px", textWrap: "nowrap" }}>Question Title:</strong>{" "}
                  {editTitleMode ? (
                    <Form.Control type="text" value={currentTitle} onChange={handleTitleChange}
                      style={{ display: "inline-block", width: "auto", fontSize: isMobile ? "14px" : "" }} />
                  ) : (
                    <span style={{ fontSize: isMobile ? "14px" : "18px", maxWidth: "100%", paddingLeft: "10px", textWrap: "nowrap", overflow: "auto" }} title={field.title}>
                      {field.title}
                    </span>
                  )}
                  {editTitleMode ? (
                    <>
                      <Button
                        variant="outline-success"
                        style={{
                          marginLeft: "10px",
                          padding: "2px 8px",
                          fontSize: "14px",
                        }} onClick={handleConfirmTitleChange}>
                        <FaCheck />
                      </Button>
                      <Button
                        variant="outline-danger"
                        style={{
                          marginLeft: "5px",
                          padding: "2px 8px",
                          fontSize: "14px",
                        }} onClick={handleCancelTitleChange}>
                        <FaXmark />
                      </Button>
                    </>
                  ) : (
                    <ThemeButton variant="outline-primary" style={{
                      marginLeft: "10px",
                      padding: "2px 8px",
                      fontSize: isMobile ? "12px" : "14px",
                    }} onClick={handleEditTitleMode}>
                      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <FaPen /> {isMobile ? "" : "Edit"}
                      </div>
                    </ThemeButton>
                  )}
                </div>
                <div className="field-attribute-container">
                  <strong style={{ fontSize: isMobile ? "14px" : "20px" }}>Question Type:</strong>{" "}
                  {editQuestionType ? (
                    <Dropdown >
                      <Dropdown.Toggle
                        variant="outline-primary"
                        id="dropdown-basic"
                        style={{
                          "--bs-btn-color": "#50011a",
                          "--bs-btn-border-color": "#50011a",
                          "--bs-btn-hover-color": "#fff",
                          "--bs-btn-hover-bg": "#50011a",
                          "--bs-btn-hover-border-color": "#50011a",
                          "--bs-btn-focus-shadow-rgb": "33,37,41",
                          "--bs-btn-active-color": "#fff",
                          "--bs-btn-active-bg": "#50011a",
                          "--bs-btn-active-border-color": "#50011a",
                          marginLeft: "10px",
                          fontSize: isMobile ? "12px" : "inherit",
                        }}>
                        {getQuestionTypeText(currentQuestionType)}
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ fontSize: isMobile ? "12px" : "inherit" }}>
                        <Dropdown.Item onClick={() => handleQuestionTypeChange("shortAnswer")}>Short Answer</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleQuestionTypeChange("paragraph")}>Paragraph</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleQuestionTypeChange("checkboxes")}>Checkboxes</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleQuestionTypeChange("multipleChoice")}>Multiple Choice</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleQuestionTypeChange("dropdown")}>Dropdown</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleQuestionTypeChange("date")}>Date</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleQuestionTypeChange("time")}>Time</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleQuestionTypeChange("fileUpload")}>File Upload</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleQuestionTypeChange("scale")}>Scale</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleQuestionTypeChange("numeric")}>Numeric</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <span style={{ fontSize: isMobile ? "14px" : "18px", paddingLeft: "10px" }} title={getQuestionTypeText(field.type)}>
                      {getQuestionTypeText(field.type)}
                    </span>
                  )}
                  {editQuestionType ? (
                    <>
                      <Button variant="outline-success"
                        style={{
                          marginLeft: "10px",
                          padding: "2px 8px",
                          fontSize: "14px",
                        }} onClick={handleConfirmQuestionTypeChange}>
                        <FaCheck />
                      </Button>
                      <Button variant="outline-danger"
                        style={{
                          marginLeft: "5px",
                          padding: "2px 8px",
                          fontSize: "14px",
                        }} onClick={handleCancelQuestionTypeChange}>
                        <FaXmark />
                      </Button>
                    </>
                  ) : (
                    <ThemeButton variant="outline-primary" style={{
                      marginLeft: "10px",
                      padding: "2px 8px",
                      fontSize: isMobile ? "12px" : "14px",
                    }} onClick={handleEditQuestionType}>
                      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <FaPen /> {isMobile ? "" : "Edit"}
                      </div>
                    </ThemeButton>
                  )}
                </div>
                <div className="field-attribute-container">
                  <strong style={{ fontSize: isMobile ? "14px" : "20px" }}>Question Required:</strong>{" "}
                  {editQuestionRequired ? (
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="outline-primary"
                        id="dropdown-basic"
                        style={{
                          "--bs-btn-color": "#50011a",
                          "--bs-btn-border-color": "#50011a",
                          "--bs-btn-hover-color": "#fff",
                          "--bs-btn-hover-bg": "#50011a",
                          "--bs-btn-hover-border-color": "#50011a",
                          "--bs-btn-focus-shadow-rgb": "33,37,41",
                          "--bs-btn-active-color": "#fff",
                          "--bs-btn-active-bg": "#50011a",
                          "--bs-btn-active-border-color": "#50011a",
                          marginLeft: "10px",
                          padding: "2px 8px",
                          fontSize: isMobile ? "12px" : "14px",
                        }}>
                        {currentQuestionRequired ? "Yes" : "No"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ fontSize: isMobile ? "12px" : "inherit" }}>
                        <Dropdown.Item onClick={() => handleQuestionRequiredChange(true)}>
                          Yes
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleQuestionRequiredChange(false)}>
                          No
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <span style={{ fontSize: isMobile ? "14px" : "18px", paddingLeft: "10px" }}>
                      {field.structure.isRequired ? "Yes" : "No"}
                    </span>
                  )}
                  {editQuestionRequired ? (
                    <>
                      <Button variant="outline-success"
                        style={{
                          marginLeft: "10px",
                          padding: "2px 8px",
                          fontSize: "14px",
                        }} onClick={handleConfirmQuestionRequiredChange}>
                        <FaCheck />
                      </Button>
                      <Button variant="outline-danger"
                        style={{
                          marginLeft: "5px",
                          padding: "2px 8px",
                          fontSize: "14px",
                        }} onClick={handleCancelQuestionRequiredChange}>
                        <FaXmark />
                      </Button>
                    </>
                  ) : (
                    <ThemeButton variant="outline-primary" style={{
                      marginLeft: "10px",
                      padding: "2px 8px",
                      fontSize: isMobile ? "12px" : "14px",
                    }} onClick={handleEditQuestionRequired}>
                      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <FaPen /> {isMobile ? "" : "Edit"}
                      </div>
                    </ThemeButton>
                  )}
                </div>
                {(field.type === "checkboxes" || field.type === "multipleChoice" || field.type === "dropdown") &&
                  (
                    <div className="field-attribute-container" style={{ display: "block" }}>
                      <strong style={{ fontSize: isMobile ? "14px" : "20px" }}>Choices:</strong>
                      {editChoices ? (
                        <div>
                          {choices.map((choice, index) => (
                            <div key={index} className="field-list-item-container">
                              <input type="text" value={choice}
                                style={{ fontSize: isMobile ? "12px" : "" }}
                                onChange={(e) => handleChoiceChange(e.target.value, index)} />
                              <ThemeButton variant="outline-primary" style={{
                                marginLeft: "10px",
                                padding: isMobile ? "1px 4px" : "2px 8px",
                                fontSize: isMobile ? "12px" : "14px"
                              }} onClick={() => handleDeleteChoice(index)} className="delete-choice">
                                <FaTrash />
                              </ThemeButton>
                              <ThemeButton variant="outline-primary" style={{
                                marginLeft: "10px",
                                padding: isMobile ? "1px 4px" : "2px 8px",
                                fontSize: isMobile ? "12px" : "14px"
                              }} onClick={() => handleMoveUpChoice(index)} disabled={index === 0}>
                                <FaAngleUp />
                              </ThemeButton>
                              <ThemeButton variant="outline-primary" style={{
                                marginLeft: "10px",
                                padding: isMobile ? "1px 4px" : "2px 8px",
                                fontSize: isMobile ? "12px" : "14px"
                              }} onClick={() => handleMoveDownChoice(index)} disabled={index === field.structure.choices.length - 1}>
                                <FaAngleDown />
                              </ThemeButton>
                            </div>
                          ))}

                          <ThemeButton variant="outline-primary" style={{
                            marginLeft: "10px",
                            padding: "2px 8px",
                            fontSize: "14px"
                          }} onClick={handleAddChoice}>
                            <FaPlus /> Add Choice
                          </ThemeButton>
                          <ThemeButton variant="outline-primary" style={{
                            marginLeft: "10px",
                            padding: "2px 8px",
                            fontSize: "14px"
                          }} onClick={handleConfirmChoicesChange}>
                            <FaCheck /> Confirm
                          </ThemeButton>
                          <ThemeButton variant="outline-primary" style={{
                            marginLeft: "10px",
                            padding: "2px 8px",
                            fontSize: "14px"
                          }} onClick={handleCancelChoicesChange}>
                            <FaXmark /> Cancel
                          </ThemeButton>
                        </div>
                      ) : (
                        <>
                          <ThemeButton variant="outline-primary" style={{
                            marginLeft: "10px",
                            padding: "2px 8px",
                            fontSize: isMobile ? "12px" : "14px"
                          }} onClick={handleEditChoices}>
                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                              <FaPen /> {isMobile ? "" : "Edit"}
                            </div>
                          </ThemeButton>
                          <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                            {field.structure.choices.map((choice, index) => (
                              <div key={index} className="field-list-item-container">
                                {" > "}
                                <span style={{ fontSize: isMobile ? "10px" : "" }}>
                                  {choice}
                                </span>
                              </div>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                  )}
                {field.type === "fileUpload" &&
                  (
                    <div className="field-attribute-container" style={{ display: "block" }}>
                      <strong style={{ fontSize: isMobile ? "14px" : "20px" }}>File Types Allowed:</strong>
                      {editFileTypes ? (
                        <div>
                          {fileTypes.map((fileType, index) => (
                            <div key={index} className="field-list-item-container">
                              <input type="text" value={fileType}
                                onChange={(e) => handleFileTypeChange(e.target.value, index)}
                                style={{ width: "100px", fontSize: isMobile ? "12px" : "" }}
                              />
                              <ThemeButton variant="outline-primary" style={{
                                marginLeft: "10px",
                                padding: "2px 8px",
                                fontSize: isMobile ? "12px" : "14px"
                              }} onClick={() => handleDeleteFileType(index)}>
                                <FaTrash />
                              </ThemeButton>
                            </div>
                          ))}

                          <ThemeButton variant="outline-primary" style={{
                            marginLeft: "10px",
                            padding: "2px 8px",
                            fontSize: "14px"
                          }} onClick={handleAddFileType} >
                            <FaPlus /> Add File Type
                          </ThemeButton>
                          <ThemeButton variant="outline-success" style={{
                            marginLeft: "10px",
                            padding: "2px 8px",
                            fontSize: "14px",
                          }} onClick={handleConfirmFileTypesChange}>
                            <FaCheck />
                          </ThemeButton>
                          <ThemeButton variant="outline-danger" style={{
                            marginLeft: "5px",
                            padding: "2px 8px",
                            fontSize: "14px",
                          }} onClick={handleCancelFileTypesChange}>
                            <FaXmark />
                          </ThemeButton>
                        </div>
                      ) : (
                        <>
                          <ThemeButton variant="outline-primary" style={{
                            marginLeft: "10px",
                            padding: "2px 8px",
                            fontSize: isMobile ? "12px" : "14px"
                          }} onClick={handleEditFileTypes}>
                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                              <FaPen /> {isMobile ? "" : "Edit"}
                            </div>
                          </ThemeButton>
                          <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                            {field.structure.fileTypes.map((fileType, index) => (
                              <div key={index} className="field-list-item-container">
                                {" > "}
                                <span style={{ fontSize: isMobile ? "10px" : "" }}>
                                  {fileType}
                                </span>
                              </div>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                  )}
                {field.type === "scale" && (
                  <div className="field-attribute-container" style={{ display: "block" }}>
                    <strong style={{ fontSize: isMobile ? "14px" : "20px" }}>Range: </strong>
                    {editScaleRange ? (
                      <div>
                        <Form.Group className="mb-3">
                          <Form.Label style={{ fontSize: isMobile ? "10px" : "" }}>Minimum:</Form.Label>
                          <Form.Control
                            type="number"
                            value={currentMinNumber}
                            onChange={(e) => setCurrentMinNumber(parseInt(e.target.value))}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label style={{ fontSize: isMobile ? "10px" : "" }}>Maximum:</Form.Label>
                          <Form.Control
                            type="number"
                            value={currentMaxNumber}
                            onChange={(e) => setCurrentMaxNumber(parseInt(e.target.value))}
                          />
                        </Form.Group>
                        <Button
                          variant="outline-success"
                          style={{
                            marginRight: "5px",
                            padding: "2px 8px",
                            fontSize: "14px",
                          }}
                          onClick={handleConfirmScaleRangeChange}
                        >
                          <FaCheck />
                        </Button>
                        <Button
                          variant="outline-danger"
                          style={{
                            padding: "2px 8px",
                            fontSize: "14px",
                          }}
                          onClick={handleCancelScaleRangeChange}
                        >
                          <FaXmark />
                        </Button>
                      </div>

                    ) : (
                      <>
                        <ThemeButton variant="outline-primary" style={{
                          marginLeft: "10px",
                          padding: "2px 8px",
                          fontSize: isMobile ? "12px" : "14px"
                        }} onClick={handleEditScaleRange}>
                          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <FaPen /> {isMobile ? "" : "Edit"}
                          </div>
                        </ThemeButton>
                        <div className="field-attribute-container">
                          <span style={{ fontSize: isMobile ? "10px" : "" }}><strong>Minimum:</strong> {field.structure.minNumber}</span>
                        </div>
                        <div className="field-attribute-container">
                          <span style={{ fontSize: isMobile ? "10px" : "" }}><strong>Maximum:</strong> {field.structure.maxNumber}</span>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </>
            ) : (
              <p>No field data available.</p>
            )}
          </>
        )}
      </Modal.Body>

      {field && (
        <Modal.Footer>
          <ThemeButton variant="outline-primary" onClick={() => { handleAddField(); }}>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <FaRegSquarePlus />
              Save Field
            </div>
          </ThemeButton>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default AddFieldModal;