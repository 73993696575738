import React, { useState, useEffect } from "react";
import { Modal, Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaCircleInfo } from "react-icons/fa6";
import { createDemoClient } from "../apis/registerationApis";

const AddClientForm = ({ show, handleClose, setAddClientResponse }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [clientName, setClientName] = useState("");
  const [profile, setProfile] = useState(null);

  const infoTooltip = (
    <Tooltip>
      Name will be updated by the backend to match with names associated with user&apos;s email id.
    </Tooltip>
  );

  useEffect(() => {
    if (show) {
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (userFromStorage) {
        setProfile(userFromStorage);
      } else {
        setAddClientResponse(401);
        handleClose();
      }
    }
  }, [show]);

  const handleCommonErrors = (response) => {
    switch (response.status) {
      case 400:
        return { status: response.status, error: "Bad Request\nInvalid Fields Provided or Client Already Exists" };
      case 401:
        return { status: response.status, error: "Unauthorized Access" };
      case 403:
        return { status: response.status, error: "Forbidden Access. Signed as " + profile.email };
      case 404:
        return { status: response.status, error: "Not Found" };
      case 429:
        return { status: response.status, error: "Too Many Requests" };
      case 500:
        return { status: response.status, error: "Internal Server Error" };
      case 503:
        return { status: response.status, error: "Service Unavailable" };
      default:
        return { status: response.status, error: "Unknown Error" };
    }
  };

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleFirstNameChange = (e) => setFirstName(e.target.value);
  const handleLastNameChange = (e) => setLastName(e.target.value);
  const handleClientNameChange = (e) => setClientName(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await createDemoClient(email, firstName, lastName, clientName);
    if (response.status === 200) {
      setAddClientResponse(200);
      alert("Client added successfully");
      handleClose();
      setEmail("");
      setFirstName("");
      setLastName("");
      setClientName("");
    } else {
      const errorData = handleCommonErrors(response);
      if (errorData.error === 401) {
        setAddClientResponse(401);
        handleClose();
      }
      setAddClientResponse(errorData.error);
      alert(`Failed to add client. Please try again later.\nServer Response: ${response.data.message}`);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Client</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" placeholder="Enter email" value={email} onChange={handleEmailChange} required />
          </Form.Group>
          <Form.Group controlId="formFirstName">
            <OverlayTrigger placement="right" overlay={infoTooltip}>
              <Form.Label>
                <span>First Name</span>
                <FaCircleInfo style={{ marginLeft: "5px", cursor: "pointer" }} />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control type="text" placeholder="Enter first name" value={firstName} onChange={handleFirstNameChange} required />
          </Form.Group>
          <Form.Group controlId="formLastName">
            <OverlayTrigger placement="right" overlay={infoTooltip}>
              <Form.Label>
                <span>Last Name</span>
                <FaCircleInfo style={{ marginLeft: "5px", cursor: "pointer" }} />
              </Form.Label>
            </OverlayTrigger>
            <Form.Control type="text" placeholder="Enter last name" value={lastName} onChange={handleLastNameChange} required />
          </Form.Group>
          <Form.Group controlId="formClientName">
            <Form.Label>Client Name</Form.Label>
            <Form.Control type="text" placeholder="Enter client name" value={clientName} onChange={handleClientNameChange} required />
          </Form.Group>
          <Button variant="primary" type="submit" style={{
            marginTop: "10px",
            "--bs-btn-color": "#fff",
            "--bs-btn-bg": "#50011a",
            "--bs-btn-border-color": "#50011a",
            "--bs-btn-hover-color": "#fff",
            "--bs-btn-hover-bg": "#960231",
            "--bs-btn-hover-border-color": "#0a2e50",
            "--bs-btn-focus-shadow-rgb": "80, 1, 26",
            "--bs-btn-active-color": "#fff",
            "--bs-btn-active-bg": "#0a2e50",
            "--bs-btn-active-border-color": "#0a2d5a",
            "--bs-btn-active-shadow": "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
            "--bs-btn-disabled-color": "#fff",
            "--bs-btn-disabled-bg": "#50011a",
            "--bs-btn-disabled-border-color": "#50011a",
          }}>
            Add Client
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddClientForm;