import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { getFieldById } from "../apis/formsApis";
import "../styles/FieldViewStyles.css";

const ViewFieldModal = ({ show, onHide, setViewFieldModal, fieldId }) => {
  const [field, setField] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);

  useEffect(() => {
    if (show) {
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (userFromStorage) {
        setProfile(userFromStorage);
        setViewFieldModal(200);
        window.addEventListener("resize", handleResize);
      } else {
        setViewFieldModal(401);
        onHide();
      }
    }
  }, [show, onHide, setViewFieldModal]);

  useEffect(() => {
    if (show && profile) {
      fetchField();
    }
  }, [show, profile]);

  const handleResize = () => {
    if (window.innerWidth < 750) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const resetState = () => {
    setField(null);
    setProfile(null);
    setIsLoading(false);
  };

  const fetchField = async () => {
    setIsLoading(true);
    const response = await getFieldById(fieldId);
    if (response.status >= 200 && response.status < 300) {
      const fieldData = response.data[0];
      setField(fieldData);
    } else {
      console.log(" Error: ", response);
      setViewFieldModal(response.status);
      onHide();
    }
    setIsLoading(false);
  };

  const getQuestionTypeText = (questionType) => {
    switch (questionType) {
      case "shortAnswer":
        return "Short Answer";
      case "paragraph":
        return "Paragraph";
      case "checkboxes":
        return "Checkboxes";
      case "multipleChoice":
        return "Multiple Choice";
      case "dropdown":
        return "Dropdown";
      case "date":
        return "Date";
      case "time":
        return "Time";
      case "fileUpload":
        return "File Upload";
      case "scale":
        return "Scale";
      case "numeric":
        return "Numeric";
      default:
        return "Unknown";
    }
  };

  return (
    <Modal show={show} onHide={() => {
      resetState();
      onHide();
    }} centered dialogClassName="modal-dialog-centered">
      <Modal.Header closeButton>
        <Modal.Title>Field Viewer</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "650px", overflowY: "auto", maxWidth: "800px", overflowX: "auto" }}>
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span className="sr-only">Loading...</span>
          </div>
        ) : field ? (
          <>
            <div className="field-attribute-container">
              <strong style={{ fontSize: isMobile ? "14px" : "20px" }}>Question Title:</strong>{" "}
              <span style={{ fontSize: isMobile ? "12px" : "18px", maxWidth: "100%", paddingLeft: "10px", textWrap: "nowrap", overflowX: "auto" }} title={field.title}>
                {field.title}
              </span>
            </div>
            <div className="field-attribute-container">
              <strong style={{ fontSize: isMobile ? "14px" : "20px" }}>Question Type:</strong>{" "}
              <span style={{ fontSize: isMobile ? "12px" : "18px", paddingLeft: "10px" }} title={getQuestionTypeText(field.type)}>
                {getQuestionTypeText(field.type)}
              </span>
            </div>
            <div className="field-attribute-container">
              <strong style={{ fontSize: isMobile ? "14px" : "20px" }}>Question Required:</strong>{" "}
              <span style={{ fontSize: isMobile ? "12px" : "18px", paddingLeft: "10px" }}>
                {field.isRequired ? "Yes" : "No"}
              </span>
            </div>
            {(field.type === "checkboxes" || field.type === "multipleChoice" || field.type === "dropdown") &&
              (
                <div className="field-attribute-container" style={{ display: "block" }}>
                  <p style={{ fontSize: isMobile ? "14px" : "20px", marginBottom: "0" }}><strong>Choices:</strong></p>
                  <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                    {field.structure.choices.map((choice, index) => (
                      <div key={index} className="field-list-item-container">
                        {" > "}
                        <span style={ { fontSize: isMobile ? "10px" : "" }}>
                          {choice}
                        </span>
                      </div>
                    ))}
                  </ul>
                </div>
              )}
            {field.type === "fileUpload" && (
              <div className="field-attribute-container" style={{ display: "block" }}>
                <p style={{ fontSize: isMobile ? "14px" : "20px", marginBottom: "0" }}><strong>File Types Allowed:</strong></p>
                <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                  {field.structure.fileTypes.map((fileType, index) => (
                    <div key={index} className="field-list-item-container">
                      {" > "}
                      <span style={ { fontSize: isMobile ? "10px" : "" }}>
                        {fileType}
                      </span>
                    </div>
                  ))}
                </ul>
              </div>
            )}
            {field.type === "scale" && (
              <div className="field-attribute-container" style={{ display: "block" }}>
                <p style={{ fontSize: isMobile ? "14px" : "20px", marginBottom: "0" }}><strong>Range: </strong></p>
                <div className="field-attribute-container">
                  <span style={{ fontSize: isMobile ? "10px" : "" }}><strong>Minimum:</strong> {field.structure.minNumber}</span>
                </div>
                <div className="field-attribute-container">
                  <span style={{ fontSize: isMobile ? "10px" : "" }}><strong>Maximum:</strong> {field.structure.maxNumber}</span>
                </div>
              </div>
            )}
          </>
        ) : (
          <p>No field data available.</p>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ViewFieldModal;