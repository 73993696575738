const baseURL = process.env.NODE_ENV === "development" ? "http://localhost:5000" : "";

/**
 * Validates a user using a Google access token.
 *
 * @param {Object} USER_GOOGLE_AUTH_CODE - The Google access token of the user.
 * @return {Promise<Response>} The response from the validation API.
 */
export const validateUser = async (USER_GOOGLE_AUTH_CODE) => {
  try {
    const response = await fetch(`${baseURL}/api/authorization/authorizeUser?authCode=${USER_GOOGLE_AUTH_CODE.code}`,
      { method: "GET", credentials: "include" }
    );
    if (response.status >= 200 && response.status < 300) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Initializes the login process by sending a GET request to the server's login API.
 *
 * @return {Promise<Response>} The response from the server.
 */
export const initLogin = async () => {
  try {
    const response = await fetch(`${baseURL}/api/authorization/initLogin`,
      { method: "GET", credentials: "include" }
    );
    if (response.status >= 200 && response.status < 300) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Logs out the user by sending a GET request to the logout endpoint.
 *
 * @return {Promise<Object>} An object containing the status code and data returned by the server.
 *                           If an error occurs, an object with a status code of 520 is returned.
 */
export const logout = async () => {
  try {
    const response = await fetch(`${baseURL}/api/authorization/logout`,
      { method: "GET", credentials: "include" }
    );
    if (response.status >= 200 && response.status < 300) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};


/**
 * Updates a user's accepted privacy policy and terms and conditions in the database.
 *
 * @param {boolean} acceptedPrivacyPolicy - Whether the user has accepted the privacy policy.
 * @param {boolean} acceptedTermAndConditions - Whether the user has accepted the terms and conditions.
 * @param {string} email - The email of the user to update.
 * @return {Promise<Object>} An object containing the status code and data returned by the server.
 *                           If an error occurs, an object with a status code of 520 is returned.
 */
export const updateUserAcceptedPrivacyPolicyTermsAndConditions = async (acceptedPrivacyPolicy = false, acceptedTermAndConditions = false, email) => {
  try {
    const response = await fetch(`${baseURL}/api/authorization/updateUserAcceptedPrivacyPolicyTermsAndConditions?email=${email}&acceptedPrivacyPolicy=${acceptedPrivacyPolicy}&acceptedTermAndConditions=${acceptedTermAndConditions}`,
      { method: "PUT", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};


export const authorizeUserFromCookie = async (cookie) => {
  try {
    const response = await fetch(`${baseURL}/api/authorization/authorizeUserFromCookie?loginCookie=${cookie}`,
      { method: "GET", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};